.contact-block__label{

}
.telephone-big{
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
    letter-spacing: normal;
    text-align: left;
    color: #00acc1;//1468a0;
    font-size: 18px;
    line-height: 24px;
    font-family: "Open Sans";

}