#logoimg {
  -vendor-animation-duration: 5s;
  -vendor-animation-delay: 5s;
  -vendor-animation-iteration-count: infinite;
}
//
//<div class="sideblock-element" >
//<!-- slow wow slideInLeft"  data-animate="slideInLeft" -->
//<div class="sidebyside-image slow wow fadeInRight"  data-animate="fadeInRight">
//<?php
//if ($row->field_url !== '' ){
//     print "<a href='" .  $view->render_field('field_url', $view->row_index)   . "'>" ;
//}
//print $view->render_field('field_top_image', $view->row_index);
//if ($row->field_url !== '' ){
//     print "</a>" ;
//}
//?>
//</div>
//<div class="sidebyside-title  slow wow fadeInLeft"  data-animate="fadeInLeft">
//<div class="sidebyside-title-inner">
//<?php
//if ($row->field_url !== '' ){
//     print "<a href='" .  $view->render_field('field_url', $view->row_index)   . "'>" ;
//}
//print $row->title;
//if ($row->field_url !== '' ){
//     print "</a>" ;
//}
//?>
//</div>
//</div>
//<div class="block-body">
//<?php
//print $view->render_field('body', $view->row_index);
//?>
//</div>
//</div>

//view-display-id-cta_block
,
cta-block {
  @include breakpoint(small) {

    .sidebysideblock-row {
    }
  }

  @include breakpoint(large) {

    .sidebysideblock-row {
    }
  }
}


.slide-and-fade-and-spin {
  @include mui-animation(slide, fade, spin(120deg));
}

.serial-animation{
@include mui-series {
  .thing-one {
    @include mui-queue(1s, 0s, fade);
  }

  // A 2s pause will happen after this animation ends
  .thing-two {
    @include mui-queue(1s, 2s, slide($direction: right));
  }

  // It works with multiple effects, too!
  .thing-three {
    @include mui-queue(2s, 0s, spin($direction: ccw), slide($direction: up));
  }
}
}
.custom-slow-animation{
animation-duration: 750ms!important;
}

.animation-logoimg {
  @include mui-animation(fade(in));
  animation-duration: 2s;
}

#btnShowMenu{
   @include mui-animation(fade(in));
   animation-duration: 2s;
 }

.custom_series{
  // 2 second delay before the first shake
  @include mui-series(2s) {
    .shake  { @include mui-queue(2s, 0s, shake()); }
    .spin   { @include mui-queue(1s, 2s, spin()); }
    .wiggle { @include mui-queue(wiggle); }
  }
}




// class="slow wow slideInLeft"
// data-animate="slideInLeft"
.fade-in {
  @include mui-animation(fade(in));
  animation-duration: 2s;
}
.fade-out {
  @include mui-animation(fade(out));
  animation-duration: 2s;
}

.menu_slide-out-to-left{
  @include mui-animation(slide(out,left));
  animation-duration: 1s;
}
.menu_slide-in-from-left{
  @include mui-animation(slide(in,right));
  animation-duration: 1s;
}

.search-icon-animation{
  @include mui-animation(slide(in,left));
  animation-duration: 1s;
}

// wow animations
.on-scroll-animate-in{
  @include mui-animation(zoom(in));
  animation-duration: 3s;
}


/*
.animation-custom1 {
  @include mui-animation(fade(in));
  animation-duration: 2s;
}
*/