.footer-container_wrap {
  @include breakpoint(medium up) {
    max-width: 90%;
  }
  @include breakpoint(small only) {
    max-width: 100%;
  }
  margin: 0 auto;
  font-size: 16px;
  font-size: 1rem;
  .textwidget {
    font-size: 20px;
  }
  .textwidget.invert {
    color: #949494;
  }
}

.site-footer.default .footer-container {
  padding-top: 14px;
  padding-bottom: 14px;
}

.footer-container__top {
  padding-top: 10px;
  padding-bottom: 10px;
  .footer-copyright {
    margin-left: auto;
    float: right;
    em {
      color: #00acc1; //#0095e5;
    }
    span {
      color: #ffffff;
    }
  }
}

.invert.footer-container,
.invert.footer-social-wrap {
  border-color: rgba(255, 255, 255, 0.15);
}

.site-footer {
  background-color: #2c2d2d;
  p {
    color: #ffffff !important;
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 24px;
    font-family: "Open Sans";
  } //width: 100vw;
  @include breakpoint(medium up) {
    //margin-left: -52px;
  }
  @include breakpoint(small only) {
    //margin-left: 0px;
  }
  .block-title {
    font-size: 16px; //1.4rem;
    letter-spacing: 0px;
    line-height: 24px;
    color: rgb(255,255,255);
    font-family: "Open Sans";
    font-weight: bold;
    font-style: normal;
    color: $white;
    text-align: left !important;
    margin-top: 0;
    margin-bottom: 10px;
  }
  h6 {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px; //1.4rem;
    color: $white;
    text-align: left !important;
    margin-top: 0;
    margin-bottom: 10px;
  }
  .block-menu-menu-footer-menu {
    ul {
      li {
        width: 100%;
      }
    }
  }
  .footer-area .row {
    //max-width: 100rem;
    //.columns {
    //}
    aside {
      padding-left: 50px;
      padding-right: 50px;
      
    }

    aside:last-child{
      padding-left: 0px;
      padding-right: 0px;
    }

  }
  .footer-area {
    @include breakpoint(medium up) {
      padding: 100px 0 23px; //padding: 30px 0 10px;
    }
    @include breakpoint(small only) {
      padding: 90px 24px 23px 23px;
    }
    .widget-about__logo{

      margin-top:-50px;

        }
  }
  .footer-area-wrap{
    margin-top:20px;
  }
  
  .footer-area-wrap,
  .footer-social-wrap {
    background-color: #015b67; //#007e8f; //#373838;
  }
  .invert {
    color: #949494;
  }
  .footer-area .widget-title {
    font-weight: bold;
    font-size:14px;
    line-height: 24px;
    font-family: "Open Sans";
    margin-bottom: 10px;
  }
  .footer-img {
    display: inline-block;
    padding-bottom: 30px;
  }
  .invert h6 {
    color: #ffffff;
  }
  .site-footer .invert a,
  .site-footer .invert .widget a {
    color: #00acc1; //#0095e5;
  }
  .invert .widget ul li a {
    color: #ffffff;
  }
  .widget ul li a,
  .elementor-widget ul li a {
    color: #616262;
  }
  .site-footer .invert a,
  .invert a:focus {
    color: #ffffff;
  }
  a {
    color: #0674d1;
  }
  a {
    text-decoration: none;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
  }
  a {
    background-color: transparent;
    text-decoration: none;
  }
  a {
    color: #ffffff;
  }
}
