body {
    overflow-x: hidden;
}

.component4_full_width_image_carousel{
    //overflow-x:hidden;
    /*
    hack from 
     https://css-tricks.com/full-width-containers-limited-width-parents/ 
     NOTE: REQUIRES  : body {overflow-x: hidden;}
     */
    .full-width {
        width: 100vw; //$global-width;
        //@include horizontal-center;
        position: relative;
        overflow-x:hidden;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
  } 

    .home-slider__slide
    {
       position: relative;
       width:100%;
        /*       
       width: 100%;
        overflow: hidden;
       display: block;
       padding: 10px;
       @include horizontal-center;
       */
    }   
    
    .home-slide__content .first, .home-slide__content .second {
      font-size: 40px;
      color: $white; //#0095e5;
      font-weight: bold;
    }
    
    .home-slide__content .second span{
      display: block;
    }
    
    .c-row {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        // background-color: rgba(0, 0, 0, 0.2);
    }

    .c-table
    {
       display: table;
       height: 100%;
       width: 100%;
       max-width: 75rem;
       margin: auto;
    }
    
    .c-cell
    {
       display: table-cell;
       vertical-align: middle;
    }
    
    .c-cell.customalign-right
    {
       text-align: right;
    }
    
    .c-cell.customalign-left
    {
       text-align: left;
    }
    
    .home-slider__image
    {
       height: 600px;//70vh;
       background-size: cover;
       background-position: center center;
    }
    .home-slide__content {
       width: 100%;
       display:inline-block;
       overflow:hidden;
       padding: 10px;
    }
    
    @keyframes showup {
       0% {opacity:0;}
       20% {opacity:1;}
       80% {opacity:1;}
       100% {opacity:0;}
    }
    @keyframes slidein {
       0% { margin-left:-800px; }
       20% { margin-left:-800px; }
       35% { margin-left:0px; }
       100% { margin-left:0px; }
    }
    @keyframes reveal {
       0% {opacity:0;width:0px;}
       20% {opacity:1;width:0px;}
       30% {width:355px;}
       80% {opacity:1;}
       100% {opacity:0;width:355px;}
    }


    .js-component4 {
        //background-color: #8ec7c7;
    }

    .js-component4  .slick-prev:before
    {
        content: url('/sites/all/themes/custom/foundation/src/assets/img/prev_dark.png')!important;
        margin-left: 55px!important;
    }
    .js-component4> [dir='rtl'] .slick-prev:before
    {
         //content: '→';
        content: url('/sites/all/themes/custom/foundation/src/assets/img/prev_dark.png')!important;
        margin-left: 55px!important;
    }

    .js-component4 .slick-next:before
    {
         //content: '→';
        content: url('/sites/all/themes/custom/foundation/src/assets/img/next_dark.png')!important;
        margin-left: -55px!important;
    }
    .js-component4 [dir='rtl'] .slick-next:before
    {
        content: url('/sites/all/themes/custom/foundation/src/assets/img/next_dark.png')!important;
        // content: '←';
        margin-left: -55px!important;
    }


}