.custom-cta-block
{
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: $blue;
    color: white;
    position: relative;

    .paragraph1 {
        font-size: 30px;
        text-align: center;
        font-style: italic;
    }

    .paragraph1 {
        font-size: 30px;
        text-align: center;
        font-style: italic;
    }

    .custom-button-container {
        text-align: center;
    }

}

.custom-cta-block::after
{
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: -100%;
    right: -100%;
    content: "";
    background: $blue;
    z-index: -1;
}