.view-blog-posts {
   // background-color: lightblue;
      font-family: 'Open Sans', sans-serif;
  .view-content {
    @include flex-grid-row();
    .blogitem {
        @include flex-grid-column($columns:12);

      .blogitem-photo {
          @include flex-grid-column($columns: 12);
        }

        .blogitem-details {
            @include flex-grid-row();
        }


        
        .blogitem-date{
            @include flex-grid-column($columns: 12);
        }
            .blogitem-title{
                @include flex-grid-column($columns: 12);
              
            }
        .blogitem-buttons {
          @include flex-grid-row();
          .custom-readmore {
            @include flex-grid-column($columns: 5,$gutters:20px);
          }
          .custom-view-all-news {
            @include flex-grid-column($columns: 5,$gutters:20px);
          }
        }
 
        }
  

    .blogitem-photo {
      img {
        display: block;
        width: 100%;
        object-fit: cover;
        min-height: 300px;
      }
    }

    .blogitem-details{
      display: flex;
      flex-direction: column;          /* top to bottom */
      justify-content: space-between;  /* first item at start, last at end */
      height:100%;
      min-height: 300px;
    }
    .blogitem-date{
        padding-top:5px;
        color: #616262;
        font-size: 0.875rem;
        font-weight: 400;
        i {
            color: #00acc1;//#0095e5;
        }
    }

    .blogitem-title{
        
        font-style: normal;
        font-weight: 700;
        line-height: 1.5;
        font-family: "Open Sans", sans-serif;
        letter-spacing: 0px;
        text-align: inherit;
        color: #00acc1;//#0095e5;
        font-size: 1.6rem;
        //font-size: 22px;
        padding-right: 0;
        padding-left: 0;
        a{
            color: #00acc1;//#0095e5;
        }
        @include breakpoint(small only) {
          margin-top: 20px;
        }
      }

    .blogitem-summary{
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 1.2;
        font-family: "Open Sans", sans-serif;
        letter-spacing: 0px;
        text-align: left;
        color: #616262;
    }
    .button {
      margin-top:20px;
      margin-right:20px;
      font-size: 1rem;
    }
    .custom-readmore{
      font-weight: bolder;
      border:2px solid #70295F!important;
      color: #70295F;
    }

    .custom-view-all-news{
      font-weight: bolder;
      border:2px solid #354F54!important;;
      color: #354F54;
    }
    .blogitem-buttons{
      margin-bottom:0px;
      align-items: flex-end;
      padding-top: 1rem;
      vertical-align: bottom;
      width: 100%;
    }



  }
}
  