@include breakpoint(small only) {
  .blue-background-cta2 {
    margin-right: unset !important;
    margin-left: unset !important;
  }

  .row .row{
    margin-right: unset !important;
    margin-left: unset !important;
  }
}

.blue-background-cta2::after {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  @include breakpoint(medium up) {
    left: -100%;
    right: -100%;
  }
  @include breakpoint(small only) {
    left: -10px;
    right: -10px;
  }
  content: "";
  z-index: -1; //background-color: #00acc1;
  background-image: url(/sites/all/themes/custom/foundation/src/assets/img/bg-img2.jpg);
  background-position: center center;
  background-size: cover;
}

.blue-background-cta2 {
  position: relative;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 55px 0px 55px 0px;




  .paragraph1 {
    text-align: center;
    color: white;
  
    font-size: 21px;
    letter-spacing: 0px;
    line-height: 34px;
  }
  .paragraph2 {
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 31px;
    text-align: center;
    font-weight: bold;
    color: white;
  }
  .custom-button-container {
    text-align: center;
  }
}

.blue-background-cta {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: $blue;
  color: white;
  position: relative;
  .paragraph1 {
    font-size: 30px;
    text-align: center;
    font-style: italic;
  }
  .paragraph1 {
    font-size: 30px;
    text-align: center;
    font-style: italic;
  }
  .custom-button-container {
    text-align: center;
  }
}

.blue-background-cta::after,
  {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: -100%;
  right: -100%;
  content: "";
  background: $blue;
  z-index: -1;
}
