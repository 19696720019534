.header-container{
    background-color: $color-header-blue-2;
    color: $color-white;
    font-size: 16px;
    font-weight: 400;

    a {
        color: $color-white;
    }
}



.tb-megamenu .taxonomy-menu-terms ul.menu a.active {
    color: #00acc1;//#0095e5;
}


.tb-megamenu {
    .responsive-menus-mean-menu-processed{
        padding-top:10px;
    }
}