
.top-panel{
    //width:100vw;
    min-height: 50px;

    .block-block-8{
        @include breakpoint(medium up) {
          width : 100%;
        }
        @include breakpoint(small) {
          width : 160%;
        }
      }
      
    padding-top:10px;
    padding-bottom:10px;
    background-color: $color-header-blue-1;
    color: $color-white;
    font-size: 16px;
    font-weight: 400;
    a {
        color: $color-white;
        font-size: 16px;
        font-weight: 400;
    }
    p{
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        color: $color-white;
        margin-bottom: 0rem;
    }
}
 


.language-switcher{
    //background-color:red;
    float: right;
    display: block;
    margin-top: 5px;
    margin-right: 40px;
    width: 100px;
    background-color: transparent;
    
    ul.language-switcher-locale-url {
        list-style-type: none;
        li {
            display: inline;
            img{
                display:none;
            }
        }
    }
    .language-link {
    display: inline;
    font-weight: normal;
    //text-transform: uppercase;
    font-size: 0.95rem;
    margin-left:20px;
}
    .active {
        .language-link {
            display: inline;
            font-weight: bold;
            text-decoration: underline;
            font-size: 0.95rem;
            margin-left:20px;
        }        
    }
 
}
.top-container_wrap{
    //max-width:1200px;
    margin:0 auto;
}


.social-links {
    padding-top:10px;
    padding-bottom:10px;
}

.header-container{
    background-color: $color-header-blue-2;
    color: $color-white;
    font-size: 16px;
    font-weight: 400;

    a {
        color: $color-white;
    }
}


.header-button{
    //border-color: $white;
    //color: #18a1eb;
    //padding: 20px 20px 20px 20px;
    //font-size: 1rem;
    //@include button(true,  #18a1eb ,#18a1eb, $black, 'hollow');
    //@include button(false, #18a1eb, #18a1eb, #0095e5, 'hollow');
    background-color: #00acc1;//#18a1eb;
}


.footer-button{
    //@include button(false, #18a1eb, #18a1eb, #0095e5, 'hollow');
    background-color: #00acc1;//#18a1eb;
}