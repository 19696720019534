
  .block-webform{
    //box-shadow: 5px 5px 5px #888888;
    box-shadow: 1px 1px 5px #888888;
  }
  
  .block-webform-client-block-6 {
    //margin-top: -150px;

    @include breakpoint(medium up) {
      padding-left: 60px;
      padding-right: 60px;
      padding-top: 20px;
      padding-bottom: 40px;
      margin-bottom:50px;
      }
    @include breakpoint(small) {
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-bottom:30px;
      }

    background-color: white;

    .webform-submit{
        color: #fefefe;
        width:100%; 

        border-radius: 120px;
        
            /* send form */
            font-family: 'Open Sans', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            text-transform: uppercase;
            background-color:#00acc1;//#0095e5;
            color:$white;
          }
    }
  
  .webform-client-form-6
  {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    padding-top:2rem;
    background-color:transparent;
  
    .node-title {
      width:100%;
      display: block;
      margin: 0 auto;
      text-align: center;
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 1rem;
      color: #1F467A;
      text-transform: uppercase;
    }
  
    .webform-client-form{
      margin: 0 auto;
  
    }
   
    .contact-header{
      /* STAY IN TOUCH! */
      padding-bottom:20px;
      text-align:center;
      font-size: 20px;
      color: #00acc1;//#0095e5;
      opacity: 1.00;
    }
 
  
  
    .contact-body{
      text-align:center;
      padding-bottom:20px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      color: #8A8A8A;
    }
  
  
    .contact_details{

      a{
        color:#858585!important;
      }
    }

    .node-appointment-webform {
     
    form {
      text-align: center;
      color: #858585;
      padding: 3rem 0;
  
  
      .form-item, .form-actions{
        text-align: left;
        font-size: 14px;//1.4rem;
        font-weight: 800;
        
        label{
          color: #858585;
        }

  
        input{
          border: 1px solid #273364;
        }
  

        ::-moz-placeholder{
          font-family: 'Open Sans', sans-serif;
          opacity: 1;
          color:#858585;
          font-size: 19px;
          font-weight: normal;
        }
        ::-webkit-input-placeholder{
          font-family: 'Open Sans', sans-serif;
          opacity: 1;
          color:#858585;
          font-size: 19px;
          font-weight: normal;
        }
        ::-webkit-input-placeholder { // WebKit, Blink, Edge 
          color:    #858585;
        }
        :-moz-placeholder { // Mozilla Firefox 4 to 18 
          color:    #858585;
          opacity:  1;
        }
        ::-moz-placeholder { //Mozilla Firefox 19+ 
          color:    #858585;
          opacity:  1;
        }
        :-ms-input-placeholder {// Internet Explorer 10-11 
          color:    #858585;
        }
        ::-ms-input-placeholder { // Microsoft Edge 
          color:    #858585;
        }

        textarea{
          outline: 0;
          background-color: $white;
          border: 1px solid #273364;
        }
  
        select{
          color: #909090;
          option:first-child
          {
            color: #909090;
          }
          option
          {
            color: #273364;
          }
          outline: 0;
          background-color: $white;
          border: 1px solid #273364;
          font-size: 16px;
          //line-height: 1;
          // br_down.png
          background: url(/sites/all/themes/custom/foundation/src/assets/img/arrowdown.png) no-repeat right transparent;
          -webkit-appearance: none;
          background-position-x: 95%;
        }
        
        .form-item-submitted-date-month, .form-item-submitted-date-day {
            padding-right: 2rem;
        }
  
        .form-type-select{
          display: inline-block;
        }
  
        input[type='image']{
          vertical-align: middle;
          display: none;
        }
  
  
      }
  
  
    }
}
    .fieldset-wrapper{
      @include flex-grid-row;
    }
  
  }
  
