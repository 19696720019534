.component10_sidebside {
  .custom_row {
    margin-bottom: 20px;
  }
 

  .div-text {
    margin: 0 auto;
    width: 80%;
    h1 {
      font-size: 24px;
      letter-spacing: 0px;
      line-height: 34px;
      color: rgb(0, 172, 193);
      font-family: "Open Sans";
    }
    p {
      font-size: 15px;
      letter-spacing: 0px;
      line-height: 27px;
      color: rgb(79, 79, 79);
      font-family: "Open Sans";
    }
  }

  @include breakpoint(small only) {
    .custom-column-text {
        width: 100% !important;
      }
      .custom-column-image {
        width: 100% !important;
      }
    
  }
  @include breakpoint(medium up) {

    .row {
        display: table !important;
      }

  .custom-column {
    display: table-cell !important;
    vertical-align: middle !important;
  }

    .custom-column-text {
        width: 50% !important;
      }
      .custom-column-image {
        width: 50% !important;
      }
    }
}
