.view-office-photos-slider {
    
    margin-top: 20px;
    margin-bottom: 20px;


    .view-content{
        margin-top: 20px;
        margin-bottom: 20px;

    } 
      .slick-prev:before
       {
           content: url('/sites/all/themes/custom/foundation/src/assets/img/prev_dark.png')!important;
       }
      > [dir='rtl'] .slick-prev:before
       {
       //content: '→';
           content: url('/sites/all/themes/custom/foundation/src/assets/img/prev_dark.png')!important;
       }
   
       .slick-next:before
       {
       //content: '→';
           content: url('/sites/all/themes/custom/foundation/src/assets/img/next_dark.png')!important;
       }
       [dir='rtl'] .slick-next:before
       {
           content: url('/sites/all/themes/custom/foundation/src/assets/img/next_dark.png')!important;
       // content: '←';
       }
   
       .slick-prev,
       .slick-next
       {
         z-index: 20;
       }
   
       .slick-prev
       {
         left: 0;
       }
   
   
       .slick-next
       {
         right: 0;
       }
   
       .slick-arrow.slick-disabled {
         pointer-events: none;
       }
   
   
   
}