.secondary-menu{
    float:right
  }
  .top-bar:after{
    content: url(/sites/all/themes/custom/foundation/src/assets/img/logo.png);
    background: transparent;
    position: relative;
    right: 3%;
    top: 60px;
    @include breakpoint(small) {
      display: none;
    }
  }
  
  .top-bar .top-bar-right
  {
    width: auto !important;
    //margin-top: 2rem;
  }
  .block-menu-menu-top-menu-bar{
    .menu > li > a {
      margin-top: 6px !important;
      padding-top: 6px !important;
      //padding-bottom: 0px !important;
    }
  }
  
  .navbar-brand{
    //@media screen and (min-width: 0px) and (max-width: 1230px) {
      //margin-left:1rem;
    //}
      //@media screen and (min-width: 1230px)  {
      //margin-left:7rem;
    //}
  }
  
  .custom-top-bar {
    min-height:50px;
    vertical-align: middle;
    width:100%;
    height:100%;
    background-color: $color-header-blue-2;
  
    .info-area-1{
      height:100%;
  
      @include breakpoint(small) {
        //margin-left: 1rem;
      }
  
      @include breakpoint(medium) {
        //margin-left:7.5rem;
      }
  
    }
  
  
    .vertical-center{
      position: relative;
      top: 50%;
      transform: translateY(50%);
    }
    .contact-info{
      height:100%;
      display: flex;
      align-items: center;
      //justify-content: center;
      justify-content: left;
      align-self: center;
      .info-area-1 {
        align-self: center;
        margin-right:120px;
      }
      .info-area-2{
        align-self: center;
  
      }
  
      .info-area-middle{
      }
      .info-icon{
      }
  
      .info-text{
        margin-right:20px;
      }
  
  
      div {
      }
      .info-text  a{
        display: inline-block;
      }
  
    }
  
  
  }
  .top-bar {
    //background-color: $color-header-blue-2;
    //padding: 1rem;
    //font-family: 'Open Sans', sans-serif;
    
    font-size: 14px;// 1.4rem;

    //color: $white;
    
    background-color: #fff;
    padding: 0!important;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 0px;

    & h1 {
      //font-weight: 700;
      //text-transform: uppercase;
    }
    &-title {
      padding-top: 0;
      color: #000;
      margin-left: 0;
      margin-right: 0;
      @include breakpoint(medium down) {
        @include flex-grid-column($columns:6);
      }
  
      img {
        margin-left: 0;
      }
  
    }
  
  

    .active-trail a{
      font-family: "Open Sans", sans-serif;//Alegreya
      font-style: normal;
      //font-weight: bold;
      font-size: 14px;//1.4rem;
      //font-weight:400;
      //text-transform: capitalize;
      color: $white;
    }


    /*
    a,a:hover,a:visited,a:active{
      color: $white;
      cursor:pointer;
      display:block ;
      font-family: "Open Sans", sans-serif;//Alegreya
      font-size: 14px;//1.4rem;
      //font-style:normal;
      font-weight:400;
      height:24px;
      //letter-spacing:normal;
      //line-height:24px;
      list-style-image:none;
      list-style-position:outside;
      list-style-type:none;
      text-align:left;
      //text-size-adjust:100%;
      //visibility:visible;
      //white-space:nowrap;
    }
   
  
    @include breakpoint(small only) {
      padding-bottom: 0;
    }

    */


    ul {
      background-color: transparent;
      a {
        color: $white;
        //letter-spacing: 1px;
      }
    }
  
    a {
      padding: 0;
    }
  
    .logo {
      height: 5rem;
      // width: 9rem;
      fill: #fff;
  
      @include breakpoint(small only){
        height: 4rem;
        // width: 7rem;
        float: left;
      }
    }
  }
  .innerNav{
  
  }
  
  .custom-menu-icon {
    top:-10px;
    text-align: right;
    /*
    @include hamburger($color: #418794,$color-hover: $dark-gray, $width: 29px, $height: 29px,$weight:2px,$bars: 3);
    */
    }
  
  .top-bar.top-bar--white {
    background-color : $color-header-blue-2;
    //padding: 1rem;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    //font-weight: normal;
    //text-transform: uppercase;
    //text-align: center;
    color: $white;
  
    .logo {
      fill: $blue;
    }
  /*
    .menu-icon {
      @include hamburger($color: #4F4F4F, $width: 40px, $height: 25px);
    }
  */
  }
  
  @media screen and (min-width: 1024px) and (max-width: 1230px) {
    .show-for-small-only-custom-wrapper {
      @include flex-grid-column($columns: 10);
      display: block;
     }
  
  }
  
  @media screen and (max-width: 1230px) {
     .custom-menu-icon
     {
      width: 50px;
      height: 50px;
      float: right;
      margin-top: 1rem;
     }
  }
  
  
  @media screen and (min-width: 1231px) {
    .show-for-small-only-custom-wrapper {
      display: none;
     }
  }
  