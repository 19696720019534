.searchAreaBtn {
    margin: 0 auto;
    vertical-align: middle;
}

.mymainmenu-full-width {
    width: 100%;
}

.mymainmenu {
    //margin-top: 2rem;
    color: $blue;
    /*
  position: relative;
  width: 100%;
  */
    ul {
        /*  position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    height: 100%;
    */
        margin-left: 0px;
        display: inline-block;
        list-style-type: none;
        background-color: transparent;
    }
    ul>li {
        display: inline-block; //padding-left: 2rem;
    }
    /*
  .active a:before{
    content: " ";
    position: absolute;
    left: 0%;
    width: 100%;
    bottom: 0.3rem;
    background-color: $blue;
  }
  */
}

.header-lang {
    padding-top: 30px;
}

.tb-megamenu.style-blue {
    //background: $blue;
    background:transparent;
    padding: 10px 0;
    ul li a {
        height: auto;
        border-right: 0 !important;
    }
}

a {
    height: auto !important;
}

.tb-megamenu.style-blue .nav>li>a {
    border-right: 0px solid #4dc6f3;
}

.tb-megamenu .taxonomy-menu-terms ul.menu a {
    padding: 3px 20px;
}

.tb-megamenu .nav>li>a:focus,
.tb-megamenu .nav>li>a:hover {
    background-color: #fff;
    color: #e54e4b;
    text-decoration: none;
    text-shadow: none;
    outline: none;
}

.tb-megamenu .tb-block ul li a:hover,
.tb-megamenu .tb-block ul li a:focus,
.tb-megamenu .tb-block ul li a:active,
.tb-megamenu .tb-block .nav li a:hover,
.tb-megamenu .tb-block .nav li a:focus,
.tb-megamenu .tb-block .nav li a:active {
    background: initial;
    font-family: "Open Sans", sans-serif;//Alegreya
    text-decoration: none;
    outline-width: initial;
    font-size: 14px;//1.4rem; 
    margin-bottom: 0px;
}

.tb-megamenu .mega.open>.mega-dropdown-menu,
.tb-megamenu .mega.dropdown-submenu:hover>.mega-dropdown-menu {
    display: block;
}

.tb-megamenu .tb-block ul li a:hover,
.tb-megamenu .tb-block ul li a:focus,
.tb-megamenu .tb-block ul li a:active,
.tb-megamenu .tb-block .nav li a:hover,
.tb-megamenu .tb-block .nav li a:focus,
.tb-megamenu .tb-block .nav li a:active {
    background-attachment:scroll;
    background-clip: border-box;
    background-color: rgba(0, 0, 0, 0);
    background-image: none;
    background-origin: padding-box;
    background-position-x: 0%;
    background-position-y: 0%;
    background-size: auto;
    font-size:1.4rem;
    font-style:normal;
    font-weight: 400;
    text-align: left;
    font-family: "Open Sans", sans-serif;//Alegreya
    display: block;
    padding: 3px 20px;
    clear: both;
    line-height: 18px;
    color: #333;
    white-space: nowrap;
    border-top: 0;
}
.tb-megamenu-main-menu{
  text-align: center;
  ul.menu{
    li{
    text-align: left;
  }
  }
}
.tb-megamenu .nav li.dropdown > .dropdown-toggle .caret {
  border-top-color: #666;
  border-bottom-color: #666;
  margin-top: 15px;
}
.tb-megamenu .nav>li>a:hover {
    text-decoration: none;
    background-color: #eee;
}

.tb-megamenu.style-blue .nav>.active>a,
.tb-megamenu.style-blue .nav>.active>a:hover,
.tb-megamenu.style-blue .nav>.active>a:focus {
    background-color: white;
    color: $blue;
}

.tb-megamenu {
    .taxonomy-menu-terms {
        ul.menu {
            a {
                font-family: "Open Sans", sans-serif; //Alegreya font-weight: 400;
                display: block;
                padding: 3px 20px;
                clear: both;
                font-weight: normal;
                line-height: 18px;
                color: #333;
                white-space: nowrap;
            }
            a.active {
                color: #00acc1;//#0095e5;
                cursor: pointer;
                display: block;
                font-family: "Open Sans", sans-serif;//Alegreya
                font-size: 1.4rem;
                font-style: normal; //font-weight: 400;
                height: 24px;
                letter-spacing: normal;
                line-height: 24px;
                list-style-image: none;
                list-style-position: outside;
                list-style-type: none;
                text-align: left;
                -webkit-text-size-adjust: 100%;
                -ms-text-size-adjust: 100%;
                text-size-adjust: 100%;
                visibility: visible;
                white-space: nowrap;
            }
        }
    }
}


/*
 .tb-megamenu .mega-nav > li:first-child > a, .tb-megamenu .dropdown-menu .mega-nav > li:first-child > a
 clear: both;
    color: #333;
    display: block;
    line-height: 20px;
    font-weight: normal;
    padding: 3px 20px;
    text-shadow: none;
    white-space: nowrap;
    
    */