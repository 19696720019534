.paragraphs-item-info-container::after {
            position: absolute;
            display: block;
            top: 0;
            bottom: 0;
           


            @include breakpoint(medium up) {
                left: -100%;
                right: -100%;
            }
              @include breakpoint(small only) {
                left: -10px;
            right: -10px;

              }

          
            content: "";
            background: $blue;
            z-index: -1;
}

.paragraphs-item-info-container {
    margin-bottom: 2rem;


    position: relative; //field-name-field-info-title-container
    .title{
        font-size: 18px;// 1.3rem;
        //font-weight: 700;
        color: $white;
    }
    .subtitle{
        //font-size: 1rem;
        font-size: 14px;
        color: $white;
        padding-top:1rem;
        padding-bottom:2.5rem;
    }
    
    /*
    .field-name-field-info-title-container {
            font-size: 2rem;
            text-align: center;
            font-style: italic;
            color: $white;
            p{
                font-size: 2rem;
                text-align: center;
                font-style: italic;
                color: $white;
            }
        h1 {
            font-weight: 700;
            font-size: 2rem;
            color: $white;
        }
    }
    */
    .paragraph1 {
        font-size: 30px;
        text-align: center;
        font-style: italic;
    }
}

.field-name-field-background-color-hex-code {
    display: none;
}

.field-name-field-info-point {
    @include flex-grid-row;
    .field.field-name-field-item-image {
        width: 134px;
        height: 134px;
        //margin: auto;
        //border-radius: 50%;
        //border: 3px solid white;
         //padding: 30px; 
         padding-left: 0;
         padding-top: 30px;
         padding-right: 60px;
         
        @include breakpoint(medium up) {
            margin-top: 30px ;
            padding-bottom: 30px;
        }
        @include breakpoint(small only) {
            margin-top: 0px ;
            padding-bottom: 5px;
        }
      

    }

    p{
        margin-bottom:0rem;
    }
    .subtitle{
        width: 90%;
        line-height: 19px;
        font-size: 12px;
    }
    .title p{
        font-size: 12px;
        text-align: left;
        color: white;
        letter-spacing: 0px;
    }

    .subtitle p {
        text-align: left;
        color: white;
        font-size: 14px;
        letter-spacing: 0px;

    }
    .title p {
        font-weight: bold;
    }
}