.component1_two_columns_leftimagetitlesubtitlepa {
  .styled_paragraph-title {
    h1 {
      font-weight: normal !important;
      @include breakpoint(medium up) {
        font-size: 28px !important;
      }
      @include breakpoint(small) {
        font-size: 16px !important;
      }
      color: #00acc1;
    }
  }
  .paragraphs-items h1 {
    font-weight: normal !important;
    font-size: 28px !important;
    color: #00acc1;
  }
  .styled_paragraph-new p {
    font-size: 14px !important;
  }
  @include breakpoint(medium up) {
    .row {
      display: table !important;
    }
    .custom-column-first {
      width: 50% !important;
    }
    .custom-column {
      display: table-cell !important;
      vertical-align: middle !important;
      img {
        padding-right: 10% !important;
        padding-left: 30px !important;
      }
    }
  }
}
