.grey {
  background: #BDBEC0;
}

.light-blue {
  background: #44B4DD;
}

.dark-blue {
  background: #225681;
}

/*
.tb-megamenu.style-blue {
  background:transparent;
  padding: 10px 0;
}

.top-bar {
  background-color: #fff;
  padding: 0!important;
  font-family: 'Open Sans sans-serif';
  margin-bottom: 20px;
}

.tb-megamenu .nav > li > a {
  font-size: 22px;
  font-weight: 100;
  font-family: "Open Sans", sans-serif;
}
*/


.tb-megamenu .nav > li > a:focus,
.tb-megamenu .nav > li > a:hover {
  font-size: 15px;
  background-color: #fff;
  color: #e54e4b;
  text-decoration: none;
  text-shadow: none;
  outline: none; }

.tb-megamenu .nav > li > a {
  font-size: 15px;
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
}


body.front .custom-column {
  img {
    @include breakpoint(medium up) {
      padding-top: 30px;
    }
    @include breakpoint(small) {
      padding-top: 10px;
    }
  }
}

.tb-megamenu .nav>li>a {
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 31px;
  font-weight: 100;
  font-family: "Open Sans", sans-serif;
  color: #535353;
}

.mega-nav{
  li.mega{
    a{
      font-size: 15px;
      letter-spacing: 0px;
      line-height: 31px;
      font-weight: 100;
      font-family: "Open Sans", sans-serif;
      color: #535353;
      text-align: left;
    }
  }
}
.tb-megamenu .mega-nav > li > a, 
.tb-megamenu .dropdown-menu .mega-nav > li > a{
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 31px;
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
  color: #535353;
  text-align: left;
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.7rem;
  color: #333;
  white-space: nowrap;
}
.tb-megamenu .dropdown-menu li > a{
  border-top: 0px solid #eee;
  border-top-width: 0px;
  border-top-style: solid;
  border-top-color: rgb(238, 238, 238);
  text-shadow: none;
}
.tb-megamenu .dropdown-menu li > a:hover,
 .tb-megamenu .dropdown-menu li > a:focus,
  .tb-megamenu .dropdown-submenu:hover > a{
    text-decoration: none;
    color: #333!important; //#fff!important;
    background-color: transparent!important;// #08c;
    background-image: none!important;//-moz-linear-gradient(top,#08c,#0077b3);
    //background-image: -webkit-gradient(linear,0 0,0 100%,from(#08c),to(#0077b3));
    //background-image: -webkit-linear-gradient(top,#08c,#0077b3);
    //background-image: -o-linear-gradient(top,#08c,#0077b3);
    //background-image: linear-gradient(to bottom,#08c,#0077b3);
    //background-repeat: repeat-x;
    //filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0088cc', endColorstr='#ff0076b2', GradientType=0);
}
.tb-megamenu.style-blue .dropdown-menu li > a:hover, .tb-megamenu.style-blue .dropdown-menu li > a:focus, .tb-megamenu.style-blue .dropdown-submenu:hover > a {
  color: #00acc1!important; //#fff!important;
  background-color: transparent!important;// #08c;
  background-image: none!important;//-moz-linear-gradient(top,#08c,#0077b3);
  }
.tb-megamenu .dropdown-menu .active > a, .tb-megamenu .dropdown-menu .active {
  color: #00acc1!important; //#fff!important;
  background-color: transparent!important;// #08c;
  background-image: none!important;//-moz-linear-gradient(top,#08c,#0077b3);
  
}

.top-panel {
  padding-bottom: 5px;
  background-color: #00acc1;//#18a1eb;
}

.top-panel a {
  color: #fff !important;
}

.tb-megamenu.style-blue .nav li.dropdown.active>.dropdown-toggle,
.tb-megamenu.style-blue .nav li.dropdown.open.active>.dropdown-toggle {
  background-color: transparent;
//  background-color: #234561;
  color: #234561;//#fff;
}

.button--secondary {
  border-radius: 100px;
}

.tb-megamenu .nav>li>a:hover {
  text-decoration: none;
  background-color: transparent;
}

.tb-megamenu.style-blue .nav>.active>a {
  color:#00acc1;// #535353;
  background: transparent;
  font-weight: bold;
}

.top-bar .button.primary {
  border: none;
  border-radius: 120px;
  background-color: #00acc1; //1779ba;
 //background-color: rgb(51, 200, 255);
  color: #fff;
  margin-top: 25px;
  line-height: 31px;
  font-family: "Open Sans";
  font-weight: bold;
  text-align: center;
  font-size: 14px;
  padding: 5px 5px 5px !important;
}

.top-bar {
  padding: 10px 0 5px;
}

/*
.navbar-brand {
  background:  url(/sites/default/files/logofides.png) no-repeat 0 0;
  background-size:  contain;
  padding: 37px!important;
  display:  block;
}
img#logoimg {
  display:  none;
}
*/
.logoimg1{
  margin-top:10px;
}
main.l-main.lmain-page {
  margin-top: 5px;
}

.webform-client-form-98 {
  width: 97%;
  margin: 0 auto;
  padding: 5px 10px;
  border: 2px solid #418794;
  box-shadow: 1px 1px 1px #888;
}

.webform-client-form-98 .webform-component-fieldset {
  border: 1px solid #418794;
  padding: 10px;
  float: left;
  width: 47%;
  margin: 10px;
}

.front .component3_images_with_text_on_top_grid .styled_paragraph{
  text-align:center!important;
  font-size: 28px!important;
  letter-spacing: 0px;
  line-height: 34px;
  color: #00acc1!important;
}
.front .component3_images_with_text_on_top_grid > .row {
width:100%;
}
