@charset "UTF-8";
/* #757575; */
/**
 * Foundation for Sites by ZURB
 * Version 6.4.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
@media print, screen and (min-width: 40em) {
  .reveal, .reveal.tiny, .reveal.small, .reveal.large {
    right: auto;
    left: auto;
    margin: 0 auto; } }

.serial-animation .thing-one, .serial-animation .thing-two, .serial-animation .thing-three, .custom_series .shake, .custom_series .spin, .custom_series .wiggle {
  animation-play-state: paused;
  animation-fill-mode: both; }
  .is-animating .serial-animation .thing-one, .serial-animation .is-animating .thing-one, .is-animating .serial-animation .thing-two, .serial-animation .is-animating .thing-two, .is-animating .serial-animation .thing-three, .serial-animation .is-animating .thing-three, .is-animating .custom_series .shake, .custom_series .is-animating .shake, .is-animating .custom_series .spin, .custom_series .is-animating .spin, .is-animating .custom_series .wiggle, .custom_series .is-animating .wiggle {
    animation-play-state: running; }

/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "small=0em&medium=40em&large=64em&xlarge=75em&xxlarge=90em"; }

html {
  box-sizing: border-box;
  font-size: 100%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: #fefefe;
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  line-height: 1.5;
  color: #616262;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0; }

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1;
  cursor: pointer; }
  [data-whatinput='mouse'] button {
    outline: 0; }

pre {
  overflow: auto; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

.row {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap; }
  .row .row {
    margin-right: -0.625rem;
    margin-left: -0.625rem; }
    @media print, screen and (min-width: 40em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    @media print, screen and (min-width: 64em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    .row .row.collapse {
      margin-right: 0;
      margin-left: 0; }
  .row.expanded {
    max-width: none; }
    .row.expanded .row {
      margin-right: auto;
      margin-left: auto; }
  .row:not(.expanded) .row {
    max-width: none; }
  .row.collapse > .column, .row.collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .row.is-collapse-child,
  .row.collapse > .column > .row,
  .row.collapse > .columns > .row {
    margin-right: 0;
    margin-left: 0; }

.column, .columns {
  flex: 1 1 0px;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  min-width: 0; }
  @media print, screen and (min-width: 40em) {
    .column, .columns {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }

.column.row.row, .row.row.columns {
  float: none;
  display: block; }

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

.small-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.small-offset-0 {
  margin-left: 0%; }

.small-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.small-offset-1 {
  margin-left: 8.33333%; }

.small-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.small-offset-2 {
  margin-left: 16.66667%; }

.small-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.small-offset-3 {
  margin-left: 25%; }

.small-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.small-offset-4 {
  margin-left: 33.33333%; }

.small-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.small-offset-5 {
  margin-left: 41.66667%; }

.small-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.small-offset-6 {
  margin-left: 50%; }

.small-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.small-offset-7 {
  margin-left: 58.33333%; }

.small-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.small-offset-8 {
  margin-left: 66.66667%; }

.small-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.small-offset-9 {
  margin-left: 75%; }

.small-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.small-offset-10 {
  margin-left: 83.33333%; }

.small-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.small-offset-11 {
  margin-left: 91.66667%; }

.small-up-1 {
  flex-wrap: wrap; }
  .small-up-1 > .column, .small-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%; }

.small-up-2 {
  flex-wrap: wrap; }
  .small-up-2 > .column, .small-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%; }

.small-up-3 {
  flex-wrap: wrap; }
  .small-up-3 > .column, .small-up-3 > .columns {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }

.small-up-4 {
  flex-wrap: wrap; }
  .small-up-4 > .column, .small-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%; }

.small-up-5 {
  flex-wrap: wrap; }
  .small-up-5 > .column, .small-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%; }

.small-up-6 {
  flex-wrap: wrap; }
  .small-up-6 > .column, .small-up-6 > .columns {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }

.small-up-7 {
  flex-wrap: wrap; }
  .small-up-7 > .column, .small-up-7 > .columns {
    flex: 0 0 14.28571%;
    max-width: 14.28571%; }

.small-up-8 {
  flex-wrap: wrap; }
  .small-up-8 > .column, .small-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%; }

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0; }

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 0.625rem;
  padding-left: 0.625rem; }

@media print, screen and (min-width: 40em) {
  .medium-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .medium-offset-0 {
    margin-left: 0%; }
  .medium-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .medium-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .medium-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .medium-offset-3 {
    margin-left: 25%; }
  .medium-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .medium-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .medium-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .medium-offset-6 {
    margin-left: 50%; }
  .medium-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .medium-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .medium-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .medium-offset-9 {
    margin-left: 75%; }
  .medium-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .medium-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .medium-up-1 {
    flex-wrap: wrap; }
    .medium-up-1 > .column, .medium-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .medium-up-2 {
    flex-wrap: wrap; }
    .medium-up-2 > .column, .medium-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .medium-up-3 {
    flex-wrap: wrap; }
    .medium-up-3 > .column, .medium-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .medium-up-4 {
    flex-wrap: wrap; }
    .medium-up-4 > .column, .medium-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .medium-up-5 {
    flex-wrap: wrap; }
    .medium-up-5 > .column, .medium-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .medium-up-6 {
    flex-wrap: wrap; }
    .medium-up-6 > .column, .medium-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .medium-up-7 {
    flex-wrap: wrap; }
    .medium-up-7 > .column, .medium-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .medium-up-8 {
    flex-wrap: wrap; }
    .medium-up-8 > .column, .medium-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .medium-expand {
    flex: 1 1 0px; } }

.row.medium-unstack > .column, .row.medium-unstack > .columns {
  flex: 0 0 100%; }
  @media print, screen and (min-width: 40em) {
    .row.medium-unstack > .column, .row.medium-unstack > .columns {
      flex: 1 1 0px; } }

@media print, screen and (min-width: 40em) {
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; } }

@media print, screen and (min-width: 64em) {
  .large-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .large-offset-0 {
    margin-left: 0%; }
  .large-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .large-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .large-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .large-offset-3 {
    margin-left: 25%; }
  .large-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .large-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .large-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .large-offset-6 {
    margin-left: 50%; }
  .large-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .large-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .large-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .large-offset-9 {
    margin-left: 75%; }
  .large-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .large-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .large-up-1 {
    flex-wrap: wrap; }
    .large-up-1 > .column, .large-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .large-up-2 {
    flex-wrap: wrap; }
    .large-up-2 > .column, .large-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .large-up-3 {
    flex-wrap: wrap; }
    .large-up-3 > .column, .large-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .large-up-4 {
    flex-wrap: wrap; }
    .large-up-4 > .column, .large-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .large-up-5 {
    flex-wrap: wrap; }
    .large-up-5 > .column, .large-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .large-up-6 {
    flex-wrap: wrap; }
    .large-up-6 > .column, .large-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .large-up-7 {
    flex-wrap: wrap; }
    .large-up-7 > .column, .large-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .large-up-8 {
    flex-wrap: wrap; }
    .large-up-8 > .column, .large-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .large-expand {
    flex: 1 1 0px; } }

.row.large-unstack > .column, .row.large-unstack > .columns {
  flex: 0 0 100%; }
  @media print, screen and (min-width: 64em) {
    .row.large-unstack > .column, .row.large-unstack > .columns {
      flex: 1 1 0px; } }

@media print, screen and (min-width: 64em) {
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; } }

.shrink {
  flex: 0 0 auto;
  max-width: 100%; }

.column-block {
  margin-bottom: 1.25rem; }
  .column-block > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .column-block {
      margin-bottom: 1.875rem; }
      .column-block > :last-child {
        margin-bottom: 0; } }

.align-right {
  justify-content: flex-end; }

.align-center {
  justify-content: center; }

.align-justify {
  justify-content: space-between; }

.align-spaced {
  justify-content: space-around; }

.align-right.vertical.menu > li > a {
  justify-content: flex-end; }

.align-center.vertical.menu > li > a {
  justify-content: center; }

.align-top {
  align-items: flex-start; }

.align-self-top {
  align-self: flex-start; }

.align-bottom {
  align-items: flex-end; }

.align-self-bottom {
  align-self: flex-end; }

.align-middle {
  align-items: center; }

.align-self-middle {
  align-self: center; }

.align-stretch {
  align-items: stretch; }

.align-self-stretch {
  align-self: stretch; }

.align-center-middle {
  justify-content: center;
  align-items: center;
  align-content: center; }

.small-order-1 {
  order: 1; }

.small-order-2 {
  order: 2; }

.small-order-3 {
  order: 3; }

.small-order-4 {
  order: 4; }

.small-order-5 {
  order: 5; }

.small-order-6 {
  order: 6; }

@media print, screen and (min-width: 40em) {
  .medium-order-1 {
    order: 1; }
  .medium-order-2 {
    order: 2; }
  .medium-order-3 {
    order: 3; }
  .medium-order-4 {
    order: 4; }
  .medium-order-5 {
    order: 5; }
  .medium-order-6 {
    order: 6; } }

@media print, screen and (min-width: 64em) {
  .large-order-1 {
    order: 1; }
  .large-order-2 {
    order: 2; }
  .large-order-3 {
    order: 3; }
  .large-order-4 {
    order: 4; }
  .large-order-5 {
    order: 5; }
  .large-order-6 {
    order: 6; } }

.flex-container {
  display: flex; }

.flex-child-auto {
  flex: 1 1 auto; }

.flex-child-grow {
  flex: 1 0 auto; }

.flex-child-shrink {
  flex: 0 1 auto; }

.flex-dir-row {
  flex-direction: row; }

.flex-dir-row-reverse {
  flex-direction: row-reverse; }

.flex-dir-column {
  flex-direction: column; }

.flex-dir-column-reverse {
  flex-direction: column-reverse; }

@media print, screen and (min-width: 40em) {
  .medium-flex-container {
    display: flex; }
  .medium-flex-child-auto {
    flex: 1 1 auto; }
  .medium-flex-child-grow {
    flex: 1 0 auto; }
  .medium-flex-child-shrink {
    flex: 0 1 auto; }
  .medium-flex-dir-row {
    flex-direction: row; }
  .medium-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .medium-flex-dir-column {
    flex-direction: column; }
  .medium-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

@media print, screen and (min-width: 64em) {
  .large-flex-container {
    display: flex; }
  .large-flex-child-auto {
    flex: 1 1 auto; }
  .large-flex-child-grow {
    flex: 1 0 auto; }
  .large-flex-child-shrink {
    flex: 0 1 auto; }
  .large-flex-dir-row {
    flex-direction: row; }
  .large-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .large-flex-dir-column {
    flex-direction: column; }
  .large-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0; }

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility; }

em,
i {
  font-style: italic;
  line-height: inherit; }

strong,
b {
  font-weight: bold;
  line-height: inherit; }

small {
  font-size: 80%;
  line-height: inherit; }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: inherit;
  text-rendering: optimizeLegibility; }
  h1 small, .h1 small,
  h2 small, .h2 small,
  h3 small, .h3 small,
  h4 small, .h4 small,
  h5 small, .h5 small,
  h6 small, .h6 small {
    line-height: 0;
    color: #cacaca; }

h1, .h1 {
  font-size: 1.5rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h2, .h2 {
  font-size: 1.25rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h3, .h3 {
  font-size: 1.1875rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h4, .h4 {
  font-size: 1.125rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h5, .h5 {
  font-size: 1.0625rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h6, .h6 {
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

@media print, screen and (min-width: 40em) {
  h1, .h1 {
    font-size: 3rem; }
  h2, .h2 {
    font-size: 2.5rem; }
  h3, .h3 {
    font-size: 1.9375rem; }
  h4, .h4 {
    font-size: 1.5625rem; }
  h5, .h5 {
    font-size: 1.25rem; }
  h6, .h6 {
    font-size: 1rem; } }

a {
  line-height: inherit;
  color: #00acc1;
  text-decoration: none;
  cursor: pointer; }
  a:hover, a:focus {
    color: #0094a6; }
  a img {
    border: 0; }

hr {
  clear: both;
  max-width: 75rem;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #cacaca;
  border-left: 0; }

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.6; }

li {
  font-size: inherit; }

ul {
  margin-left: 1.25rem;
  list-style-type: disc; }

ol {
  margin-left: 1.25rem; }

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0; }

dl {
  margin-bottom: 1rem; }
  dl dt {
    margin-bottom: 0.3rem;
    font-weight: bold; }

blockquote {
  margin: 0 0 1rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #cacaca; }
  blockquote, blockquote p {
    line-height: 1.6;
    color: #8a8a8a; }

cite {
  display: block;
  font-size: 0.8125rem;
  color: #8a8a8a; }
  cite:before {
    content: "— "; }

abbr, abbr[title] {
  border-bottom: 1px dotted #0a0a0a;
  cursor: help;
  text-decoration: none; }

figure {
  margin: 0; }

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 1px solid #cacaca;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #0a0a0a; }

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #0a0a0a; }

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #8a8a8a; }

.lead {
  font-size: 125%;
  line-height: 1.6; }

.stat {
  font-size: 2.5rem;
  line-height: 1; }
  p + .stat {
    margin-top: -1rem; }

ul.no-bullet, ol.no-bullet {
  margin-left: 0;
  list-style: none; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

@media print, screen and (min-width: 40em) {
  .medium-text-left {
    text-align: left; }
  .medium-text-right {
    text-align: right; }
  .medium-text-center {
    text-align: center; }
  .medium-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 64em) {
  .large-text-left {
    text-align: left; }
  .large-text-right {
    text-align: right; }
  .large-text-center {
    text-align: center; }
  .large-text-justify {
    text-align: justify; } }

.show-for-print {
  display: none !important; }

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important; }
  .show-for-print {
    display: block !important; }
  .hide-for-print {
    display: none !important; }
  table.show-for-print {
    display: table !important; }
  thead.show-for-print {
    display: table-header-group !important; }
  tbody.show-for-print {
    display: table-row-group !important; }
  tr.show-for-print {
    display: table-row !important; }
  td.show-for-print {
    display: table-cell !important; }
  th.show-for-print {
    display: table-cell !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #8a8a8a;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .print-break-inside {
    page-break-inside: auto; } }

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  font-family: inherit;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #00acc1;
  color: #fefefe; }
  [data-whatinput='mouse'] .button {
    outline: 0; }
  .button:hover, .button:focus {
    background-color: #0092a4;
    color: #fefefe; }
  .button.tiny {
    font-size: 0.6rem; }
  .button.small {
    font-size: 0.75rem; }
  .button.large {
    font-size: 1.25rem; }
  .button.expanded {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0; }
  .button.primary {
    background-color: #00acc1;
    color: #0a0a0a; }
    .button.primary:hover, .button.primary:focus {
      background-color: #008a9a;
      color: #0a0a0a; }
  .button.secondary {
    background-color: #767676;
    color: #fefefe; }
    .button.secondary:hover, .button.secondary:focus {
      background-color: #5e5e5e;
      color: #fefefe; }
  .button.success {
    background-color: #3adb76;
    color: #0a0a0a; }
    .button.success:hover, .button.success:focus {
      background-color: #22bb5b;
      color: #0a0a0a; }
  .button.warning {
    background-color: #ffae00;
    color: #0a0a0a; }
    .button.warning:hover, .button.warning:focus {
      background-color: #cc8b00;
      color: #0a0a0a; }
  .button.alert {
    background-color: #cc4b37;
    color: #fefefe; }
    .button.alert:hover, .button.alert:focus {
      background-color: #a53b2a;
      color: #fefefe; }
  .button.disabled, .button[disabled] {
    opacity: 0.25;
    cursor: not-allowed; }
    .button.disabled, .button.disabled:hover, .button.disabled:focus, .button[disabled], .button[disabled]:hover, .button[disabled]:focus {
      background-color: #00acc1;
      color: #fefefe; }
    .button.disabled.primary, .button[disabled].primary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.primary, .button.disabled.primary:hover, .button.disabled.primary:focus, .button[disabled].primary, .button[disabled].primary:hover, .button[disabled].primary:focus {
        background-color: #00acc1;
        color: #0a0a0a; }
    .button.disabled.secondary, .button[disabled].secondary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.secondary, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
        background-color: #767676;
        color: #fefefe; }
    .button.disabled.success, .button[disabled].success {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.success, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success, .button[disabled].success:hover, .button[disabled].success:focus {
        background-color: #3adb76;
        color: #0a0a0a; }
    .button.disabled.warning, .button[disabled].warning {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.warning, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning, .button[disabled].warning:hover, .button[disabled].warning:focus {
        background-color: #ffae00;
        color: #0a0a0a; }
    .button.disabled.alert, .button[disabled].alert {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.alert, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert, .button[disabled].alert:hover, .button[disabled].alert:focus {
        background-color: #cc4b37;
        color: #fefefe; }
  .button.hollow {
    border: 1px solid #00acc1;
    color: #00acc1; }
    .button.hollow, .button.hollow:hover, .button.hollow:focus {
      background-color: transparent; }
    .button.hollow.disabled, .button.hollow.disabled:hover, .button.hollow.disabled:focus, .button.hollow[disabled], .button.hollow[disabled]:hover, .button.hollow[disabled]:focus {
      background-color: transparent; }
    .button.hollow:hover, .button.hollow:focus {
      border-color: #005661;
      color: #005661; }
      .button.hollow:hover.disabled, .button.hollow:hover[disabled], .button.hollow:focus.disabled, .button.hollow:focus[disabled] {
        border: 1px solid #00acc1;
        color: #00acc1; }
    .button.hollow.primary {
      border: 1px solid #00acc1;
      color: #00acc1; }
      .button.hollow.primary:hover, .button.hollow.primary:focus {
        border-color: #005661;
        color: #005661; }
        .button.hollow.primary:hover.disabled, .button.hollow.primary:hover[disabled], .button.hollow.primary:focus.disabled, .button.hollow.primary:focus[disabled] {
          border: 1px solid #00acc1;
          color: #00acc1; }
    .button.hollow.secondary {
      border: 1px solid #767676;
      color: #767676; }
      .button.hollow.secondary:hover, .button.hollow.secondary:focus {
        border-color: #3b3b3b;
        color: #3b3b3b; }
        .button.hollow.secondary:hover.disabled, .button.hollow.secondary:hover[disabled], .button.hollow.secondary:focus.disabled, .button.hollow.secondary:focus[disabled] {
          border: 1px solid #767676;
          color: #767676; }
    .button.hollow.success {
      border: 1px solid #3adb76;
      color: #3adb76; }
      .button.hollow.success:hover, .button.hollow.success:focus {
        border-color: #157539;
        color: #157539; }
        .button.hollow.success:hover.disabled, .button.hollow.success:hover[disabled], .button.hollow.success:focus.disabled, .button.hollow.success:focus[disabled] {
          border: 1px solid #3adb76;
          color: #3adb76; }
    .button.hollow.warning {
      border: 1px solid #ffae00;
      color: #ffae00; }
      .button.hollow.warning:hover, .button.hollow.warning:focus {
        border-color: #805700;
        color: #805700; }
        .button.hollow.warning:hover.disabled, .button.hollow.warning:hover[disabled], .button.hollow.warning:focus.disabled, .button.hollow.warning:focus[disabled] {
          border: 1px solid #ffae00;
          color: #ffae00; }
    .button.hollow.alert {
      border: 1px solid #cc4b37;
      color: #cc4b37; }
      .button.hollow.alert:hover, .button.hollow.alert:focus {
        border-color: #67251a;
        color: #67251a; }
        .button.hollow.alert:hover.disabled, .button.hollow.alert:hover[disabled], .button.hollow.alert:focus.disabled, .button.hollow.alert:focus[disabled] {
          border: 1px solid #cc4b37;
          color: #cc4b37; }
  .button.clear {
    border: 1px solid #00acc1;
    color: #00acc1; }
    .button.clear, .button.clear:hover, .button.clear:focus {
      background-color: transparent; }
    .button.clear.disabled, .button.clear.disabled:hover, .button.clear.disabled:focus, .button.clear[disabled], .button.clear[disabled]:hover, .button.clear[disabled]:focus {
      background-color: transparent; }
    .button.clear:hover, .button.clear:focus {
      border-color: #005661;
      color: #005661; }
      .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus.disabled, .button.clear:focus[disabled] {
        border: 1px solid #00acc1;
        color: #00acc1; }
    .button.clear, .button.clear.disabled, .button.clear[disabled], .button.clear:hover, .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus, .button.clear:focus.disabled, .button.clear:focus[disabled] {
      border-color: transparent; }
    .button.clear.primary {
      border: 1px solid #00acc1;
      color: #00acc1; }
      .button.clear.primary:hover, .button.clear.primary:focus {
        border-color: #005661;
        color: #005661; }
        .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
          border: 1px solid #00acc1;
          color: #00acc1; }
      .button.clear.primary, .button.clear.primary.disabled, .button.clear.primary[disabled], .button.clear.primary:hover, .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus, .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
        border-color: transparent; }
    .button.clear.secondary {
      border: 1px solid #767676;
      color: #767676; }
      .button.clear.secondary:hover, .button.clear.secondary:focus {
        border-color: #3b3b3b;
        color: #3b3b3b; }
        .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
          border: 1px solid #767676;
          color: #767676; }
      .button.clear.secondary, .button.clear.secondary.disabled, .button.clear.secondary[disabled], .button.clear.secondary:hover, .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus, .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
        border-color: transparent; }
    .button.clear.success {
      border: 1px solid #3adb76;
      color: #3adb76; }
      .button.clear.success:hover, .button.clear.success:focus {
        border-color: #157539;
        color: #157539; }
        .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
          border: 1px solid #3adb76;
          color: #3adb76; }
      .button.clear.success, .button.clear.success.disabled, .button.clear.success[disabled], .button.clear.success:hover, .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus, .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
        border-color: transparent; }
    .button.clear.warning {
      border: 1px solid #ffae00;
      color: #ffae00; }
      .button.clear.warning:hover, .button.clear.warning:focus {
        border-color: #805700;
        color: #805700; }
        .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
          border: 1px solid #ffae00;
          color: #ffae00; }
      .button.clear.warning, .button.clear.warning.disabled, .button.clear.warning[disabled], .button.clear.warning:hover, .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus, .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
        border-color: transparent; }
    .button.clear.alert {
      border: 1px solid #cc4b37;
      color: #cc4b37; }
      .button.clear.alert:hover, .button.clear.alert:focus {
        border-color: #67251a;
        color: #67251a; }
        .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
          border: 1px solid #cc4b37;
          color: #cc4b37; }
      .button.clear.alert, .button.clear.alert.disabled, .button.clear.alert[disabled], .button.clear.alert:hover, .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus, .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
        border-color: transparent; }
  .button.dropdown::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.4em;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #fefefe transparent transparent;
    position: relative;
    top: 0.4em;
    display: inline-block;
    float: right;
    margin-left: 1em; }
  .button.dropdown.hollow::after {
    border-top-color: #00acc1; }
  .button.dropdown.hollow.primary::after {
    border-top-color: #00acc1; }
  .button.dropdown.hollow.secondary::after {
    border-top-color: #767676; }
  .button.dropdown.hollow.success::after {
    border-top-color: #3adb76; }
  .button.dropdown.hollow.warning::after {
    border-top-color: #ffae00; }
  .button.dropdown.hollow.alert::after {
    border-top-color: #cc4b37; }
  .button.arrow-only::after {
    top: -0.1em;
    float: none;
    margin-left: 0; }

a.button:hover, a.button:focus {
  text-decoration: none; }

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  appearance: none; }
  [type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus,
  textarea:focus {
    outline: none;
    border: 1px solid #8a8a8a;
    background-color: #fefefe;
    box-shadow: 0 0 5px #cacaca;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }

textarea {
  max-width: 100%; }
  textarea[rows] {
    height: auto; }

input::placeholder,
textarea::placeholder {
  color: #cacaca; }

input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #e6e6e6;
  cursor: not-allowed; }

[type='submit'],
[type='button'] {
  appearance: none;
  border-radius: 0; }

input[type='search'] {
  box-sizing: border-box; }

[type='file'],
[type='checkbox'],
[type='radio'] {
  margin: 0 0 1rem; }

[type='checkbox'] + label,
[type='radio'] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0; }
  [type='checkbox'] + label[for],
  [type='radio'] + label[for] {
    cursor: pointer; }

label > [type='checkbox'],
label > [type='radio'] {
  margin-right: 0.5rem; }

[type='file'] {
  width: 100%; }

label {
  display: block;
  margin: 0;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.8;
  color: #0a0a0a; }
  label.middle {
    margin: 0 0 1rem;
    padding: 0.5625rem 0; }

.help-text {
  margin-top: -0.5rem;
  font-size: 0.8125rem;
  font-style: italic;
  color: #0a0a0a; }

.input-group {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  align-items: stretch; }
  .input-group > :first-child {
    border-radius: 0 0 0 0; }
  .input-group > :last-child > * {
    border-radius: 0 0 0 0; }

.input-group-label, .input-group-field, .input-group-button, .input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  margin: 0;
  white-space: nowrap; }

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #cacaca;
  background: #e6e6e6;
  color: #0a0a0a;
  text-align: center;
  white-space: nowrap;
  display: flex;
  flex: 0 0 auto;
  align-items: center; }
  .input-group-label:first-child {
    border-right: 0; }
  .input-group-label:last-child {
    border-left: 0; }

.input-group-field {
  border-radius: 0;
  flex: 1 1 0px;
  height: auto;
  min-width: 0; }

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  display: flex;
  flex: 0 0 auto; }
  .input-group-button a,
  .input-group-button input,
  .input-group-button button,
  .input-group-button label {
    height: auto;
    align-self: stretch;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1rem; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0; }

legend {
  max-width: 100%;
  margin-bottom: 0.5rem; }

.fieldset {
  margin: 1.125rem 0;
  padding: 1.25rem;
  border: 1px solid #cacaca; }
  .fieldset legend {
    margin: 0;
    margin-left: -0.1875rem;
    padding: 0 0.1875rem; }

select {
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  appearance: none;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.5rem;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  @media screen and (min-width: 0\0) {
    select {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg=="); } }
  select:focus {
    outline: none;
    border: 1px solid #8a8a8a;
    background-color: #fefefe;
    box-shadow: 0 0 5px #cacaca;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  select:disabled {
    background-color: #e6e6e6;
    cursor: not-allowed; }
  select::-ms-expand {
    display: none; }
  select[multiple] {
    height: auto;
    background-image: none; }

.is-invalid-input:not(:focus) {
  border-color: #cc4b37;
  background-color: #f9ecea; }
  .is-invalid-input:not(:focus)::placeholder {
    color: #cc4b37; }

.is-invalid-label {
  color: #cc4b37; }

.form-error {
  display: none;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: bold;
  color: #cc4b37; }
  .form-error.is-visible {
    display: block; }

.accordion {
  margin-left: 0;
  background: #fefefe;
  list-style-type: none; }
  .accordion[disabled] .accordion-title {
    cursor: not-allowed; }

.accordion-item:first-child > :first-child {
  border-radius: 0 0 0 0; }

.accordion-item:last-child > :last-child {
  border-radius: 0 0 0 0; }

.accordion-title {
  position: relative;
  display: block;
  padding: 1.25rem 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  font-size: 0.75rem;
  line-height: 1;
  color: #00acc1; }
  :last-child:not(.is-active) > .accordion-title {
    border-bottom: 1px solid #e6e6e6;
    border-radius: 0 0 0 0; }
  .accordion-title:hover, .accordion-title:focus {
    background-color: #e6e6e6; }
  .accordion-title::before {
    position: absolute;
    top: 50%;
    right: 1rem;
    margin-top: -0.5rem;
    content: '+'; }
  .is-active > .accordion-title::before {
    content: '\2013'; }

.accordion-content {
  display: none;
  padding: 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  background-color: #fefefe;
  color: #0a0a0a; }
  :last-child > .accordion-content:last-child {
    border-bottom: 1px solid #e6e6e6; }

.accordion-menu li {
  width: 100%; }

.accordion-menu a {
  padding: 0.7rem 1rem; }

.accordion-menu .is-accordion-submenu a {
  padding: 0.7rem 1rem; }

.accordion-menu .nested.is-accordion-submenu {
  margin-right: 0;
  margin-left: 1rem; }

.accordion-menu.align-right .nested.is-accordion-submenu {
  margin-right: 1rem;
  margin-left: 0; }

.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a {
  position: relative; }
  .accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #00acc1 transparent transparent;
    position: absolute;
    top: 50%;
    margin-top: -3px;
    right: 1rem; }

.accordion-menu.align-left .is-accordion-submenu-parent > a::after {
  left: auto;
  right: 1rem; }

.accordion-menu.align-right .is-accordion-submenu-parent > a::after {
  right: auto;
  left: 1rem; }

.accordion-menu .is-accordion-submenu-parent[aria-expanded='true'] > a::after {
  transform: rotate(180deg);
  transform-origin: 50% 50%; }

.is-accordion-submenu-parent {
  position: relative; }

.has-submenu-toggle > a {
  margin-right: 40px; }

.submenu-toggle {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  width: 40px;
  height: 40px; }
  .submenu-toggle::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #00acc1 transparent transparent;
    top: 0;
    bottom: 0;
    margin: auto; }

.submenu-toggle[aria-expanded='true']::after {
  transform: scaleY(-1);
  transform-origin: 50% 50%; }

.submenu-toggle-text {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.badge {
  display: inline-block;
  min-width: 2.1em;
  padding: 0.3em;
  border-radius: 50%;
  font-size: 0.6rem;
  text-align: center;
  background: #00acc1;
  color: #fefefe; }
  .badge.primary {
    background: #00acc1;
    color: #0a0a0a; }
  .badge.secondary {
    background: #767676;
    color: #fefefe; }
  .badge.success {
    background: #3adb76;
    color: #0a0a0a; }
  .badge.warning {
    background: #ffae00;
    color: #0a0a0a; }
  .badge.alert {
    background: #cc4b37;
    color: #fefefe; }

.breadcrumbs {
  margin: 0 0 1rem 0;
  list-style: none; }
  .breadcrumbs::before, .breadcrumbs::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .breadcrumbs::after {
    clear: both; }
  .breadcrumbs li {
    float: left;
    font-size: 0.6875rem;
    color: #0a0a0a;
    cursor: default;
    text-transform: uppercase; }
    .breadcrumbs li:not(:last-child)::after {
      position: relative;
      margin: 0 0.75rem;
      opacity: 1;
      content: "/";
      color: #cacaca; }
  .breadcrumbs a {
    color: #00acc1; }
    .breadcrumbs a:hover {
      text-decoration: underline; }
  .breadcrumbs .disabled {
    color: #cacaca;
    cursor: not-allowed; }

.button-group {
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch; }
  .button-group::before, .button-group::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .button-group::after {
    clear: both; }
  .button-group .button {
    margin: 0;
    margin-right: 1px;
    margin-bottom: 1px;
    font-size: 0.9rem;
    flex: 0 0 auto; }
    .button-group .button:last-child {
      margin-right: 0; }
  .button-group.tiny .button {
    font-size: 0.6rem; }
  .button-group.small .button {
    font-size: 0.75rem; }
  .button-group.large .button {
    font-size: 1.25rem; }
  .button-group.expanded .button {
    flex: 1 1 0px; }
  .button-group.primary .button {
    background-color: #00acc1;
    color: #0a0a0a; }
    .button-group.primary .button:hover, .button-group.primary .button:focus {
      background-color: #008a9a;
      color: #0a0a0a; }
  .button-group.secondary .button {
    background-color: #767676;
    color: #fefefe; }
    .button-group.secondary .button:hover, .button-group.secondary .button:focus {
      background-color: #5e5e5e;
      color: #fefefe; }
  .button-group.success .button {
    background-color: #3adb76;
    color: #0a0a0a; }
    .button-group.success .button:hover, .button-group.success .button:focus {
      background-color: #22bb5b;
      color: #0a0a0a; }
  .button-group.warning .button {
    background-color: #ffae00;
    color: #0a0a0a; }
    .button-group.warning .button:hover, .button-group.warning .button:focus {
      background-color: #cc8b00;
      color: #0a0a0a; }
  .button-group.alert .button {
    background-color: #cc4b37;
    color: #fefefe; }
    .button-group.alert .button:hover, .button-group.alert .button:focus {
      background-color: #a53b2a;
      color: #fefefe; }
  .button-group.stacked, .button-group.stacked-for-small, .button-group.stacked-for-medium {
    flex-wrap: wrap; }
    .button-group.stacked .button, .button-group.stacked-for-small .button, .button-group.stacked-for-medium .button {
      flex: 0 0 100%; }
      .button-group.stacked .button:last-child, .button-group.stacked-for-small .button:last-child, .button-group.stacked-for-medium .button:last-child {
        margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .button-group.stacked-for-small .button {
      flex: 1 1 0px;
      margin-bottom: 0; } }
  @media print, screen and (min-width: 64em) {
    .button-group.stacked-for-medium .button {
      flex: 1 1 0px;
      margin-bottom: 0; } }
  @media screen and (max-width: 39.9375em) {
    .button-group.stacked-for-small.expanded {
      display: block; }
      .button-group.stacked-for-small.expanded .button {
        display: block;
        margin-right: 0; } }

.callout {
  position: relative;
  margin: 0 0 1rem 0;
  padding: 1rem;
  border: 1px solid rgba(10, 10, 10, 0.25);
  border-radius: 0;
  background-color: white;
  color: #0a0a0a; }
  .callout > :first-child {
    margin-top: 0; }
  .callout > :last-child {
    margin-bottom: 0; }
  .callout.primary {
    background-color: #cffaff;
    color: #0a0a0a; }
  .callout.secondary {
    background-color: #eaeaea;
    color: #0a0a0a; }
  .callout.success {
    background-color: #e1faea;
    color: #0a0a0a; }
  .callout.warning {
    background-color: #fff3d9;
    color: #0a0a0a; }
  .callout.alert {
    background-color: #f7e4e1;
    color: #0a0a0a; }
  .callout.small {
    padding-top: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem; }
  .callout.large {
    padding-top: 3rem;
    padding-right: 3rem;
    padding-bottom: 3rem;
    padding-left: 3rem; }

.card {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 1rem;
  border: 1px solid #e6e6e6;
  border-radius: 0;
  background: #fefefe;
  box-shadow: none;
  overflow: hidden;
  color: #0a0a0a; }
  .card > :last-child {
    margin-bottom: 0; }

.card-divider {
  flex: 0 1 auto;
  display: flex;
  padding: 1rem;
  background: #e6e6e6; }
  .card-divider > :last-child {
    margin-bottom: 0; }

.card-section {
  flex: 1 0 auto;
  padding: 1rem; }
  .card-section > :last-child {
    margin-bottom: 0; }

.card-image {
  min-height: 1px; }

.close-button {
  position: absolute;
  color: #8a8a8a;
  cursor: pointer; }
  [data-whatinput='mouse'] .close-button {
    outline: 0; }
  .close-button:hover, .close-button:focus {
    color: #0a0a0a; }
  .close-button.small {
    right: 0.66rem;
    top: 0.33em;
    font-size: 1.5em;
    line-height: 1; }
  .close-button, .close-button.medium {
    right: 1rem;
    top: 0.5rem;
    font-size: 2em;
    line-height: 1; }

.menu {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  display: flex;
  flex-wrap: wrap; }
  [data-whatinput='mouse'] .menu li {
    outline: 0; }
  .menu a,
  .menu .button {
    line-height: 1;
    text-decoration: none;
    display: block;
    padding: 0.7rem 1rem; }
  .menu input,
  .menu select,
  .menu a,
  .menu button {
    margin-bottom: 0; }
  .menu input {
    display: inline-block; }
  .menu, .menu.horizontal {
    flex-wrap: wrap;
    flex-direction: row; }
  .menu.vertical {
    flex-wrap: nowrap;
    flex-direction: column; }
  .menu.expanded li {
    flex: 1 1 0px; }
  .menu.simple {
    align-items: center; }
    .menu.simple li + li {
      margin-left: 1rem; }
    .menu.simple a {
      padding: 0; }
  @media print, screen and (min-width: 40em) {
    .menu.medium-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.medium-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.medium-expanded li {
      flex: 1 1 0px; }
    .menu.medium-simple li {
      flex: 1 1 0px; } }
  @media print, screen and (min-width: 64em) {
    .menu.large-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.large-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.large-expanded li {
      flex: 1 1 0px; }
    .menu.large-simple li {
      flex: 1 1 0px; } }
  .menu.nested {
    margin-right: 0;
    margin-left: 1rem; }
  .menu.icons a {
    display: flex; }
  .menu.icon-top a, .menu.icon-right a, .menu.icon-bottom a, .menu.icon-left a {
    display: flex; }
  .menu.icon-left li a {
    flex-flow: row nowrap; }
    .menu.icon-left li a img,
    .menu.icon-left li a i,
    .menu.icon-left li a svg {
      margin-right: 0.25rem; }
  .menu.icon-right li a {
    flex-flow: row nowrap; }
    .menu.icon-right li a img,
    .menu.icon-right li a i,
    .menu.icon-right li a svg {
      margin-left: 0.25rem; }
  .menu.icon-top li a {
    flex-flow: column nowrap; }
    .menu.icon-top li a img,
    .menu.icon-top li a i,
    .menu.icon-top li a svg {
      align-self: stretch;
      margin-bottom: 0.25rem;
      text-align: center; }
  .menu.icon-bottom li a {
    flex-flow: column nowrap; }
    .menu.icon-bottom li a img,
    .menu.icon-bottom li a i,
    .menu.icon-bottom li a svg {
      align-self: stretch;
      margin-bottom: 0.25rem;
      text-align: center; }
  .menu .is-active > a {
    background: #00acc1;
    color: #fefefe; }
  .menu .active > a {
    background: #00acc1;
    color: #fefefe; }
  .menu.align-left {
    justify-content: flex-start; }
  .menu.align-right li {
    display: flex;
    justify-content: flex-end; }
    .menu.align-right li .submenu li {
      justify-content: flex-start; }
  .menu.align-right.vertical li {
    display: block;
    text-align: right; }
    .menu.align-right.vertical li .submenu li {
      text-align: right; }
  .menu.align-right .nested {
    margin-right: 1rem;
    margin-left: 0; }
  .menu.align-center li {
    display: flex;
    justify-content: center; }
    .menu.align-center li .submenu li {
      justify-content: flex-start; }
  .menu .menu-text {
    padding: 0.7rem 1rem;
    font-weight: bold;
    line-height: 1;
    color: inherit; }

.menu-centered > .menu {
  justify-content: center; }
  .menu-centered > .menu li {
    display: flex;
    justify-content: center; }
    .menu-centered > .menu li .submenu li {
      justify-content: flex-start; }

.no-js [data-responsive-menu] ul {
  display: none; }

.menu-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #fefefe;
    box-shadow: 0 7px 0 #fefefe, 0 14px 0 #fefefe;
    content: ''; }
  .menu-icon:hover::after {
    background: #cacaca;
    box-shadow: 0 7px 0 #cacaca, 0 14px 0 #cacaca; }

.menu-icon.dark {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon.dark::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #0a0a0a;
    box-shadow: 0 7px 0 #0a0a0a, 0 14px 0 #0a0a0a;
    content: ''; }
  .menu-icon.dark:hover::after {
    background: #8a8a8a;
    box-shadow: 0 7px 0 #8a8a8a, 0 14px 0 #8a8a8a; }

.is-drilldown {
  position: relative;
  overflow: hidden; }
  .is-drilldown li {
    display: block; }
  .is-drilldown.animate-height {
    transition: height 0.5s; }

.drilldown a {
  padding: 0.7rem 1rem;
  background: #fefefe; }

.drilldown .is-drilldown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: -1;
  width: 100%;
  background: #fefefe;
  transition: transform 0.15s linear; }
  .drilldown .is-drilldown-submenu.is-active {
    z-index: 1;
    display: block;
    transform: translateX(-100%); }
  .drilldown .is-drilldown-submenu.is-closing {
    transform: translateX(100%); }
  .drilldown .is-drilldown-submenu a {
    padding: 0.7rem 1rem; }

.drilldown .nested.is-drilldown-submenu {
  margin-right: 0;
  margin-left: 0; }

.drilldown .drilldown-submenu-cover-previous {
  min-height: 100%; }

.drilldown .is-drilldown-submenu-parent > a {
  position: relative; }
  .drilldown .is-drilldown-submenu-parent > a::after {
    position: absolute;
    top: 50%;
    margin-top: -6px;
    right: 1rem;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #00acc1; }

.drilldown.align-left .is-drilldown-submenu-parent > a::after {
  left: auto;
  right: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #00acc1; }

.drilldown.align-right .is-drilldown-submenu-parent > a::after {
  right: auto;
  left: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #00acc1 transparent transparent; }

.drilldown .js-drilldown-back > a::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #00acc1 transparent transparent;
  border-left-width: 0;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.75rem;
  border-left-width: 0; }

.dropdown-pane {
  position: absolute;
  z-index: 10;
  width: 300px;
  padding: 1rem;
  visibility: hidden;
  display: none;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  font-size: 1rem; }
  .dropdown-pane.is-opening {
    display: block; }
  .dropdown-pane.is-open {
    visibility: visible;
    display: block; }

.dropdown-pane.tiny {
  width: 100px; }

.dropdown-pane.small {
  width: 200px; }

.dropdown-pane.large {
  width: 400px; }

.dropdown.menu > li.opens-left > .is-dropdown-submenu {
  top: 100%;
  right: 0;
  left: auto; }

.dropdown.menu > li.opens-right > .is-dropdown-submenu {
  top: 100%;
  right: auto;
  left: 0; }

.dropdown.menu > li.is-dropdown-submenu-parent > a {
  position: relative;
  padding-right: 1.5rem; }

.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #00acc1 transparent transparent;
  right: 5px;
  left: auto;
  margin-top: -3px; }

.dropdown.menu a {
  padding: 0.7rem 1rem; }
  [data-whatinput='mouse'] .dropdown.menu a {
    outline: 0; }

.dropdown.menu .is-active > a {
  background: transparent;
  color: #00acc1; }

.no-js .dropdown.menu ul {
  display: none; }

.dropdown.menu .nested.is-dropdown-submenu {
  margin-right: 0;
  margin-left: 0; }

.dropdown.menu.vertical > li .is-dropdown-submenu {
  top: 0; }

.dropdown.menu.vertical > li.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto;
  top: 0; }

.dropdown.menu.vertical > li.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%; }

.dropdown.menu.vertical > li > a::after {
  right: 14px; }

.dropdown.menu.vertical > li.opens-left > a::after {
  right: auto;
  left: 5px;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #00acc1 transparent transparent; }

.dropdown.menu.vertical > li.opens-right > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #00acc1; }

@media print, screen and (min-width: 40em) {
  .dropdown.menu.medium-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.medium-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #00acc1 transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px; }
  .dropdown.menu.medium-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.medium-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.medium-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #00acc1 transparent transparent; }
  .dropdown.menu.medium-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #00acc1; } }

@media print, screen and (min-width: 64em) {
  .dropdown.menu.large-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.large-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #00acc1 transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px; }
  .dropdown.menu.large-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.large-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.large-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #00acc1 transparent transparent; }
  .dropdown.menu.large-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #00acc1; } }

.dropdown.menu.align-right .is-dropdown-submenu.first-sub {
  top: 100%;
  right: 0;
  left: auto; }

.is-dropdown-menu.vertical {
  width: 100px; }
  .is-dropdown-menu.vertical.align-right {
    float: right; }

.is-dropdown-submenu-parent {
  position: relative; }
  .is-dropdown-submenu-parent a::after {
    position: absolute;
    top: 50%;
    right: 5px;
    left: auto;
    margin-top: -6px; }
  .is-dropdown-submenu-parent.opens-inner > .is-dropdown-submenu {
    top: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }

.is-dropdown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  display: none;
  min-width: 200px;
  border: 1px solid #cacaca;
  background: #fefefe; }
  .dropdown .is-dropdown-submenu a {
    padding: 0.7rem 1rem; }
  .is-dropdown-submenu .is-dropdown-submenu-parent > a::after {
    right: 14px; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #00acc1 transparent transparent; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #00acc1; }
  .is-dropdown-submenu .is-dropdown-submenu {
    margin-top: -1px; }
  .is-dropdown-submenu > li {
    width: 100%; }
  .is-dropdown-submenu.js-dropdown-active {
    display: block; }

.responsive-embed,
.flex-video {
  position: relative;
  height: 0;
  margin-bottom: 1rem;
  padding-bottom: 75%;
  overflow: hidden; }
  .responsive-embed iframe,
  .responsive-embed object,
  .responsive-embed embed,
  .responsive-embed video,
  .flex-video iframe,
  .flex-video object,
  .flex-video embed,
  .flex-video video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .responsive-embed.widescreen,
  .flex-video.widescreen {
    padding-bottom: 56.25%; }
  .responsive-embed.vertical,
  .flex-video.vertical {
    padding-bottom: 177.77778%; }
  .responsive-embed.panorama,
  .flex-video.panorama {
    padding-bottom: 31.64062%; }
  .responsive-embed.square,
  .flex-video.square {
    padding-bottom: 100%; }

.label {
  display: inline-block;
  padding: 0.33333rem 0.5rem;
  border-radius: 0;
  font-size: 0.8rem;
  line-height: 1;
  white-space: nowrap;
  cursor: default;
  background: #00acc1;
  color: #fefefe; }
  .label.primary {
    background: #00acc1;
    color: #0a0a0a; }
  .label.secondary {
    background: #767676;
    color: #fefefe; }
  .label.success {
    background: #3adb76;
    color: #0a0a0a; }
  .label.warning {
    background: #ffae00;
    color: #0a0a0a; }
  .label.alert {
    background: #cc4b37;
    color: #fefefe; }

.media-object {
  display: flex;
  margin-bottom: 1rem;
  flex-wrap: nowrap; }
  .media-object img {
    max-width: none; }
  @media screen and (max-width: 39.9375em) {
    .media-object.stack-for-small {
      flex-wrap: wrap; } }
  @media screen and (max-width: 39.9375em) {
    .media-object.stack-for-small .media-object-section {
      padding: 0;
      padding-bottom: 1rem;
      flex-basis: 100%;
      max-width: 100%; }
      .media-object.stack-for-small .media-object-section img {
        width: 100%; } }

.media-object-section {
  flex: 0 1 auto; }
  .media-object-section:first-child {
    padding-right: 1rem; }
  .media-object-section:last-child:not(:nth-child(2)) {
    padding-left: 1rem; }
  .media-object-section > :last-child {
    margin-bottom: 0; }
  .media-object-section.main-section {
    flex: 1 1 0px; }

.is-off-canvas-open {
  overflow: hidden; }

.js-off-canvas-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  background: rgba(254, 254, 254, 0.25);
  opacity: 0;
  visibility: hidden;
  overflow: hidden; }
  .js-off-canvas-overlay.is-visible {
    opacity: 1;
    visibility: visible; }
  .js-off-canvas-overlay.is-closable {
    cursor: pointer; }
  .js-off-canvas-overlay.is-overlay-absolute {
    position: absolute; }
  .js-off-canvas-overlay.is-overlay-fixed {
    position: fixed; }

.off-canvas-wrapper {
  position: relative;
  overflow: hidden; }

.off-canvas {
  position: fixed;
  z-index: 12;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #e6e6e6; }
  [data-whatinput='mouse'] .off-canvas {
    outline: 0; }
  .off-canvas.is-transition-push {
    z-index: 12; }
  .off-canvas.is-closed {
    visibility: hidden; }
  .off-canvas.is-transition-overlap {
    z-index: 13; }
    .off-canvas.is-transition-overlap.is-open {
      box-shadow: 0 0 10px rgba(10, 10, 10, 0.7); }
  .off-canvas.is-open {
    transform: translate(0, 0); }

.off-canvas-absolute {
  position: absolute;
  z-index: 12;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #e6e6e6; }
  [data-whatinput='mouse'] .off-canvas-absolute {
    outline: 0; }
  .off-canvas-absolute.is-transition-push {
    z-index: 12; }
  .off-canvas-absolute.is-closed {
    visibility: hidden; }
  .off-canvas-absolute.is-transition-overlap {
    z-index: 13; }
    .off-canvas-absolute.is-transition-overlap.is-open {
      box-shadow: 0 0 10px rgba(10, 10, 10, 0.7); }
  .off-canvas-absolute.is-open {
    transform: translate(0, 0); }

.position-left {
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
  width: 250px;
  transform: translateX(-250px); }
  .off-canvas-content .off-canvas.position-left {
    transform: translateX(-250px); }
    .off-canvas-content .off-canvas.position-left.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-left.has-transition-push {
    transform: translateX(250px); }
  .position-left.is-transition-push {
    box-shadow: inset -13px 0 20px -13px rgba(10, 10, 10, 0.25); }

.position-right {
  top: 0;
  right: 0;
  height: 100%;
  overflow-y: auto;
  width: 250px;
  transform: translateX(250px); }
  .off-canvas-content .off-canvas.position-right {
    transform: translateX(250px); }
    .off-canvas-content .off-canvas.position-right.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-right.has-transition-push {
    transform: translateX(-250px); }
  .position-right.is-transition-push {
    box-shadow: inset 13px 0 20px -13px rgba(10, 10, 10, 0.25); }

.position-top {
  top: 0;
  left: 0;
  width: 100%;
  overflow-x: auto;
  height: 250px;
  transform: translateY(-250px); }
  .off-canvas-content .off-canvas.position-top {
    transform: translateY(-250px); }
    .off-canvas-content .off-canvas.position-top.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-top.has-transition-push {
    transform: translateY(250px); }
  .position-top.is-transition-push {
    box-shadow: inset 0 -13px 20px -13px rgba(10, 10, 10, 0.25); }

.position-bottom {
  bottom: 0;
  left: 0;
  width: 100%;
  overflow-x: auto;
  height: 250px;
  transform: translateY(250px); }
  .off-canvas-content .off-canvas.position-bottom {
    transform: translateY(250px); }
    .off-canvas-content .off-canvas.position-bottom.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-bottom.has-transition-push {
    transform: translateY(-250px); }
  .position-bottom.is-transition-push {
    box-shadow: inset 0 13px 20px -13px rgba(10, 10, 10, 0.25); }

.off-canvas-content {
  transform: none;
  transition: transform 0.5s ease;
  backface-visibility: hidden; }
  .off-canvas-content.has-transition-push {
    transform: translate(0, 0); }
  .off-canvas-content .off-canvas.is-open {
    transform: translate(0, 0); }

@media print, screen and (min-width: 40em) {
  .position-left.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-left.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-left.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-left {
      margin-left: 250px; }
    .position-left.reveal-for-medium ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-right.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-right.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-right {
      margin-right: 250px; }
    .position-right.reveal-for-medium ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-top.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-top.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-top {
      margin-top: 250px; }
    .position-top.reveal-for-medium ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-bottom.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-bottom.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-bottom {
      margin-bottom: 250px; }
    .position-bottom.reveal-for-medium ~ .off-canvas-content {
      margin-bottom: 250px; } }

@media print, screen and (min-width: 64em) {
  .position-left.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-left.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-left.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-left {
      margin-left: 250px; }
    .position-left.reveal-for-large ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-right.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-right.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-right {
      margin-right: 250px; }
    .position-right.reveal-for-large ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-top.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-top.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-top {
      margin-top: 250px; }
    .position-top.reveal-for-large ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-bottom.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-bottom.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-bottom {
      margin-bottom: 250px; }
    .position-bottom.reveal-for-large ~ .off-canvas-content {
      margin-bottom: 250px; } }

@media print, screen and (min-width: 40em) {
  .off-canvas.in-canvas-for-medium {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit; }
    .off-canvas.in-canvas-for-medium.position-left, .off-canvas.in-canvas-for-medium.position-right, .off-canvas.in-canvas-for-medium.position-top, .off-canvas.in-canvas-for-medium.position-bottom {
      box-shadow: none;
      transform: none; }
    .off-canvas.in-canvas-for-medium .close-button {
      display: none; } }

@media print, screen and (min-width: 64em) {
  .off-canvas.in-canvas-for-large {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit; }
    .off-canvas.in-canvas-for-large.position-left, .off-canvas.in-canvas-for-large.position-right, .off-canvas.in-canvas-for-large.position-top, .off-canvas.in-canvas-for-large.position-bottom {
      box-shadow: none;
      transform: none; }
    .off-canvas.in-canvas-for-large .close-button {
      display: none; } }

.orbit {
  position: relative; }

.orbit-container {
  position: relative;
  height: 0;
  margin: 0;
  list-style: none;
  overflow: hidden; }

.orbit-slide {
  width: 100%; }
  .orbit-slide.no-motionui.is-active {
    top: 0;
    left: 0; }

.orbit-figure {
  margin: 0; }

.orbit-image {
  width: 100%;
  max-width: 100%;
  margin: 0; }

.orbit-caption {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 0;
  padding: 1rem;
  background-color: rgba(10, 10, 10, 0.5);
  color: #fefefe; }

.orbit-previous, .orbit-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  padding: 1rem;
  color: #fefefe; }
  [data-whatinput='mouse'] .orbit-previous, [data-whatinput='mouse'] .orbit-next {
    outline: 0; }
  .orbit-previous:hover, .orbit-next:hover, .orbit-previous:active, .orbit-next:active, .orbit-previous:focus, .orbit-next:focus {
    background-color: rgba(10, 10, 10, 0.5); }

.orbit-previous {
  left: 0; }

.orbit-next {
  left: auto;
  right: 0; }

.orbit-bullets {
  position: relative;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  text-align: center; }
  [data-whatinput='mouse'] .orbit-bullets {
    outline: 0; }
  .orbit-bullets button {
    width: 1.2rem;
    height: 1.2rem;
    margin: 0.1rem;
    border-radius: 50%;
    background-color: #cacaca; }
    .orbit-bullets button:hover {
      background-color: #8a8a8a; }
    .orbit-bullets button.is-active {
      background-color: #8a8a8a; }

.pagination {
  margin-left: 0;
  margin-bottom: 1rem; }
  .pagination::before, .pagination::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .pagination::after {
    clear: both; }
  .pagination li {
    margin-right: 0.0625rem;
    border-radius: 0;
    font-size: 0.875rem;
    display: none; }
    .pagination li:last-child, .pagination li:first-child {
      display: inline-block; }
    @media print, screen and (min-width: 40em) {
      .pagination li {
        display: inline-block; } }
  .pagination a,
  .pagination button {
    display: block;
    padding: 0.1875rem 0.625rem;
    border-radius: 0;
    color: #0a0a0a; }
    .pagination a:hover,
    .pagination button:hover {
      background: #e6e6e6; }
  .pagination .current {
    padding: 0.1875rem 0.625rem;
    background: #00acc1;
    color: #fefefe;
    cursor: default; }
  .pagination .disabled {
    padding: 0.1875rem 0.625rem;
    color: #cacaca;
    cursor: not-allowed; }
    .pagination .disabled:hover {
      background: transparent; }
  .pagination .ellipsis::after {
    padding: 0.1875rem 0.625rem;
    content: '\2026';
    color: #0a0a0a; }

.pagination-previous a::before,
.pagination-previous.disabled::before {
  display: inline-block;
  margin-right: 0.5rem;
  content: '\00ab'; }

.pagination-next a::after,
.pagination-next.disabled::after {
  display: inline-block;
  margin-left: 0.5rem;
  content: '\00bb'; }

.progress {
  height: 1rem;
  margin-bottom: 1rem;
  border-radius: 0;
  background-color: #cacaca; }
  .progress.primary .progress-meter {
    background-color: #00acc1; }
  .progress.secondary .progress-meter {
    background-color: #767676; }
  .progress.success .progress-meter {
    background-color: #3adb76; }
  .progress.warning .progress-meter {
    background-color: #ffae00; }
  .progress.alert .progress-meter {
    background-color: #cc4b37; }

.progress-meter {
  position: relative;
  display: block;
  width: 0%;
  height: 100%;
  background-color: #00acc1; }

.progress-meter-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  margin: 0;
  font-size: 0.75rem;
  font-weight: bold;
  color: #fefefe;
  white-space: nowrap; }

.slider {
  position: relative;
  height: 0.5rem;
  margin-top: 1.25rem;
  margin-bottom: 2.25rem;
  background-color: #e6e6e6;
  cursor: pointer;
  user-select: none;
  touch-action: none; }

.slider-fill {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  max-width: 100%;
  height: 0.5rem;
  background-color: #cacaca;
  transition: all 0.2s ease-in-out; }
  .slider-fill.is-dragging {
    transition: all 0s linear; }

.slider-handle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 1;
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 0;
  background-color: #00acc1;
  transition: all 0.2s ease-in-out;
  touch-action: manipulation; }
  [data-whatinput='mouse'] .slider-handle {
    outline: 0; }
  .slider-handle:hover {
    background-color: #0092a4; }
  .slider-handle.is-dragging {
    transition: all 0s linear; }

.slider.disabled,
.slider[disabled] {
  opacity: 0.25;
  cursor: not-allowed; }

.slider.vertical {
  display: inline-block;
  width: 0.5rem;
  height: 12.5rem;
  margin: 0 1.25rem;
  transform: scale(1, -1); }
  .slider.vertical .slider-fill {
    top: 0;
    width: 0.5rem;
    max-height: 100%; }
  .slider.vertical .slider-handle {
    position: absolute;
    top: 0;
    left: 50%;
    width: 1.4rem;
    height: 1.4rem;
    transform: translateX(-50%); }

.sticky-container {
  position: relative; }

.sticky {
  position: relative;
  z-index: 0;
  transform: translate3d(0, 0, 0); }

.sticky.is-stuck {
  position: fixed;
  z-index: 5;
  width: 100%; }
  .sticky.is-stuck.is-at-top {
    top: 0; }
  .sticky.is-stuck.is-at-bottom {
    bottom: 0; }

.sticky.is-anchored {
  position: relative;
  right: auto;
  left: auto; }
  .sticky.is-anchored.is-at-bottom {
    bottom: 0; }

body.is-reveal-open {
  overflow: hidden; }

html.is-reveal-open,
html.is-reveal-open body {
  min-height: 100%;
  overflow: hidden;
  position: fixed;
  user-select: none; }

.reveal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  display: none;
  background-color: rgba(10, 10, 10, 0.45);
  overflow-y: scroll; }

.reveal {
  z-index: 1006;
  backface-visibility: hidden;
  display: none;
  padding: 1rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  position: relative;
  top: 100px;
  margin-right: auto;
  margin-left: auto;
  overflow-y: auto; }
  [data-whatinput='mouse'] .reveal {
    outline: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal {
      min-height: 0; } }
  .reveal .column, .reveal .columns {
    min-width: 0; }
  .reveal > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal {
      width: 600px;
      max-width: 75rem; } }
  .reveal.collapse {
    padding: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal.tiny {
      width: 30%;
      max-width: 75rem; } }
  @media print, screen and (min-width: 40em) {
    .reveal.small {
      width: 50%;
      max-width: 75rem; } }
  @media print, screen and (min-width: 40em) {
    .reveal.large {
      width: 90%;
      max-width: 75rem; } }
  .reveal.full {
    top: 0;
    left: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    margin-left: 0;
    border: 0;
    border-radius: 0; }
  @media screen and (max-width: 39.9375em) {
    .reveal {
      top: 0;
      left: 0;
      width: 100%;
      max-width: none;
      height: 100%;
      height: 100vh;
      min-height: 100vh;
      margin-left: 0;
      border: 0;
      border-radius: 0; } }
  .reveal.without-overlay {
    position: fixed; }

.switch {
  height: 2rem;
  position: relative;
  margin-bottom: 1rem;
  outline: 0;
  font-size: 0.875rem;
  font-weight: bold;
  color: #fefefe;
  user-select: none; }

.switch-input {
  position: absolute;
  margin-bottom: 0;
  opacity: 0; }

.switch-paddle {
  position: relative;
  display: block;
  width: 4rem;
  height: 2rem;
  border-radius: 0;
  background: #cacaca;
  transition: all 0.25s ease-out;
  font-weight: inherit;
  color: inherit;
  cursor: pointer; }
  input + .switch-paddle {
    margin: 0; }
  .switch-paddle::after {
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    transform: translate3d(0, 0, 0);
    border-radius: 0;
    background: #fefefe;
    transition: all 0.25s ease-out;
    content: ''; }
  input:checked ~ .switch-paddle {
    background: #00acc1; }
    input:checked ~ .switch-paddle::after {
      left: 2.25rem; }
  [data-whatinput='mouse'] input:focus ~ .switch-paddle {
    outline: 0; }

.switch-active, .switch-inactive {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.switch-active {
  left: 8%;
  display: none; }
  input:checked + label > .switch-active {
    display: block; }

.switch-inactive {
  right: 15%; }
  input:checked + label > .switch-inactive {
    display: none; }

.switch.tiny {
  height: 1.5rem; }
  .switch.tiny .switch-paddle {
    width: 3rem;
    height: 1.5rem;
    font-size: 0.625rem; }
  .switch.tiny .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 1rem;
    height: 1rem; }
  .switch.tiny input:checked ~ .switch-paddle::after {
    left: 1.75rem; }

.switch.small {
  height: 1.75rem; }
  .switch.small .switch-paddle {
    width: 3.5rem;
    height: 1.75rem;
    font-size: 0.75rem; }
  .switch.small .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 1.25rem;
    height: 1.25rem; }
  .switch.small input:checked ~ .switch-paddle::after {
    left: 2rem; }

.switch.large {
  height: 2.5rem; }
  .switch.large .switch-paddle {
    width: 5rem;
    height: 2.5rem;
    font-size: 1rem; }
  .switch.large .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 2rem;
    height: 2rem; }
  .switch.large input:checked ~ .switch-paddle::after {
    left: 2.75rem; }

table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 0; }
  table thead,
  table tbody,
  table tfoot {
    border: 1px solid #f1f1f1;
    background-color: #fefefe; }
  table caption {
    padding: 0.5rem 0.625rem 0.625rem;
    font-weight: bold; }
  table thead {
    background: #f8f8f8;
    color: #0a0a0a; }
  table tfoot {
    background: #f1f1f1;
    color: #0a0a0a; }
  table thead tr,
  table tfoot tr {
    background: transparent; }
  table thead th,
  table thead td,
  table tfoot th,
  table tfoot td {
    padding: 0.5rem 0.625rem 0.625rem;
    font-weight: bold;
    text-align: left; }
  table tbody th,
  table tbody td {
    padding: 0.5rem 0.625rem 0.625rem; }
  table tbody tr:nth-child(even) {
    border-bottom: 0;
    background-color: #f1f1f1; }
  table.unstriped tbody {
    background-color: #fefefe; }
    table.unstriped tbody tr {
      border-bottom: 0;
      border-bottom: 1px solid #f1f1f1;
      background-color: #fefefe; }

@media screen and (max-width: 63.9375em) {
  table.stack thead {
    display: none; }
  table.stack tfoot {
    display: none; }
  table.stack tr,
  table.stack th,
  table.stack td {
    display: block; }
  table.stack td {
    border-top: 0; } }

table.scroll {
  display: block;
  width: 100%;
  overflow-x: auto; }

table.hover thead tr:hover {
  background-color: #f3f3f3; }

table.hover tfoot tr:hover {
  background-color: #ececec; }

table.hover tbody tr:hover {
  background-color: #f9f9f9; }

table.hover:not(.unstriped) tr:nth-of-type(even):hover {
  background-color: #ececec; }

.table-scroll {
  overflow-x: auto; }
  .table-scroll table {
    width: auto; }

.tabs {
  margin: 0;
  border: 1px solid #e6e6e6;
  background: #fefefe;
  list-style-type: none; }
  .tabs::before, .tabs::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .tabs::after {
    clear: both; }

.tabs.vertical > li {
  display: block;
  float: none;
  width: auto; }

.tabs.simple > li > a {
  padding: 0; }
  .tabs.simple > li > a:hover {
    background: transparent; }

.tabs.primary {
  background: #00acc1; }
  .tabs.primary > li > a {
    color: #0a0a0a; }
    .tabs.primary > li > a:hover, .tabs.primary > li > a:focus {
      background: #00bad1; }

.tabs-title {
  float: left; }
  .tabs-title > a {
    display: block;
    padding: 1.25rem 1.5rem;
    font-size: 0.75rem;
    line-height: 1;
    color: #00acc1; }
    .tabs-title > a:hover {
      background: #fefefe;
      color: #0094a6; }
    .tabs-title > a:focus, .tabs-title > a[aria-selected='true'] {
      background: #e6e6e6;
      color: #00acc1; }

.tabs-content {
  border: 1px solid #e6e6e6;
  border-top: 0;
  background: #fefefe;
  color: #0a0a0a;
  transition: all 0.5s ease; }

.tabs-content.vertical {
  border: 1px solid #e6e6e6;
  border-left: 0; }

.tabs-panel {
  display: none;
  padding: 1rem; }
  .tabs-panel.is-active {
    display: block; }

.thumbnail {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 1rem;
  border: solid 4px #fefefe;
  border-radius: 0;
  box-shadow: 0 0 0 1px rgba(10, 10, 10, 0.2);
  line-height: 0; }

a.thumbnail {
  transition: box-shadow 200ms ease-out; }
  a.thumbnail:hover, a.thumbnail:focus {
    box-shadow: 0 0 6px 1px rgba(0, 172, 193, 0.5); }
  a.thumbnail image {
    box-shadow: none; }

.title-bar {
  padding: 0.5rem;
  background: #0a0a0a;
  color: #fefefe;
  display: flex;
  justify-content: flex-start;
  align-items: center; }
  .title-bar .menu-icon {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }

.title-bar-left,
.title-bar-right {
  flex: 1 1 0px; }

.title-bar-right {
  text-align: right; }

.title-bar-title {
  display: inline-block;
  vertical-align: middle;
  font-weight: bold; }

.has-tip {
  position: relative;
  display: inline-block;
  border-bottom: dotted 1px #8a8a8a;
  font-weight: bold;
  cursor: help; }

.tooltip {
  position: absolute;
  top: calc(100% + 0.6495rem);
  z-index: 1200;
  max-width: 10rem;
  padding: 0.75rem;
  border-radius: 0;
  background-color: #0a0a0a;
  font-size: 80%;
  color: #fefefe; }
  .tooltip::before {
    position: absolute; }
  .tooltip.bottom::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-top-width: 0;
    border-bottom-style: solid;
    border-color: transparent transparent #0a0a0a;
    bottom: 100%; }
  .tooltip.bottom.align-center::before {
    left: 50%;
    transform: translateX(-50%); }
  .tooltip.top::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #0a0a0a transparent transparent;
    top: 100%;
    bottom: auto; }
  .tooltip.top.align-center::before {
    left: 50%;
    transform: translateX(-50%); }
  .tooltip.left::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #0a0a0a;
    left: 100%; }
  .tooltip.left.align-center::before {
    bottom: auto;
    top: 50%;
    transform: translateY(-50%); }
  .tooltip.right::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #0a0a0a transparent transparent;
    right: 100%;
    left: auto; }
  .tooltip.right.align-center::before {
    bottom: auto;
    top: 50%;
    transform: translateY(-50%); }
  .tooltip.align-top::before {
    bottom: auto;
    top: 10%; }
  .tooltip.align-bottom::before {
    bottom: 10%;
    top: auto; }
  .tooltip.align-left::before {
    left: 10%;
    right: auto; }
  .tooltip.align-right::before {
    left: auto;
    right: 10%; }

.top-bar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  flex-wrap: wrap; }
  .top-bar,
  .top-bar ul {
    background-color: #e6e6e6; }
  .top-bar input {
    max-width: 200px;
    margin-right: 1rem; }
  .top-bar .input-group-field {
    width: 100%;
    margin-right: 0; }
  .top-bar input.button {
    width: auto; }
  .top-bar .top-bar-left,
  .top-bar .top-bar-right {
    flex: 0 0 100%;
    max-width: 100%; }
  @media print, screen and (min-width: 40em) {
    .top-bar {
      flex-wrap: nowrap; }
      .top-bar .top-bar-left {
        flex: 1 1 auto;
        margin-right: auto; }
      .top-bar .top-bar-right {
        flex: 0 1 auto;
        margin-left: auto; } }
  @media screen and (max-width: 63.9375em) {
    .top-bar.stacked-for-medium {
      flex-wrap: wrap; }
      .top-bar.stacked-for-medium .top-bar-left,
      .top-bar.stacked-for-medium .top-bar-right {
        flex: 0 0 100%;
        max-width: 100%; } }
  @media screen and (max-width: 74.9375em) {
    .top-bar.stacked-for-large {
      flex-wrap: wrap; }
      .top-bar.stacked-for-large .top-bar-left,
      .top-bar.stacked-for-large .top-bar-right {
        flex: 0 0 100%;
        max-width: 100%; } }

.top-bar-title {
  flex: 0 0 auto;
  margin: 0.5rem 1rem 0.5rem 0; }

.top-bar-left,
.top-bar-right {
  flex: 0 0 auto; }

.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media screen and (max-width: 39.9375em) {
  .hide-for-small-only {
    display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important; } }

@media print, screen and (min-width: 40em) {
  .hide-for-medium {
    display: none !important; } }

@media screen and (max-width: 39.9375em) {
  .show-for-medium {
    display: none !important; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .hide-for-medium-only {
    display: none !important; } }

@media screen and (max-width: 39.9375em), screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important; } }

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important; } }

@media screen and (max-width: 63.9375em) {
  .show-for-large {
    display: none !important; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important; } }

@media screen and (max-width: 63.9375em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important; } }

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.show-for-landscape,
.hide-for-portrait {
  display: block !important; }
  @media screen and (orientation: landscape) {
    .show-for-landscape,
    .hide-for-portrait {
      display: block !important; } }
  @media screen and (orientation: portrait) {
    .show-for-landscape,
    .hide-for-portrait {
      display: none !important; } }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }
  @media screen and (orientation: landscape) {
    .hide-for-landscape,
    .show-for-portrait {
      display: none !important; } }
  @media screen and (orientation: portrait) {
    .hide-for-landscape,
    .show-for-portrait {
      display: block !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.clearfix::before, .clearfix::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1; }

.clearfix::after {
  clear: both; }

.slide-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(-100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-down.mui-enter.mui-enter-active {
  transform: translateY(0); }

.slide-in-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(-100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-left.mui-enter.mui-enter-active {
  transform: translateX(0); }

.slide-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-up.mui-enter.mui-enter-active {
  transform: translateY(0); }

.slide-in-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-right.mui-enter.mui-enter-active {
  transform: translateX(0); }

.slide-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-down.mui-leave.mui-leave-active {
  transform: translateY(100%); }

.slide-out-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-right.mui-leave.mui-leave-active {
  transform: translateX(100%); }

.slide-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-up.mui-leave.mui-leave-active {
  transform: translateY(-100%); }

.slide-out-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-left.mui-leave.mui-leave-active {
  transform: translateX(-100%); }

.fade-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 0;
  transition-property: opacity; }

.fade-in.mui-enter.mui-enter-active {
  opacity: 1; }

.fade-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 1;
  transition-property: opacity; }

.fade-out.mui-leave.mui-leave-active {
  opacity: 0; }

.hinge-in-from-top.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-top.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-right.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-bottom.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(90deg);
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-bottom.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(90deg);
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-left.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-middle-x.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-middle-x.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-middle-y.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-middle-y.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-out-from-top.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-top.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0; }

.hinge-out-from-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-right.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0; }

.hinge-out-from-bottom.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-bottom.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(90deg);
  opacity: 0; }

.hinge-out-from-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-left.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(90deg);
  opacity: 0; }

.hinge-out-from-middle-x.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-middle-x.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0; }

.hinge-out-from-middle-y.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-middle-y.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0; }

.scale-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(0.5);
  transition-property: transform, opacity;
  opacity: 0; }

.scale-in-up.mui-enter.mui-enter-active {
  transform: scale(1);
  opacity: 1; }

.scale-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1.5);
  transition-property: transform, opacity;
  opacity: 0; }

.scale-in-down.mui-enter.mui-enter-active {
  transform: scale(1);
  opacity: 1; }

.scale-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1; }

.scale-out-up.mui-leave.mui-leave-active {
  transform: scale(1.5);
  opacity: 0; }

.scale-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1; }

.scale-out-down.mui-leave.mui-leave-active {
  transform: scale(0.5);
  opacity: 0; }

.spin-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(-0.75turn);
  transition-property: transform, opacity;
  opacity: 0; }

.spin-in.mui-enter.mui-enter-active {
  transform: rotate(0);
  opacity: 1; }

.spin-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1; }

.spin-out.mui-leave.mui-leave-active {
  transform: rotate(0.75turn);
  opacity: 0; }

.spin-in-ccw.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0.75turn);
  transition-property: transform, opacity;
  opacity: 0; }

.spin-in-ccw.mui-enter.mui-enter-active {
  transform: rotate(0);
  opacity: 1; }

.spin-out-ccw.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1; }

.spin-out-ccw.mui-leave.mui-leave-active {
  transform: rotate(-0.75turn);
  opacity: 0; }

.slow {
  transition-duration: 750ms !important; }

.fast {
  transition-duration: 250ms !important; }

.linear {
  transition-timing-function: linear !important; }

.ease {
  transition-timing-function: ease !important; }

.ease-in {
  transition-timing-function: ease-in !important; }

.ease-out {
  transition-timing-function: ease-out !important; }

.ease-in-out {
  transition-timing-function: ease-in-out !important; }

.bounce-in {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.bounce-out {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.bounce-in-out {
  transition-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.short-delay {
  transition-delay: 300ms !important; }

.long-delay {
  transition-delay: 700ms !important; }

.shake {
  animation-name: shake-7; }

@keyframes shake-7 {
  0%, 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% {
    transform: translateX(7%); }
  5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% {
    transform: translateX(-7%); } }

.spin-cw {
  animation-name: spin-cw-1turn; }

@keyframes spin-cw-1turn {
  0% {
    transform: rotate(-1turn); }
  100% {
    transform: rotate(0); } }

.spin-ccw {
  animation-name: spin-cw-1turn; }

@keyframes spin-cw-1turn {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(1turn); } }

.wiggle {
  animation-name: wiggle-7deg; }

@keyframes wiggle-7deg {
  40%, 50%, 60% {
    transform: rotate(7deg); }
  35%, 45%, 55%, 65% {
    transform: rotate(-7deg); }
  0%, 30%, 70%, 100% {
    transform: rotate(0); } }

.shake,
.spin-cw,
.spin-ccw,
.wiggle {
  animation-duration: 500ms; }

.infinite {
  animation-iteration-count: infinite; }

.slow {
  animation-duration: 750ms !important; }

.fast {
  animation-duration: 250ms !important; }

.linear {
  animation-timing-function: linear !important; }

.ease {
  animation-timing-function: ease !important; }

.ease-in {
  animation-timing-function: ease-in !important; }

.ease-out {
  animation-timing-function: ease-out !important; }

.ease-in-out {
  animation-timing-function: ease-in-out !important; }

.bounce-in {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.bounce-out {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.bounce-in-out {
  animation-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.short-delay {
  animation-delay: 300ms !important; }

.long-delay {
  animation-delay: 700ms !important; }

/*
 * zurb_foundation.scss - Contains all Drupal-specific SASS for this theme.
 *
 * @todo Split this into multiple fnewers/files.
*/
.region-highlighted:empty {
  display: none; }

.toolbar-tray a {
  font-size: 13px; }

.toolbar .toolbar-toggle-orientation .toolbar-icon {
  margin: 0; }
  .toolbar .toolbar-toggle-orientation .toolbar-icon:before {
    min-height: 0; }

.top-bar-section ul li {
  padding-top: 0; }
  .top-bar-section ul li a.is-active {
    color: #FFFFFF; }

body.toolbar-fixed .sticky.is-stuck .top-bar {
  margin-top: 39px; }

body.toolbar-fixed.toolbar-horizontal.toolbar-tray-open .sticky.is-stuck .top-bar {
  margin-top: 78px; }

body.toolbar-fixed .sticky.is-stuck.is-at-top {
  margin-top: 0 !important; }

.top-bar .menu-item--collapsed, .top-bar .menu-item--expanded {
  list-style: none; }

.top-bar .search-block-form .form-item-keys, .top-bar .search-block-form .form-actions {
  display: inline-block; }

.dropbutton-wrapper {
  background-color: #219653;
  margin: 0 0 1.25rem; }
  .dropbutton-wrapper .button {
    margin-bottom: 0;
    width: 100%;
    text-align: left; }

.element-invisible {
  display: none; }

.button-group a.button.secondary.active {
  background: #4F4F4F; }

.top-panel {
  min-height: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #00acc1;
  color: #fefefe;
  font-size: 16px;
  font-weight: 400; }
  .top-panel .block-block-8 {
    width: 160%; }
    @media screen and (min-width: 40em) {
      .top-panel .block-block-8 {
        width: 100%; } }
  .top-panel a {
    color: #fefefe;
    font-size: 16px;
    font-weight: 400; }
  .top-panel p {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #fefefe;
    margin-bottom: 0rem; }

.language-switcher {
  float: right;
  display: block;
  margin-top: 5px;
  margin-right: 40px;
  width: 100px;
  background-color: transparent; }
  .language-switcher ul.language-switcher-locale-url {
    list-style-type: none; }
    .language-switcher ul.language-switcher-locale-url li {
      display: inline; }
      .language-switcher ul.language-switcher-locale-url li img {
        display: none; }
  .language-switcher .language-link {
    display: inline;
    font-weight: normal;
    font-size: 0.95rem;
    margin-left: 20px; }
  .language-switcher .active .language-link {
    display: inline;
    font-weight: bold;
    text-decoration: underline;
    font-size: 0.95rem;
    margin-left: 20px; }

.top-container_wrap {
  margin: 0 auto; }

.social-links {
  padding-top: 10px;
  padding-bottom: 10px; }

.header-container {
  background-color: #00acc1;
  color: #fefefe;
  font-size: 16px;
  font-weight: 400; }
  .header-container a {
    color: #fefefe; }

.header-button {
  background-color: #00acc1; }

.footer-button {
  background-color: #00acc1; }

.header-container {
  background-color: #00acc1;
  color: #fefefe;
  font-size: 16px;
  font-weight: 400; }
  .header-container a {
    color: #fefefe; }

.tb-megamenu .taxonomy-menu-terms ul.menu a.active {
  color: #00acc1; }

.tb-megamenu .responsive-menus-mean-menu-processed {
  padding-top: 10px; }

.main-menu-small-1 .offcanvas-full-screen-menu {
  padding-top: -1px; }

.offcanvas-full-screen-menu {
  padding-top: -1px;
  display: inline-block !important; }
  .offcanvas-full-screen-menu ul:first-child {
    display: inline-block !important; }

.main-menu-small-1 .offcanvas-full-screen-menu:first-child {
  padding-top: 10px; }

.offcanvas-full-screen {
  position: fixed;
  z-index: 12;
  transition: transform 0.3s ease-in-out;
  backface-visibility: hidden;
  background: #284349;
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto; }
  [data-whatinput='mouse'] .offcanvas-full-screen {
    outline: 0; }
  .offcanvas-full-screen.is-transition-push {
    z-index: 12; }
  .offcanvas-full-screen.is-closed {
    visibility: hidden; }
  .offcanvas-full-screen.is-transition-overlap {
    z-index: 13; }
    .offcanvas-full-screen.is-transition-overlap.is-open {
      box-shadow: 0 0 10px rgba(10, 10, 10, 0.7); }
  .offcanvas-full-screen.is-open {
    transform: translate(0, 0); }
  @media screen and (min-width: 80em) {
    .offcanvas-full-screen {
      transform: translateX(-); } }
  @media screen and (min-width: 80em) {
    .off-canvas-content .off-canvas.position-left {
      transform: translateX(-); } }
  .off-canvas-content .off-canvas.position-left.is-transition-overlap.is-open {
    transform: translate(0, 0); }
  @media screen and (min-width: 80em) {
    .off-canvas-content.is-open-left.has-transition-push {
      transform: translateX(); } }
  .offcanvas-full-screen.is-transition-push {
    box-shadow: inset -13px 0 20px -13px rgba(10, 10, 10, 0.25); }

.offcanvas-full-screen.is-transition-overlap {
  z-index: 100; }

.offcanvas-full-screen-inner {
  text-align: left; }
  @media screen and (max-width: 39.9375em) {
    .offcanvas-full-screen-inner {
      padding-top: 0rem;
      padding-bottom: 5rem;
      padding-left: 0rem;
      padding-right: 0rem; } }

.menu.vertical > li > a {
  padding-top: 13px !important;
  padding-bottom: 13px !important;
  display: inline-block;
  position: relative; }

.menu > li > a {
  padding-top: 13px !important;
  padding-bottom: 13px !important; }

.offcanvas-full-screen-menu {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-wrap: nowrap;
  flex-direction: column;
  /*
  li.is-accordion-submenu-parent:after{
    position:relative;
    color: white;
    content: '▾';
  }
  */
  /*
  li.is-accordion-submenu-parent[aria-expanded="false"]:first-child:after {
    content: "▾";
    right: 6%;
    font-size: 2rem;
    color: white;
    position: relative;
    float: right;
    margin-top: -60px;
  }
  li.is-accordion-submenu-parent[aria-expanded="true"]:first-child:after {
    content: " ";
    font-size:2rem;
    position: relative;
    padding-left: 98%;
    float:left;
    color: white;
    left: 0;
    top: 0;
  }
*/ }
  [data-whatinput='mouse'] .offcanvas-full-screen-menu li {
    outline: 0; }
  .offcanvas-full-screen-menu a,
  .offcanvas-full-screen-menu .button {
    line-height: 1;
    text-decoration: none;
    display: block;
    padding: 0.7rem 1rem; }
  .offcanvas-full-screen-menu input,
  .offcanvas-full-screen-menu select,
  .offcanvas-full-screen-menu a,
  .offcanvas-full-screen-menu button {
    margin-bottom: 0; }
  .offcanvas-full-screen-menu input {
    display: inline-block; }
  .offcanvas-full-screen-menu .logo {
    fill: #fff; }
  .offcanvas-full-screen-menu .menu.nested {
    margin-left: 0rem; }
  .offcanvas-full-screen-menu .menu > li > a {
    padding-top: 20px !important;
    padding-bottom: 20px !important; }
  .offcanvas-full-screen-menu a {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 1.7rem;
    color: #fefefe;
    display: inline-block !important;
    width: 100%; }
    .offcanvas-full-screen-menu a:hover {
      background-color: #b2b2b2; }
  .offcanvas-full-screen-menu a.active {
    background-color: #418794;
    font-size: 1.9rem; }
  .offcanvas-full-screen-menu a.active-trail {
    background-color: #418794;
    font-size: 1.9rem; }
  .offcanvas-full-screen-menu ul {
    list-style-type: none;
    margin: 0;
    background-color: #8BC2C3;
    padding-bottom: 0px;
    margin-bottom: 0px; }
    .offcanvas-full-screen-menu ul li {
      width: 100%;
      border-bottom: 1px solid #3F8591;
      padding-bottom: 0px;
      margin-bottom: 0px;
      display: inline-table; }
  .offcanvas-full-screen-menu li {
    border-bottom: 1px solid #418794;
    display: inline-block;
    position: relative; }
  .offcanvas-full-screen-menu li.is-accordion-submenu-parent[aria-expanded="true"] {
    background-color: #3F8591; }

.offcanvas-full-screen-menu > li > a {
  display: inline-block !important; }

.home-button-wrapper {
  padding-left: 15px;
  padding-top: 20px; }
  .home-button-wrapper a {
    padding-left: 0rem;
    float: left;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: bold;
    font-size: 1.7rem;
    border-color: transparent;
    color: #8EC7C7;
    height: 100%;
    width: 100%;
    outline: none;
    padding-bottom: 20px !important; }

.searchAreaBtn {
  margin: 0 auto;
  vertical-align: middle; }

.mymainmenu-full-width {
  width: 100%; }

.mymainmenu {
  color: #00acc1;
  /*
  position: relative;
  width: 100%;
  */
  /*
  .active a:before{
    content: " ";
    position: absolute;
    left: 0%;
    width: 100%;
    bottom: 0.3rem;
    background-color: $blue;
  }
  */ }
  .mymainmenu ul {
    /*  position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    height: 100%;
    */
    margin-left: 0px;
    display: inline-block;
    list-style-type: none;
    background-color: transparent; }
  .mymainmenu ul > li {
    display: inline-block; }

.header-lang {
  padding-top: 30px; }

.tb-megamenu.style-blue {
  background: transparent;
  padding: 10px 0; }
  .tb-megamenu.style-blue ul li a {
    height: auto;
    border-right: 0 !important; }

a {
  height: auto !important; }

.tb-megamenu.style-blue .nav > li > a {
  border-right: 0px solid #4dc6f3; }

.tb-megamenu .taxonomy-menu-terms ul.menu a {
  padding: 3px 20px; }

.tb-megamenu .nav > li > a:focus,
.tb-megamenu .nav > li > a:hover {
  background-color: #fff;
  color: #e54e4b;
  text-decoration: none;
  text-shadow: none;
  outline: none; }

.tb-megamenu .tb-block ul li a:hover,
.tb-megamenu .tb-block ul li a:focus,
.tb-megamenu .tb-block ul li a:active,
.tb-megamenu .tb-block .nav li a:hover,
.tb-megamenu .tb-block .nav li a:focus,
.tb-megamenu .tb-block .nav li a:active {
  background: initial;
  font-family: "Open Sans", sans-serif;
  text-decoration: none;
  outline-width: initial;
  font-size: 14px;
  margin-bottom: 0px; }

.tb-megamenu .mega.open > .mega-dropdown-menu,
.tb-megamenu .mega.dropdown-submenu:hover > .mega-dropdown-menu {
  display: block; }

.tb-megamenu .tb-block ul li a:hover,
.tb-megamenu .tb-block ul li a:focus,
.tb-megamenu .tb-block ul li a:active,
.tb-megamenu .tb-block .nav li a:hover,
.tb-megamenu .tb-block .nav li a:focus,
.tb-megamenu .tb-block .nav li a:active {
  background-attachment: scroll;
  background-clip: border-box;
  background-color: transparent;
  background-image: none;
  background-origin: padding-box;
  background-position-x: 0%;
  background-position-y: 0%;
  background-size: auto;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  font-family: "Open Sans", sans-serif;
  display: block;
  padding: 3px 20px;
  clear: both;
  line-height: 18px;
  color: #333;
  white-space: nowrap;
  border-top: 0; }

.tb-megamenu-main-menu {
  text-align: center; }
  .tb-megamenu-main-menu ul.menu li {
    text-align: left; }

.tb-megamenu .nav li.dropdown > .dropdown-toggle .caret {
  border-top-color: #666;
  border-bottom-color: #666;
  margin-top: 15px; }

.tb-megamenu .nav > li > a:hover {
  text-decoration: none;
  background-color: #eee; }

.tb-megamenu.style-blue .nav > .active > a,
.tb-megamenu.style-blue .nav > .active > a:hover,
.tb-megamenu.style-blue .nav > .active > a:focus {
  background-color: white;
  color: #00acc1; }

.tb-megamenu .taxonomy-menu-terms ul.menu a {
  font-family: "Open Sans", sans-serif;
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 18px;
  color: #333;
  white-space: nowrap; }

.tb-megamenu .taxonomy-menu-terms ul.menu a.active {
  color: #00acc1;
  cursor: pointer;
  display: block;
  font-family: "Open Sans", sans-serif;
  font-size: 1.4rem;
  font-style: normal;
  height: 24px;
  letter-spacing: normal;
  line-height: 24px;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  text-align: left;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  visibility: visible;
  white-space: nowrap; }

/*
 .tb-megamenu .mega-nav > li:first-child > a, .tb-megamenu .dropdown-menu .mega-nav > li:first-child > a
 clear: both;
    color: #333;
    display: block;
    line-height: 20px;
    font-weight: normal;
    padding: 3px 20px;
    text-shadow: none;
    white-space: nowrap;
    
    */
.secondary-menu {
  float: right; }

.top-bar:after {
  content: url(/sites/all/themes/custom/foundation/src/assets/img/logo.png);
  background: transparent;
  position: relative;
  right: 3%;
  top: 60px;
  display: none; }

.top-bar .top-bar-right {
  width: auto !important; }

.block-menu-menu-top-menu-bar .menu > li > a {
  margin-top: 6px !important;
  padding-top: 6px !important; }

.custom-top-bar {
  min-height: 50px;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  background-color: #00acc1; }
  .custom-top-bar .info-area-1 {
    height: 100%; }
  .custom-top-bar .vertical-center {
    position: relative;
    top: 50%;
    transform: translateY(50%); }
  .custom-top-bar .contact-info {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    align-self: center; }
    .custom-top-bar .contact-info .info-area-1 {
      align-self: center;
      margin-right: 120px; }
    .custom-top-bar .contact-info .info-area-2 {
      align-self: center; }
    .custom-top-bar .contact-info .info-text {
      margin-right: 20px; }
    .custom-top-bar .contact-info .info-text a {
      display: inline-block; }

.top-bar {
  font-size: 14px;
  background-color: #fff;
  padding: 0 !important;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 0px;
  /*
    a,a:hover,a:visited,a:active{
      color: $white;
      cursor:pointer;
      display:block ;
      font-family: "Open Sans", sans-serif;//Alegreya
      font-size: 14px;//1.4rem;
      //font-style:normal;
      font-weight:400;
      height:24px;
      //letter-spacing:normal;
      //line-height:24px;
      list-style-image:none;
      list-style-position:outside;
      list-style-type:none;
      text-align:left;
      //text-size-adjust:100%;
      //visibility:visible;
      //white-space:nowrap;
    }
   
  
    @include breakpoint(small only) {
      padding-bottom: 0;
    }

    */ }
  .top-bar-title {
    padding-top: 0;
    color: #000;
    margin-left: 0;
    margin-right: 0; }
    @media screen and (max-width: 63.9375em) {
      .top-bar-title {
        flex: 0 0 50%;
        max-width: 50%;
        padding-right: 0.625rem;
        padding-left: 0.625rem; } }
  @media screen and (max-width: 63.9375em) and (min-width: 40em) {
    .top-bar-title {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
    .top-bar-title img {
      margin-left: 0; }
  .top-bar .active-trail a {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-size: 14px;
    color: #fefefe; }
  .top-bar ul {
    background-color: transparent; }
    .top-bar ul a {
      color: #fefefe; }
  .top-bar a {
    padding: 0; }
  .top-bar .logo {
    height: 5rem;
    fill: #fff; }
    @media screen and (max-width: 39.9375em) {
      .top-bar .logo {
        height: 4rem;
        float: left; } }

.custom-menu-icon {
  top: -10px;
  text-align: right;
  /*
    @include hamburger($color: #418794,$color-hover: $dark-gray, $width: 29px, $height: 29px,$weight:2px,$bars: 3);
    */ }

.top-bar.top-bar--white {
  background-color: #00acc1;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  color: #fefefe;
  /*
    .menu-icon {
      @include hamburger($color: #4F4F4F, $width: 40px, $height: 25px);
    }
  */ }
  .top-bar.top-bar--white .logo {
    fill: #00acc1; }

@media screen and (min-width: 1024px) and (max-width: 1230px) {
  .show-for-small-only-custom-wrapper {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    display: block; } }
  @media screen and (min-width: 1024px) and (max-width: 1230px) and (min-width: 40em) {
    .show-for-small-only-custom-wrapper {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }

@media screen and (max-width: 1230px) {
  .custom-menu-icon {
    width: 50px;
    height: 50px;
    float: right;
    margin-top: 1rem; } }

@media screen and (min-width: 1231px) {
  .show-for-small-only-custom-wrapper {
    display: none; } }

.footer-container_wrap {
  margin: 0 auto;
  font-size: 16px;
  font-size: 1rem; }
  @media screen and (min-width: 40em) {
    .footer-container_wrap {
      max-width: 90%; } }
  @media screen and (max-width: 39.9375em) {
    .footer-container_wrap {
      max-width: 100%; } }
  .footer-container_wrap .textwidget {
    font-size: 20px; }
  .footer-container_wrap .textwidget.invert {
    color: #949494; }

.site-footer.default .footer-container {
  padding-top: 14px;
  padding-bottom: 14px; }

.footer-container__top {
  padding-top: 10px;
  padding-bottom: 10px; }
  .footer-container__top .footer-copyright {
    margin-left: auto;
    float: right; }
    .footer-container__top .footer-copyright em {
      color: #00acc1; }
    .footer-container__top .footer-copyright span {
      color: #ffffff; }

.invert.footer-container,
.invert.footer-social-wrap {
  border-color: rgba(255, 255, 255, 0.15); }

.site-footer {
  background-color: #2c2d2d; }
  .site-footer p {
    color: #ffffff !important;
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 24px;
    font-family: "Open Sans"; }
  .site-footer .block-title {
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 24px;
    color: white;
    font-family: "Open Sans";
    font-weight: bold;
    font-style: normal;
    color: #fefefe;
    text-align: left !important;
    margin-top: 0;
    margin-bottom: 10px; }
  .site-footer h6 {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #fefefe;
    text-align: left !important;
    margin-top: 0;
    margin-bottom: 10px; }
  .site-footer .block-menu-menu-footer-menu ul li {
    width: 100%; }
  .site-footer .footer-area .row aside {
    padding-left: 50px;
    padding-right: 50px; }
  .site-footer .footer-area .row aside:last-child {
    padding-left: 0px;
    padding-right: 0px; }
  @media screen and (min-width: 40em) {
    .site-footer .footer-area {
      padding: 100px 0 23px; } }
  @media screen and (max-width: 39.9375em) {
    .site-footer .footer-area {
      padding: 90px 24px 23px 23px; } }
  .site-footer .footer-area .widget-about__logo {
    margin-top: -50px; }
  .site-footer .footer-area-wrap {
    margin-top: 20px; }
  .site-footer .footer-area-wrap,
  .site-footer .footer-social-wrap {
    background-color: #015b67; }
  .site-footer .invert {
    color: #949494; }
  .site-footer .footer-area .widget-title {
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    font-family: "Open Sans";
    margin-bottom: 10px; }
  .site-footer .footer-img {
    display: inline-block;
    padding-bottom: 30px; }
  .site-footer .invert h6 {
    color: #ffffff; }
  .site-footer .site-footer .invert a,
  .site-footer .site-footer .invert .widget a {
    color: #00acc1; }
  .site-footer .invert .widget ul li a {
    color: #ffffff; }
  .site-footer .widget ul li a,
  .site-footer .elementor-widget ul li a {
    color: #616262; }
  .site-footer .site-footer .invert a,
  .site-footer .invert a:focus {
    color: #ffffff; }
  .site-footer a {
    color: #0674d1; }
  .site-footer a {
    text-decoration: none;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s; }
  .site-footer a {
    background-color: transparent;
    text-decoration: none; }
  .site-footer a {
    color: #ffffff; }

.block-webform {
  box-shadow: 1px 1px 5px #888888; }

.block-webform-client-block-6 {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 30px;
  background-color: white; }
  @media screen and (min-width: 40em) {
    .block-webform-client-block-6 {
      padding-left: 60px;
      padding-right: 60px;
      padding-top: 20px;
      padding-bottom: 40px;
      margin-bottom: 50px; } }
  .block-webform-client-block-6 .webform-submit {
    color: #fefefe;
    width: 100%;
    border-radius: 120px;
    /* send form */
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    text-transform: uppercase;
    background-color: #00acc1;
    color: #fefefe; }

.webform-client-form-6 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  padding-top: 2rem;
  background-color: transparent; }
  .webform-client-form-6 .node-title {
    width: 100%;
    display: block;
    margin: 0 auto;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    color: #1F467A;
    text-transform: uppercase; }
  .webform-client-form-6 .webform-client-form {
    margin: 0 auto; }
  .webform-client-form-6 .contact-header {
    /* STAY IN TOUCH! */
    padding-bottom: 20px;
    text-align: center;
    font-size: 20px;
    color: #00acc1;
    opacity: 1.00; }
  .webform-client-form-6 .contact-body {
    text-align: center;
    padding-bottom: 20px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: #8A8A8A; }
  .webform-client-form-6 .contact_details a {
    color: #858585 !important; }
  .webform-client-form-6 .node-appointment-webform form {
    text-align: center;
    color: #858585;
    padding: 3rem 0; }
    .webform-client-form-6 .node-appointment-webform form .form-item, .webform-client-form-6 .node-appointment-webform form .form-actions {
      text-align: left;
      font-size: 14px;
      font-weight: 800; }
      .webform-client-form-6 .node-appointment-webform form .form-item label, .webform-client-form-6 .node-appointment-webform form .form-actions label {
        color: #858585; }
      .webform-client-form-6 .node-appointment-webform form .form-item input, .webform-client-form-6 .node-appointment-webform form .form-actions input {
        border: 1px solid #273364; }
      .webform-client-form-6 .node-appointment-webform form .form-item ::-moz-placeholder, .webform-client-form-6 .node-appointment-webform form .form-actions ::-moz-placeholder {
        font-family: 'Open Sans', sans-serif;
        opacity: 1;
        color: #858585;
        font-size: 19px;
        font-weight: normal; }
      .webform-client-form-6 .node-appointment-webform form .form-item ::-webkit-input-placeholder, .webform-client-form-6 .node-appointment-webform form .form-actions ::-webkit-input-placeholder {
        font-family: 'Open Sans', sans-serif;
        opacity: 1;
        color: #858585;
        font-size: 19px;
        font-weight: normal; }
      .webform-client-form-6 .node-appointment-webform form .form-item ::-webkit-input-placeholder, .webform-client-form-6 .node-appointment-webform form .form-actions ::-webkit-input-placeholder {
        color: #858585; }
      .webform-client-form-6 .node-appointment-webform form .form-item :-moz-placeholder, .webform-client-form-6 .node-appointment-webform form .form-actions :-moz-placeholder {
        color: #858585;
        opacity: 1; }
      .webform-client-form-6 .node-appointment-webform form .form-item ::-moz-placeholder, .webform-client-form-6 .node-appointment-webform form .form-actions ::-moz-placeholder {
        color: #858585;
        opacity: 1; }
      .webform-client-form-6 .node-appointment-webform form .form-item :-ms-input-placeholder, .webform-client-form-6 .node-appointment-webform form .form-actions :-ms-input-placeholder {
        color: #858585; }
      .webform-client-form-6 .node-appointment-webform form .form-item ::-ms-input-placeholder, .webform-client-form-6 .node-appointment-webform form .form-actions ::-ms-input-placeholder {
        color: #858585; }
      .webform-client-form-6 .node-appointment-webform form .form-item textarea, .webform-client-form-6 .node-appointment-webform form .form-actions textarea {
        outline: 0;
        background-color: #fefefe;
        border: 1px solid #273364; }
      .webform-client-form-6 .node-appointment-webform form .form-item select, .webform-client-form-6 .node-appointment-webform form .form-actions select {
        color: #909090;
        outline: 0;
        background-color: #fefefe;
        border: 1px solid #273364;
        font-size: 16px;
        background: url(/sites/all/themes/custom/foundation/src/assets/img/arrowdown.png) no-repeat right transparent;
        -webkit-appearance: none;
        background-position-x: 95%; }
        .webform-client-form-6 .node-appointment-webform form .form-item select option:first-child, .webform-client-form-6 .node-appointment-webform form .form-actions select option:first-child {
          color: #909090; }
        .webform-client-form-6 .node-appointment-webform form .form-item select option, .webform-client-form-6 .node-appointment-webform form .form-actions select option {
          color: #273364; }
      .webform-client-form-6 .node-appointment-webform form .form-item .form-item-submitted-date-month, .webform-client-form-6 .node-appointment-webform form .form-item .form-item-submitted-date-day, .webform-client-form-6 .node-appointment-webform form .form-actions .form-item-submitted-date-month, .webform-client-form-6 .node-appointment-webform form .form-actions .form-item-submitted-date-day {
        padding-right: 2rem; }
      .webform-client-form-6 .node-appointment-webform form .form-item .form-type-select, .webform-client-form-6 .node-appointment-webform form .form-actions .form-type-select {
        display: inline-block; }
      .webform-client-form-6 .node-appointment-webform form .form-item input[type='image'], .webform-client-form-6 .node-appointment-webform form .form-actions input[type='image'] {
        vertical-align: middle;
        display: none; }
  .webform-client-form-6 .fieldset-wrapper {
    max-width: 75rem;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-flow: row wrap; }

.view-office-photos-slider {
  margin-top: 20px;
  margin-bottom: 20px; }
  .view-office-photos-slider .view-content {
    margin-top: 20px;
    margin-bottom: 20px; }
  .view-office-photos-slider .slick-prev:before {
    content: url("/sites/all/themes/custom/foundation/src/assets/img/prev_dark.png") !important; }
  .view-office-photos-slider > [dir='rtl'] .slick-prev:before {
    content: url("/sites/all/themes/custom/foundation/src/assets/img/prev_dark.png") !important; }
  .view-office-photos-slider .slick-next:before {
    content: url("/sites/all/themes/custom/foundation/src/assets/img/next_dark.png") !important; }
  .view-office-photos-slider [dir='rtl'] .slick-next:before {
    content: url("/sites/all/themes/custom/foundation/src/assets/img/next_dark.png") !important; }
  .view-office-photos-slider .slick-prev,
  .view-office-photos-slider .slick-next {
    z-index: 20; }
  .view-office-photos-slider .slick-prev {
    left: 0; }
  .view-office-photos-slider .slick-next {
    right: 0; }
  .view-office-photos-slider .slick-arrow.slick-disabled {
    pointer-events: none; }

.menu-terms {
  padding-bottom: 20px;
  /*
    .drilldown a {
        background: $menu-color-1 none repeat scroll 0 0;
        padding: 0.7rem 1rem;
    }
      
    .drilldown .is-drilldown-submenu {
        background: $menu-color-1 none repeat scroll 0 0;
    }  
    */ }
  .menu-terms a {
    width: 100%;
    background-color: #00acc1;
    color: white; }
  .menu-terms a.active-trail {
    background-color: white !important;
    color: #00acc1 !important; }
  .menu-terms a.active {
    background-color: white !important;
    color: #767f89 !important;
    width: 100.1%; }
  .menu-terms .submenu li {
    width: 100%; }
  .menu-terms .submenu a {
    width: 100%; }

/*
.menu-terms{
    background-color:$menu-color-1;
    padding-top:5px;
    padding-bottom:5px;
    margin-right:10px;
    .menu{
        width:100%;
    }
    
    ul.menu {
        list-style-type: none;
        margin: 0 0 0 0;
        padding: 0px 0px 0px 0px;
        > li{
            margin-right:10px;
            display: block;
            width:100%;
            margin: 0 0 0.25em 0.5em;
            //padding: 0px 0px 0px 0px;
            background-color: transparent;// $menu-color-1;
            border: 1px solid $menu-color-2;
            > a{
                color:$white!important;
            }
            > a.active-trail{
                background-color: white!important;
                color:$menu-color-1!important;
            }
            > a.active{
                background-color: white!important;
                color:#767f89!important;
                width:100.1%;
            }
        }
        li{
            ul.menu{
                border:0px solid transparent!important;
            }
        }
        li.active-trail.expanded{
            ul.menu{
                border:1px solid white!important;
            }
        }
        li:hover{
            background-color: $menu-color-2;
            border: 1px solid $white;
        }
        > li.active-trail{
            //background-color: $white;
            background-color: transparent!important;
            border: 1px solid $menu-color-2;
        }

    }
}
*/
.small-path {
  font-style: normal;
  line-height: 1.3;
  font-family: "Open Sans", sans-serif;
  letter-spacing: 0px;
  text-align: inherit;
  color: #616262;
  font-size: 0.8rem; }
  .small-path a {
    font-style: normal;
    line-height: 1.3;
    font-family: "Open Sans", sans-serif;
    letter-spacing: 0px;
    text-align: inherit;
    color: #00acc1;
    font-size: 0.8rem; }

.custom-cta-block {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #00acc1;
  color: white;
  position: relative; }
  .custom-cta-block .paragraph1 {
    font-size: 30px;
    text-align: center;
    font-style: italic; }
  .custom-cta-block .paragraph1 {
    font-size: 30px;
    text-align: center;
    font-style: italic; }
  .custom-cta-block .custom-button-container {
    text-align: center; }

.custom-cta-block::after {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: -100%;
  right: -100%;
  content: "";
  background: #00acc1;
  z-index: -1; }

.paragraphs-items {
  /*
     h1:after
        {
            content:' ';
            display:block;
            content:' ';
            display:block;
            border:2px solid #00acc1;//#0095e5;
            border-radius:4px;
            width:100px;//10%;
            -webkit-border-radius:4px;
            -moz-border-radius:4px;
            box-shadow:inset 0 1px 1px rgba(0, 0, 0, .05);
            -webkit-box-shadow:inset 0 1px 1px rgba(0, 0, 0, .05);
            -moz-box-shadow:inset 0 1px 1px rgba(0, 0, 0, .05);
    }
        */ }
  .paragraphs-items .styled_paragraph p {
    font-style: normal;
    font-family: "Open Sans";
    letter-spacing: 0px;
    text-align: left;
    color: #616262; }
  .paragraphs-items h1 {
    font-size: 28px;
    color: #00acc1; }
  .paragraphs-items h2 {
    font-size: 14px;
    color: #00acc1; }
  .paragraphs-items h3 {
    font-size: 1.3rem;
    color: #00acc1; }
  .paragraphs-items h4 {
    font-size: 1.2rem;
    color: #00acc1; }
  .paragraphs-items h5 {
    font-size: 1rem;
    color: #00acc1; }
  .paragraphs-items p {
    font-size: 15px;
    line-height: 1.5;
    font-style: normal;
    font-family: "Open Sans", sans-serif;
    letter-spacing: 0px;
    text-align: inherit;
    color: #616262; }

@media screen and (max-width: 39.9375em) {
  .blue-background-cta2 {
    margin-right: unset !important;
    margin-left: unset !important; }
  .row .row {
    margin-right: unset !important;
    margin-left: unset !important; } }

.blue-background-cta2::after {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  content: "";
  z-index: -1;
  background-image: url(/sites/all/themes/custom/foundation/src/assets/img/bg-img2.jpg);
  background-position: center center;
  background-size: cover; }
  @media screen and (min-width: 40em) {
    .blue-background-cta2::after {
      left: -100%;
      right: -100%; } }
  @media screen and (max-width: 39.9375em) {
    .blue-background-cta2::after {
      left: -10px;
      right: -10px; } }

.blue-background-cta2 {
  position: relative;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 55px 0px 55px 0px; }
  .blue-background-cta2 .paragraph1 {
    text-align: center;
    color: white;
    font-size: 21px;
    letter-spacing: 0px;
    line-height: 34px; }
  .blue-background-cta2 .paragraph2 {
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 31px;
    text-align: center;
    font-weight: bold;
    color: white; }
  .blue-background-cta2 .custom-button-container {
    text-align: center; }

.blue-background-cta {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #00acc1;
  color: white;
  position: relative; }
  .blue-background-cta .paragraph1 {
    font-size: 30px;
    text-align: center;
    font-style: italic; }
  .blue-background-cta .paragraph1 {
    font-size: 30px;
    text-align: center;
    font-style: italic; }
  .blue-background-cta .custom-button-container {
    text-align: center; }

.blue-background-cta::after {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: -100%;
  right: -100%;
  content: "";
  background: #00acc1;
  z-index: -1; }

.paragraphs-item-info-container::after {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  content: "";
  background: #00acc1;
  z-index: -1; }
  @media screen and (min-width: 40em) {
    .paragraphs-item-info-container::after {
      left: -100%;
      right: -100%; } }
  @media screen and (max-width: 39.9375em) {
    .paragraphs-item-info-container::after {
      left: -10px;
      right: -10px; } }

.paragraphs-item-info-container {
  margin-bottom: 2rem;
  position: relative;
  /*
    .field-name-field-info-title-container {
            font-size: 2rem;
            text-align: center;
            font-style: italic;
            color: $white;
            p{
                font-size: 2rem;
                text-align: center;
                font-style: italic;
                color: $white;
            }
        h1 {
            font-weight: 700;
            font-size: 2rem;
            color: $white;
        }
    }
    */ }
  .paragraphs-item-info-container .title {
    font-size: 18px;
    color: #fefefe; }
  .paragraphs-item-info-container .subtitle {
    font-size: 14px;
    color: #fefefe;
    padding-top: 1rem;
    padding-bottom: 2.5rem; }
  .paragraphs-item-info-container .paragraph1 {
    font-size: 30px;
    text-align: center;
    font-style: italic; }

.field-name-field-background-color-hex-code {
  display: none; }

.field-name-field-info-point {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap; }
  .field-name-field-info-point .field.field-name-field-item-image {
    width: 134px;
    height: 134px;
    padding-left: 0;
    padding-top: 30px;
    padding-right: 60px; }
    @media screen and (min-width: 40em) {
      .field-name-field-info-point .field.field-name-field-item-image {
        margin-top: 30px;
        padding-bottom: 30px; } }
    @media screen and (max-width: 39.9375em) {
      .field-name-field-info-point .field.field-name-field-item-image {
        margin-top: 0px;
        padding-bottom: 5px; } }
  .field-name-field-info-point p {
    margin-bottom: 0rem; }
  .field-name-field-info-point .subtitle {
    width: 90%;
    line-height: 19px;
    font-size: 12px; }
  .field-name-field-info-point .title p {
    font-size: 12px;
    text-align: left;
    color: white;
    letter-spacing: 0px; }
  .field-name-field-info-point .subtitle p {
    text-align: left;
    color: white;
    font-size: 14px;
    letter-spacing: 0px; }
  .field-name-field-info-point .title p {
    font-weight: bold; }

.component1_two_columns_leftimagetitlesubtitlepa .styled_paragraph-title h1 {
  font-weight: normal !important;
  font-size: 16px !important;
  color: #00acc1; }
  @media screen and (min-width: 40em) {
    .component1_two_columns_leftimagetitlesubtitlepa .styled_paragraph-title h1 {
      font-size: 28px !important; } }

.component1_two_columns_leftimagetitlesubtitlepa .paragraphs-items h1 {
  font-weight: normal !important;
  font-size: 28px !important;
  color: #00acc1; }

.component1_two_columns_leftimagetitlesubtitlepa .styled_paragraph-new p {
  font-size: 14px !important; }

@media screen and (min-width: 40em) {
  .component1_two_columns_leftimagetitlesubtitlepa .row {
    display: table !important; }
  .component1_two_columns_leftimagetitlesubtitlepa .custom-column-first {
    width: 50% !important; }
  .component1_two_columns_leftimagetitlesubtitlepa .custom-column {
    display: table-cell !important;
    vertical-align: middle !important; }
    .component1_two_columns_leftimagetitlesubtitlepa .custom-column img {
      padding-right: 10% !important;
      padding-left: 30px !important; } }

.component2_images-and-text-in-2-columns {
  margin-top: 20px;
  margin-bottom: 20px; }
  .component2_images-and-text-in-2-columns .comp-row {
    margin-bottom: 5px;
    font-weight: 400;
    line-height: 1.2; }

.component3_images_with_text_on_top_grid {
  margin: 0 auto;
  margin-left: 0%;
  margin-right: 0%;
  max-width: none;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap; }
  .component3_images_with_text_on_top_grid .styled_paragraph {
    margin-top: 40px;
    margin-bottom: 30px;
    font-family: 'Open Sans', sans-serif;
    font-size: 2rem;
    text-align: center;
    color: #284349;
    padding-top: 20px;
    padding-bottom: 20px; }
  .component3_images_with_text_on_top_grid .comp-content .views-field {
    height: 100%;
    width: 100%; }
  .component3_images_with_text_on_top_grid .comp-content a:hover .service-image {
    -webkit-filter: brightness(70%);
    filter: brightness(70%); }
  .component3_images_with_text_on_top_grid .comp-content a {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    color: white;
    display: block;
    text-align: center;
    height: 100%;
    width: 100%; }
  .component3_images_with_text_on_top_grid .comp-content .service-image {
    z-index: 2;
    -webkit-filter: brightness(70%);
    filter: brightness(70%);
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-clip: border-box;
    background-size: 100% 100%;
    background-size: cover; }
  .component3_images_with_text_on_top_grid .comp-content .service-mainphoto {
    width: 100%;
    height: 100%;
    max-height: 300px;
    overflow: hidden; }
  .component3_images_with_text_on_top_grid .comp-content .service-title {
    height: 100%;
    width: 100%;
    position: relative;
    vertical-align: middle;
    display: block;
    float: left;
    top: 40% !important;
    z-index: 4; }
  .component3_images_with_text_on_top_grid .comp-content .service-mainphoto:hover .service-title {
    transform: scale(1.1);
    transition: all .2s ease-in-out;
    opacity: 1;
    filter: alpha(opacity=1); }
  .component3_images_with_text_on_top_grid .comp-content .service-background {
    z-index: 3; }
  .component3_images_with_text_on_top_grid .comp-content .service-image {
    z-index: 2;
    -webkit-filter: brightness(70%);
    filter: brightness(70%); }
  .component3_images_with_text_on_top_grid .paragraphs-items h1 {
    font-weight: 700;
    font-size: 2rem;
    color: #fefefe; }
  .component3_images_with_text_on_top_grid .comp-content {
    display: grid;
    width: 100%;
    max-width: 1400px;
    margin: auto;
    justify-items: stretch;
    align-items: stretch;
    grid-gap: 10px 10px; }
    @media screen and (min-width: 40em) {
      .component3_images_with_text_on_top_grid .comp-content {
        grid-template-columns: repeat(2, 1fr); } }
    @media screen and (max-width: 39.9375em) {
      .component3_images_with_text_on_top_grid .comp-content {
        grid-template-columns: repeat(1, 1fr); } }
    @media screen and (min-width: 40em) {
      .component3_images_with_text_on_top_grid .comp-content .featuredItemRow:nth-child(odd) {
        grid-auto-rows: auto;
        grid-column: 1; }
      .component3_images_with_text_on_top_grid .comp-content .featuredItemRow:nth-child(even) {
        grid-auto-rows: auto;
        grid-column: 2; } }
    @media screen and (min-width: 40em) {
      .component3_images_with_text_on_top_grid .comp-content .featuredItemRow a {
        display: grid;
        padding-left: 2px;
        padding-right: 2px;
        font-size: 21px;
        letter-spacing: 0px;
        line-height: 34px;
        color: white;
        font-family: "Open Sans";
        font-weight: bold;
        text-align: center; } }
    @media screen and (max-width: 39.9375em) {
      .component3_images_with_text_on_top_grid .comp-content .featuredItemRow a {
        font-size: 14px; } }

body {
  overflow-x: hidden; }

.component4_full_width_image_carousel {
  /*
    hack from 
     https://css-tricks.com/full-width-containers-limited-width-parents/ 
     NOTE: REQUIRES  : body {overflow-x: hidden;}
     */ }
  .component4_full_width_image_carousel .full-width {
    width: 100vw;
    position: relative;
    overflow-x: hidden;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw; }
  .component4_full_width_image_carousel .home-slider__slide {
    position: relative;
    width: 100%;
    /*       
       width: 100%;
        overflow: hidden;
       display: block;
       padding: 10px;
       @include horizontal-center;
       */ }
  .component4_full_width_image_carousel .home-slide__content .first, .component4_full_width_image_carousel .home-slide__content .second {
    font-size: 40px;
    color: #fefefe;
    font-weight: bold; }
  .component4_full_width_image_carousel .home-slide__content .second span {
    display: block; }
  .component4_full_width_image_carousel .c-row {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; }
  .component4_full_width_image_carousel .c-table {
    display: table;
    height: 100%;
    width: 100%;
    max-width: 75rem;
    margin: auto; }
  .component4_full_width_image_carousel .c-cell {
    display: table-cell;
    vertical-align: middle; }
  .component4_full_width_image_carousel .c-cell.customalign-right {
    text-align: right; }
  .component4_full_width_image_carousel .c-cell.customalign-left {
    text-align: left; }
  .component4_full_width_image_carousel .home-slider__image {
    height: 600px;
    background-size: cover;
    background-position: center center; }
  .component4_full_width_image_carousel .home-slide__content {
    width: 100%;
    display: inline-block;
    overflow: hidden;
    padding: 10px; }

@keyframes showup {
  0% {
    opacity: 0; }
  20% {
    opacity: 1; }
  80% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes slidein {
  0% {
    margin-left: -800px; }
  20% {
    margin-left: -800px; }
  35% {
    margin-left: 0px; }
  100% {
    margin-left: 0px; } }

@keyframes reveal {
  0% {
    opacity: 0;
    width: 0px; }
  20% {
    opacity: 1;
    width: 0px; }
  30% {
    width: 355px; }
  80% {
    opacity: 1; }
  100% {
    opacity: 0;
    width: 355px; } }
  .component4_full_width_image_carousel .js-component4 .slick-prev:before {
    content: url("/sites/all/themes/custom/foundation/src/assets/img/prev_dark.png") !important;
    margin-left: 55px !important; }
  .component4_full_width_image_carousel .js-component4 > [dir='rtl'] .slick-prev:before {
    content: url("/sites/all/themes/custom/foundation/src/assets/img/prev_dark.png") !important;
    margin-left: 55px !important; }
  .component4_full_width_image_carousel .js-component4 .slick-next:before {
    content: url("/sites/all/themes/custom/foundation/src/assets/img/next_dark.png") !important;
    margin-left: -55px !important; }
  .component4_full_width_image_carousel .js-component4 [dir='rtl'] .slick-next:before {
    content: url("/sites/all/themes/custom/foundation/src/assets/img/next_dark.png") !important;
    margin-left: -55px !important; }

.component5_simpleParagraphs {
  /*
    .simple-paragraph{    
        font-style: normal;
        line-height: 1.3;
        font-family: "Open Sans", sans-serif;
        letter-spacing: 0px;
        text-align: inherit;
        color: #616262;
        font-weight: 400;
        font-size:20px;
        p{
            line-height: 1.3;
            font-family: "Open Sans", sans-serif;
            letter-spacing: 0px;
            font-weight: 400;
            text-align: inherit;
            color: #616262;
            font-size:20px;
        }

    }
*/ }

body {
  overflow-x: hidden; }

.component9_full_width_image_carousel {
  /*
    hack from 
     https://css-tricks.com/full-width-containers-limited-width-parents/ 
     NOTE: REQUIRES  : body {overflow-x: hidden;}
     */
  min-height: 50vh; }
  @media print, screen and (min-width: 40em) {
    .component9_full_width_image_carousel {
      min-height: 600px; } }
  .component9_full_width_image_carousel .full-width {
    width: 100vw;
    position: relative;
    overflow-x: hidden;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw; }
  .component9_full_width_image_carousel .custom-highlighted {
    background-color: rgba(255, 255, 255, 0.7);
    position: absolute;
    height: 100%;
    width: 100%;
    bottom: 0px; }
    @media print, screen and (min-width: 40em) {
      .component9_full_width_image_carousel .custom-highlighted {
        width: 50%; } }
  .component9_full_width_image_carousel .custom-position {
    position: absolute;
    height: 100%;
    width: 100%;
    bottom: 0px; }
  .component9_full_width_image_carousel .customalign-tmp-left {
    height: 100%; }
  .component9_full_width_image_carousel .customalign-tmp-right {
    height: 100%; }
  .component9_full_width_image_carousel .home-slide__content1 {
    transform: translateY(-50%);
    color: white;
    z-index: 1;
    z-index: 3;
    top: 50%;
    position: relative;
    color: white;
    display: inline-block;
    right: 64%;
    left: 0;
    width: 100%;
    text-align: left;
    padding-left: 10%; }
    .component9_full_width_image_carousel .home-slide__content1 a {
      z-index: 2;
      color: white; }
    @media print, screen and (min-width: 40em) {
      .component9_full_width_image_carousel .home-slide__content1 {
        padding-left: 10%; } }
  .component9_full_width_image_carousel .home-slider__slide {
    position: relative;
    width: 100%;
    /*       
       width: 100%;
        overflow: hidden;
       display: block;
       padding: 10px;
       @include horizontal-center;
       */ }
  .component9_full_width_image_carousel .columnText .first,
  .component9_full_width_image_carousel .columnText .second {
    font-size: 1.2rem;
    line-height: 1rem;
    color: #00acc1;
    font-weight: bold; }
    @media print, screen and (min-width: 40em) {
      .component9_full_width_image_carousel .columnText .first,
      .component9_full_width_image_carousel .columnText .second {
        font-size: 28px;
        line-height: 42px; } }
  .component9_full_width_image_carousel .columnText .second span {
    display: block; }
  .component9_full_width_image_carousel .columnImage {
    min-height: 50vh;
    /*
        background-size: cover;
        background-position: center center;
        */ }
    @media print, screen and (min-width: 40em) {
      .component9_full_width_image_carousel .columnImage {
        min-height: 600px; } }
  .component9_full_width_image_carousel .columnText {
    color: white;
    height: 100%;
    width: 100%;
    position: relative;
    padding-top: 0px;
    padding-bottom: 20px; }
  .component9_full_width_image_carousel .js-component9 .slick-prev:before {
    content: url("/sites/all/themes/custom/foundation/src/assets/img/prev_dark.png") !important;
    margin-left: 5px !important; }
    @media print, screen and (min-width: 40em) {
      .component9_full_width_image_carousel .js-component9 .slick-prev:before {
        margin-left: 55px !important; } }
  .component9_full_width_image_carousel .js-component9 > [dir='rtl'] .slick-prev:before {
    content: url("/sites/all/themes/custom/foundation/src/assets/img/prev_dark.png") !important;
    margin-left: 5px !important; }
    @media print, screen and (min-width: 40em) {
      .component9_full_width_image_carousel .js-component9 > [dir='rtl'] .slick-prev:before {
        margin-left: 55px !important; } }
  .component9_full_width_image_carousel .js-component9 .slick-next:before {
    content: url("/sites/all/themes/custom/foundation/src/assets/img/next_dark.png") !important;
    margin-left: -5px !important; }
    @media print, screen and (min-width: 40em) {
      .component9_full_width_image_carousel .js-component9 .slick-next:before {
        margin-left: -55px !important; } }
  .component9_full_width_image_carousel .js-component9 [dir='rtl'] .slick-next:before {
    content: url("/sites/all/themes/custom/foundation/src/assets/img/next_dark.png") !important;
    margin-left: -5px !important; }
    @media print, screen and (min-width: 40em) {
      .component9_full_width_image_carousel .js-component9 [dir='rtl'] .slick-next:before {
        margin-left: -55px !important; } }

.component10_sidebside .custom_row {
  margin-bottom: 20px; }

.component10_sidebside .div-text {
  margin: 0 auto;
  width: 80%; }
  .component10_sidebside .div-text h1 {
    font-size: 24px;
    letter-spacing: 0px;
    line-height: 34px;
    color: #00acc1;
    font-family: "Open Sans"; }
  .component10_sidebside .div-text p {
    font-size: 15px;
    letter-spacing: 0px;
    line-height: 27px;
    color: #4f4f4f;
    font-family: "Open Sans"; }

@media screen and (max-width: 39.9375em) {
  .component10_sidebside .custom-column-text {
    width: 100% !important; }
  .component10_sidebside .custom-column-image {
    width: 100% !important; } }

@media screen and (min-width: 40em) {
  .component10_sidebside .row {
    display: table !important; }
  .component10_sidebside .custom-column {
    display: table-cell !important;
    vertical-align: middle !important; }
  .component10_sidebside .custom-column-text {
    width: 50% !important; }
  .component10_sidebside .custom-column-image {
    width: 50% !important; } }

.view-blog-posts {
  font-family: 'Open Sans', sans-serif; }
  .view-blog-posts .view-content {
    max-width: 75rem;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-flow: row wrap; }
    .view-blog-posts .view-content .blogitem {
      flex: 0 0 100%;
      max-width: 100%;
      padding-right: 0.625rem;
      padding-left: 0.625rem; }
      @media print, screen and (min-width: 40em) {
        .view-blog-posts .view-content .blogitem {
          padding-right: 0.9375rem;
          padding-left: 0.9375rem; } }
      .view-blog-posts .view-content .blogitem .blogitem-photo {
        flex: 0 0 100%;
        max-width: 100%;
        padding-right: 0.625rem;
        padding-left: 0.625rem; }
        @media print, screen and (min-width: 40em) {
          .view-blog-posts .view-content .blogitem .blogitem-photo {
            padding-right: 0.9375rem;
            padding-left: 0.9375rem; } }
      .view-blog-posts .view-content .blogitem .blogitem-details {
        max-width: 75rem;
        margin-right: auto;
        margin-left: auto;
        display: flex;
        flex-flow: row wrap; }
      .view-blog-posts .view-content .blogitem .blogitem-date {
        flex: 0 0 100%;
        max-width: 100%;
        padding-right: 0.625rem;
        padding-left: 0.625rem; }
        @media print, screen and (min-width: 40em) {
          .view-blog-posts .view-content .blogitem .blogitem-date {
            padding-right: 0.9375rem;
            padding-left: 0.9375rem; } }
      .view-blog-posts .view-content .blogitem .blogitem-title {
        flex: 0 0 100%;
        max-width: 100%;
        padding-right: 0.625rem;
        padding-left: 0.625rem; }
        @media print, screen and (min-width: 40em) {
          .view-blog-posts .view-content .blogitem .blogitem-title {
            padding-right: 0.9375rem;
            padding-left: 0.9375rem; } }
      .view-blog-posts .view-content .blogitem .blogitem-buttons {
        max-width: 75rem;
        margin-right: auto;
        margin-left: auto;
        display: flex;
        flex-flow: row wrap; }
        .view-blog-posts .view-content .blogitem .blogitem-buttons .custom-readmore {
          flex: 0 0 41.66667%;
          max-width: 41.66667%;
          padding-right: 0.625rem;
          padding-left: 0.625rem; }
        .view-blog-posts .view-content .blogitem .blogitem-buttons .custom-view-all-news {
          flex: 0 0 41.66667%;
          max-width: 41.66667%;
          padding-right: 0.625rem;
          padding-left: 0.625rem; }
    .view-blog-posts .view-content .blogitem-photo img {
      display: block;
      width: 100%;
      object-fit: cover;
      min-height: 300px; }
    .view-blog-posts .view-content .blogitem-details {
      display: flex;
      flex-direction: column;
      /* top to bottom */
      justify-content: space-between;
      /* first item at start, last at end */
      height: 100%;
      min-height: 300px; }
    .view-blog-posts .view-content .blogitem-date {
      padding-top: 5px;
      color: #616262;
      font-size: 0.875rem;
      font-weight: 400; }
      .view-blog-posts .view-content .blogitem-date i {
        color: #00acc1; }
    .view-blog-posts .view-content .blogitem-title {
      font-style: normal;
      font-weight: 700;
      line-height: 1.5;
      font-family: "Open Sans", sans-serif;
      letter-spacing: 0px;
      text-align: inherit;
      color: #00acc1;
      font-size: 1.6rem;
      padding-right: 0;
      padding-left: 0; }
      .view-blog-posts .view-content .blogitem-title a {
        color: #00acc1; }
      @media screen and (max-width: 39.9375em) {
        .view-blog-posts .view-content .blogitem-title {
          margin-top: 20px; } }
    .view-blog-posts .view-content .blogitem-summary {
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 1.2;
      font-family: "Open Sans", sans-serif;
      letter-spacing: 0px;
      text-align: left;
      color: #616262; }
    .view-blog-posts .view-content .button {
      margin-top: 20px;
      margin-right: 20px;
      font-size: 1rem; }
    .view-blog-posts .view-content .custom-readmore {
      font-weight: bolder;
      border: 2px solid #70295F !important;
      color: #70295F; }
    .view-blog-posts .view-content .custom-view-all-news {
      font-weight: bolder;
      border: 2px solid #354F54 !important;
      color: #354F54; }
    .view-blog-posts .view-content .blogitem-buttons {
      margin-bottom: 0px;
      align-items: flex-end;
      padding-top: 1rem;
      vertical-align: bottom;
      width: 100%; }

.telephone-big {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  letter-spacing: normal;
  text-align: left;
  color: #00acc1;
  font-size: 18px;
  line-height: 24px;
  font-family: "Open Sans"; }

.news-item-container {
  font-family: "Open Sans", sans-serif; }
  .news-item-container .news-item-title h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 23px;
    line-height: 1.3;
    font-family: "Open Sans", sans-serif;
    letter-spacing: 0px;
    text-align: inherit;
    color: #00acc1; }
  .news-item-container .news-item-date {
    padding-top: 5px;
    color: #616262;
    font-size: 0.875rem;
    font-weight: 400; }
    .news-item-container .news-item-date i {
      color: #00acc1; }
  .news-item-container .news-item-body p {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.2;
    font-family: "Open Sans", sans-serif;
    letter-spacing: 0px;
    text-align: left;
    color: #616262; }

.treatment-page-styling {
  font-style: normal;
  line-height: 1.3;
  font-family: "Open Sans", sans-serif;
  letter-spacing: 0px;
  text-align: inherit;
  color: #616262; }
  .treatment-page-styling .elementor-divider-separator {
    border-top-style: solid;
    border-top-width: 2px;
    width: 65px; }
  .treatment-page-styling .elementor {
    hyphens: manual; }
  .treatment-page-styling .elementor-widget-divider .elementor-divider-separator {
    display: inline-block; }
  .treatment-page-styling .elementor-divider-separator {
    color: #00acc1; }
  .treatment-page-styling .elementor-widget-divider .elementor-divider {
    line-height: 0;
    font-size: 0; }
  .treatment-page-styling h1 {
    font-size: 2rem;
    color: #00acc1; }
  .treatment-page-styling h2 {
    font-size: 1.5rem;
    color: #00acc1; }
  .treatment-page-styling h3 {
    font-size: 14px;
    color: #00acc1; }
  .treatment-page-styling h4 {
    font-size: 1.3rem;
    color: #00acc1; }
  .treatment-page-styling h5 {
    font-size: 1.2px;
    color: #00acc1; }
  .treatment-page-styling .paragraphs-items p, .treatment-page-styling p {
    font-size: 14px;
    font-style: normal;
    line-height: 1.3;
    font-family: "Open Sans", sans-serif;
    letter-spacing: 0px;
    text-align: inherit;
    color: #616262; }
  .treatment-page-styling .treatments_main_view_content ol, .treatment-page-styling .treatments_main_view_content ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    padding-bottom: 1rem; }
  .treatment-page-styling .treatments_main_view_content ol > li,
  .treatment-page-styling .treatments_main_view_content ul > li {
    font-weight: normal; }
  .treatment-page-styling .treatments_main_view_content ol > li:before,
  .treatment-page-styling .treatments_main_view_content ul > li:before {
    color: #00acc1;
    content: '\f00c';
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 2px;
    margin-right: 10px; }
  .treatment-page-styling .field-name-name-field {
    font-weight: 700;
    font-size: 2rem;
    color: #00acc1; }
  .treatment-page-styling .field-name-field-info-title-container {
    margin-bottom: 40px;
    margin-top: 40px; }
  .treatment-page-styling .field-name-name-field:after {
    /*
        content: ' ';
        display: block;
        border:1px solid #0095e5;
        //border-radius: 4px;
        width: 100px;
*/
    margin-bottom: 20px;
    margin-top: 20px;
    bottom: 4px;
    display: block;
    height: 4px;
    content: "";
    position: relative;
    /*
    background: none repeat scroll 0 0 #00acc1;//#0095e5;
    content: "";
    width: 100px;
    */ }
  .treatment-page-styling .paragraphs-item-info-container::after {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: -100%;
    right: -100%;
    content: "";
    background-color: transparent;
    background: none;
    z-index: -1; }

.button {
  padding: 14px 30px 13px !important;
  color: white;
  background: #00acc1;
  display: inline-block;
  width: auto;
  min-width: 200px;
  border-color: white;
  font-size: 14px;
  letter-spacing: 0px;
  margin-bottom: 0;
  border-radius: 3px;
  font-weight: bold; }
  .button--secondary {
    background: white;
    color: #00acc1;
    border-color: #00acc1; }

#logoimg {
  -vendor-animation-duration: 5s;
  -vendor-animation-delay: 5s;
  -vendor-animation-iteration-count: infinite; }

.slide-and-fade-and-spin {
  animation-name: custom-1; }

@keyframes custom-1 {
  0% {
    transform: translateY(100%) rotate(0);
    opacity: 0; }
  100% {
    transform: translateY(0) rotate(1turn);
    opacity: 1; } }

.serial-animation .thing-one {
  animation-name: fade-0-to-100;
  animation-duration: 1s;
  animation-delay: 0s; }

@keyframes fade-0-to-100 {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.serial-animation .thing-two {
  animation-name: slide-in-right-100;
  animation-duration: 1s;
  animation-delay: 1s; }

@keyframes slide-in-right-100 {
  0% {
    transform: translateX(-100%); }
  100% {
    transform: translateX(0); } }

.serial-animation .thing-three {
  animation-name: custom-3;
  animation-duration: 2s;
  animation-delay: 4s; }

@keyframes custom-3 {
  0% {
    transform: rotate(1turn) translateY(100%); }
  100% {
    transform: rotate(0) translateY(0); } }

.custom-slow-animation {
  animation-duration: 750ms !important; }

.animation-logoimg {
  animation-name: fade-0-to-100;
  animation-duration: 2s; }

@keyframes fade-0-to-100 {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

#btnShowMenu {
  animation-name: fade-0-to-100;
  animation-duration: 2s; }

@keyframes fade-0-to-100 {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.custom_series .shake {
  animation-name: shake-7;
  animation-duration: 2s;
  animation-delay: 2s; }

@keyframes shake-7 {
  0%, 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% {
    transform: translateX(7%); }
  5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% {
    transform: translateX(-7%); } }

.custom_series .spin {
  animation-name: spin-cw-1turn;
  animation-duration: 1s;
  animation-delay: 4s; }

@keyframes spin-cw-1turn {
  0% {
    transform: rotate(-1turn); }
  100% {
    transform: rotate(0); } }

.custom_series .wiggle {
  animation-name: custom-4;
  animation-duration: wiggle;
  animation-delay: 7s; }

@keyframes custom-4 {}.fade-in {
  animation-name: fade-0-to-100;
  animation-duration: 2s; }

@keyframes fade-0-to-100 {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.fade-out {
  animation-name: fade-100-to-0;
  animation-duration: 2s; }

@keyframes fade-100-to-0 {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.menu_slide-out-to-left {
  animation-name: slide-out-left-100;
  animation-duration: 1s; }

@keyframes slide-out-left-100 {
  0% {
    transform: translateX(0); }
  100% {
    transform: translateX(-100%); } }

.menu_slide-in-from-left {
  animation-name: slide-in-right-100;
  animation-duration: 1s; }

@keyframes slide-in-right-100 {
  0% {
    transform: translateX(-100%); }
  100% {
    transform: translateX(0); } }

.search-icon-animation {
  animation-name: slide-in-left-100;
  animation-duration: 1s; }

@keyframes slide-in-left-100 {
  0% {
    transform: translateX(100%); }
  100% {
    transform: translateX(0); } }

.on-scroll-animate-in {
  animation-name: scale-1-to-in;
  animation-duration: 3s; }

@keyframes scale-1-to-in {
  0% {
    transform: scale(in); }
  100% {
    transform: scale(1); } }

/*
.animation-custom1 {
  @include mui-animation(fade(in));
  animation-duration: 2s;
}
*/
.grey {
  background: #BDBEC0; }

.light-blue {
  background: #44B4DD; }

.dark-blue {
  background: #225681; }

/*
.tb-megamenu.style-blue {
  background:transparent;
  padding: 10px 0;
}

.top-bar {
  background-color: #fff;
  padding: 0!important;
  font-family: 'Open Sans sans-serif';
  margin-bottom: 20px;
}

.tb-megamenu .nav > li > a {
  font-size: 22px;
  font-weight: 100;
  font-family: "Open Sans", sans-serif;
}
*/
.tb-megamenu .nav > li > a:focus,
.tb-megamenu .nav > li > a:hover {
  font-size: 15px;
  background-color: #fff;
  color: #e54e4b;
  text-decoration: none;
  text-shadow: none;
  outline: none; }

.tb-megamenu .nav > li > a {
  font-size: 15px;
  font-weight: bold;
  font-family: "Open Sans", sans-serif; }

body.front .custom-column img {
  padding-top: 10px; }
  @media screen and (min-width: 40em) {
    body.front .custom-column img {
      padding-top: 30px; } }

.tb-megamenu .nav > li > a {
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 31px;
  font-weight: 100;
  font-family: "Open Sans", sans-serif;
  color: #535353; }

.mega-nav li.mega a {
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 31px;
  font-weight: 100;
  font-family: "Open Sans", sans-serif;
  color: #535353;
  text-align: left; }

.tb-megamenu .mega-nav > li > a,
.tb-megamenu .dropdown-menu .mega-nav > li > a {
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 31px;
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
  color: #535353;
  text-align: left;
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.7rem;
  color: #333;
  white-space: nowrap; }

.tb-megamenu .dropdown-menu li > a {
  border-top: 0px solid #eee;
  border-top-width: 0px;
  border-top-style: solid;
  border-top-color: #eeeeee;
  text-shadow: none; }

.tb-megamenu .dropdown-menu li > a:hover,
.tb-megamenu .dropdown-menu li > a:focus,
.tb-megamenu .dropdown-submenu:hover > a {
  text-decoration: none;
  color: #333 !important;
  background-color: transparent !important;
  background-image: none !important; }

.tb-megamenu.style-blue .dropdown-menu li > a:hover, .tb-megamenu.style-blue .dropdown-menu li > a:focus, .tb-megamenu.style-blue .dropdown-submenu:hover > a {
  color: #00acc1 !important;
  background-color: transparent !important;
  background-image: none !important; }

.tb-megamenu .dropdown-menu .active > a, .tb-megamenu .dropdown-menu .active {
  color: #00acc1 !important;
  background-color: transparent !important;
  background-image: none !important; }

.top-panel {
  padding-bottom: 5px;
  background-color: #00acc1; }

.top-panel a {
  color: #fff !important; }

.tb-megamenu.style-blue .nav li.dropdown.active > .dropdown-toggle,
.tb-megamenu.style-blue .nav li.dropdown.open.active > .dropdown-toggle {
  background-color: transparent;
  color: #234561; }

.button--secondary {
  border-radius: 100px; }

.tb-megamenu .nav > li > a:hover {
  text-decoration: none;
  background-color: transparent; }

.tb-megamenu.style-blue .nav > .active > a {
  color: #00acc1;
  background: transparent;
  font-weight: bold; }

.top-bar .button.primary {
  border: none;
  border-radius: 120px;
  background-color: #00acc1;
  color: #fff;
  margin-top: 25px;
  line-height: 31px;
  font-family: "Open Sans";
  font-weight: bold;
  text-align: center;
  font-size: 14px;
  padding: 5px 5px 5px !important; }

.top-bar {
  padding: 10px 0 5px; }

/*
.navbar-brand {
  background:  url(/sites/default/files/logofides.png) no-repeat 0 0;
  background-size:  contain;
  padding: 37px!important;
  display:  block;
}
img#logoimg {
  display:  none;
}
*/
.logoimg1 {
  margin-top: 10px; }

main.l-main.lmain-page {
  margin-top: 5px; }

.webform-client-form-98 {
  width: 97%;
  margin: 0 auto;
  padding: 5px 10px;
  border: 2px solid #418794;
  box-shadow: 1px 1px 1px #888; }

.webform-client-form-98 .webform-component-fieldset {
  border: 1px solid #418794;
  padding: 10px;
  float: left;
  width: 47%;
  margin: 10px; }

.front .component3_images_with_text_on_top_grid .styled_paragraph {
  text-align: center !important;
  font-size: 28px !important;
  letter-spacing: 0px;
  line-height: 34px;
  color: #00acc1 !important; }

.front .component3_images_with_text_on_top_grid > .row {
  width: 100%; }

.return-link {
  text-transform: uppercase;
  font-family: 'Open Sans', sans-serif;
  line-height: normal;
  font-size: 14px;
  text-align: left;
  color: #BDBDBD; }

.paragraphs-items {
  /*
  @include breakpoint(small) {
    width:90%;
    padding-left:10%;
  }
  */ }
  @media screen and (min-width: 40em) {
    .paragraphs-items {
      width: 100%; } }

a {
  padding: 0rem 0rem; }

.button-group {
  list-style-type: none; }

h1 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  color: #57A099;
  padding-top: 1rem;
  padding-bottom: 1rem; }

h2 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.8rem;
  color: #57A099;
  padding-bottom: 1rem; }

h3 {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  color: #57A099;
  padding-top: 0.5rem;
  padding-bottom: 0.7rem; }

p {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #828282; }

.all-cases-link {
  font-family: 'Open Sans', sans-serif;
  line-height: normal;
  font-size: 14px;
  text-transform: uppercase;
  color: #BDBDBD; }

.user-options-menu ul {
  margin-eight: 1.25rem;
  list-style-type: none; }

.user-options-menu li {
  display: inline-block;
  padding-right: 10px; }
  .user-options-menu li a {
    padding: 0rem 0rem;
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 1rem 0;
    font-family: inherit;
    padding: 0.85em 1em;
    -webkit-appearance: none;
    border: 1px solid transparent;
    border-radius: 0;
    transition: background-color 0.25s ease-out, color 0.25s ease-out;
    font-size: 0.9rem;
    line-height: 1;
    text-align: center;
    cursor: pointer;
    background-color: #0a0a0a;
    color: #fefefe;
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0; }
    [data-whatinput='mouse'] .user-options-menu li a {
      outline: 0; }
    .user-options-menu li a:hover, .user-options-menu li a:focus {
      background-color: #0a0a0a;
      color: #fefefe; }

#btnHideMenu {
  padding: 0rem 0em;
  background-color: transparent !important; }

.main-column-primary {
  position: relative; }
  @media screen and (min-width: 0px) and (max-width: 1230px) {
    .main-column-primary .block {
      margin-left: 0rem; } }
  @media screen and (min-width: 1230px) {
    .main-column-primary .block {
      margin-left: 5rem; } }

.moto {
  font-family: 'Open Sans', sans-serif;
  font-style: italic;
  font-weight: bold;
  font-size: 2rem;
  color: #57A099;
  padding-left: 10%;
  padding-top: 2rem;
  padding-bottom: 2rem; }

.moto-1 {
  width: 50%;
  font-family: 'Open Sans', sans-serif;
  font-style: italic;
  font-weight: bold;
  font-size: 2rem;
  color: #57A099;
  padding-left: 10%;
  padding-top: 2rem;
  padding-bottom: 2rem; }

.moto-2 {
  font-family: 'Open Sans', sans-serif;
  font-style: italic;
  font-weight: bold;
  font-size: 2rem;
  color: #57A099;
  width: 80%;
  padding-left: 10%;
  padding-top: 2rem;
  padding-bottom: 2rem; }

.moto-3 {
  font-family: 'Open Sans', sans-serif;
  font-style: italic;
  font-weight: bold;
  font-size: 2rem;
  color: #57A099;
  width: 70%;
  padding-top: 2rem;
  padding-bottom: 2rem; }

.company-page {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
  padding-right: 0.625rem;
  padding-left: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .company-page {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }

h1.hrLine {
  padding-top: 3rem;
  position: relative; }

h1.hrLine:before {
  content: "";
  position: absolute;
  margin-top: -2rem;
  height: 4px;
  background-color: #CBF1FD;
  width: 70%;
  left: 40%;
  overflow: hidden; }

article .customUL {
  font-style: normal;
  font-weight: bold;
  line-height: 133%;
  list-style-type: none;
  font-size: 1.3rem;
  text-indent: 0rem;
  padding-top: 0.5rem;
  padding-bottom: 0.7rem; }
  article .customUL li:before {
    content: "- ";
    text-indent: -1.8rem; }

img {
  display: block;
  margin: 0 auto; }

.block-title {
  margin-top: 40px;
  margin-bottom: 30px;
  font-family: 'Open Sans', sans-serif;
  font-size: 2rem;
  text-align: center;
  color: #00acc1; }

h1.block-title {
  color: #00acc1;
  font-size: 28px;
  letter-spacing: 0px;
  line-height: 34px;
  font-family: "Open Sans"; }

.view-header p {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  text-align: center;
  color: #284349;
  margin-top: 10px;
  margin-bottom: 30px; }

.l-main-front {
  background-color: #fefefe; }

body {
  max-width: 2000px;
  margin: 0 auto;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  font-family: "Open Sans", sans-serif;
  letter-spacing: 0px;
  text-align: left;
  color: #616262; }

.color-lightgreenblue {
  color: #418794; }

.text-big {
  font-size: 2rem; }

.text-normal {
  font-size: 1.3rem; }

@media screen and (min-width: 0px) and (max-width: 1230px) {
  .show-for-small-only-custom {
    display: block !important; }
  /* show it on small screens */
  .hide-for-small-only-custom {
    display: none !important; }
  /* hide it  */ }

@media screen and (min-width: 1230px) {
  .show-for-small-only-custom {
    display: none !important; }
  /* hide it elsewhere */
  .hide-for-small-only-custom {
    display: block !important; }
  /* show it elsewhere */ }

.gutter-top {
  margin-top: 20px; }

.view-list-of-treatment-articles .view-content {
  width: 95%; }

.contact_details {
  width: 90%;
  margin: 0 auto; }
  @media screen and (min-width: 40em) {
    .contact_details {
      width: 100%; } }
  .contact_details h1 {
    color: #20afb3;
    font-size: 26px !important;
    line-height: 34px; }
  .contact_details a {
    color: #858585 !important; }

@media screen and (min-width: 40em) {
  .block-block-2 {
    padding-top: 42px; } }

.block-block-13 {
  background-color: black;
  padding-top: 80px;
  padding-bottom: 70px; }
  .block-block-13 p {
    text-align: center;
    font-size: 29px;
    line-height: 37px;
    color: white;
    font-weight: bold;
    text-align: center; }

.node-appointment-webform {
  box-shadow: 0px 0px 0px transparent;
  width: 100%;
  background-color: #fafafa; }
  @media screen and (min-width: 40em) {
    .node-appointment-webform {
      width: 80%; } }
  .node-appointment-webform .webform-component--intro2 {
    font-size: 14px;
    line-height: 37px;
    color: #888b8d;
    font-family: "Roboto"; }
  .node-appointment-webform .fieldset-description {
    color: #00acc1;
    letter-spacing: 0px;
    line-height: 37px;
    font-family: "Roboto";
    font-size: 14px; }
    @media screen and (min-width: 40em) {
      .node-appointment-webform .fieldset-description {
        font-size: 26px; } }
    .node-appointment-webform .fieldset-description h2 {
      color: #20afb3; }
    .node-appointment-webform .fieldset-description .cal_link a {
      color: #858585 !important; }
  .node-appointment-webform ::-moz-placeholder {
    opacity: 1;
    color: #858585;
    font-size: 19px;
    font-weight: normal; }
  .node-appointment-webform ::-webkit-input-placeholder {
    opacity: 1;
    color: #858585;
    font-size: 19px;
    font-weight: normal; }
  .node-appointment-webform .webform-submit {
    width: 100%; }
    @media screen and (min-width: 40em) {
      .node-appointment-webform .webform-submit {
        width: 50%; } }
  @media screen and (min-width: 40em) {
    .node-appointment-webform .webform-component--e-mail {
      display: inline-block;
      width: 49%; }
    .node-appointment-webform .webform-component--telephone {
      display: inline-block;
      width: 49%; } }
  .node-appointment-webform form .form-item input,
  .node-appointment-webform form .form-actions input {
    color: #888b8d;
    outline: 0;
    font-size: 15px;
    font-weight: normal;
    line-height: 37px;
    box-shadow: inset 0 0px 0px rgba(133, 133, 133, 0.1);
    background: transparent;
    border-bottom: 1px solid #858585;
    border: none;
    border-bottom: 1px solid #858585; }
  .node-appointment-webform form .form-item textarea,
  .node-appointment-webform form .form-actions textarea {
    color: #888b8d;
    outline: 0;
    font-weight: normal;
    font-size: 15px;
    line-height: 37px;
    background: transparent;
    border-bottom: 1px solid black;
    border: none;
    border-bottom: 1px solid #000000; }
  .node-appointment-webform label {
    color: #888b8d;
    font-size: 15px;
    font-weight: normal;
    line-height: 37px; }
  .node-appointment-webform .webform-datepicker {
    padding-left: 20px; }
    .node-appointment-webform .webform-datepicker .form-item {
      width: 25%;
      display: inline-block; }
    .node-appointment-webform .webform-datepicker .webform-calendar {
      vertical-align: middle;
      border-bottom: 0px; }
  .node-appointment-webform form .form-item select,
  .node-appointment-webform form .form-actions select {
    font-weight: normal;
    color: #888b8d;
    outline: 0;
    font-size: 18px;
    line-height: 22px;
    background-color: #fefefe;
    border: 0px solid #888b8d;
    border-bottom: 1px solid #888b8d;
    background: url(/sites/all/themes/custom/foundation/src/assets/img/br_down.png) no-repeat right transparent;
    -webkit-appearance: none;
    background-position-x: 100%; }

#data-sticky-container {
  z-index: 999999; }
