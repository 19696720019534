body {
  overflow-x: hidden;
}

.component9_full_width_image_carousel {
  //overflow-x:hidden;
  /*
    hack from 
     https://css-tricks.com/full-width-containers-limited-width-parents/ 
     NOTE: REQUIRES  : body {overflow-x: hidden;}
     */

      @include breakpoint(medium) {
        min-height: 600px;
      }
      @include breakpoint(small) {
        min-height: 50vh;
      }

  .full-width {
    width: 100vw; //$global-width;
    //@include horizontal-center;
    position: relative;
    overflow-x: hidden;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }
  .custom-highlighted {
    background-color:rgba(255,255,255,0.7);
    //background-color: white;
    
    position: absolute;
    height: 100%;
    @include breakpoint(medium) {
      //width: 100%;
      width: 50%;
    }
    @include breakpoint(small) {
      width: 100%;
    }
    //opacity: 0.60;
    bottom: 0px;

 }

 .custom-position {
  //background-color: white;

  position: absolute;
  height: 100%;
    width: 100%;
  //opacity: 0.60;
  bottom: 0px;
}
.customalign-tmp-left{
  height:100%;
}

.customalign-tmp-right{
  height:100%;
}

 .home-slide__content1{
  transform: translateY(-50%);
  color: white;
  z-index: 1;
 
  a{
    z-index: 2;
    color: white;
  }
     z-index: 3;
     top:50%;
     position :relative;
     color: white;
     display: inline-block;

     right: 64%;
     left: 0;
     width: 100%;
     text-align: left;
    
     @include breakpoint(medium) {
      padding-left: 10%;
    }
    @include breakpoint(small) {
      padding-left: 10%;
    }
 }

  .home-slider__slide {
    position: relative;
    width: 100%;
    /*       
       width: 100%;
        overflow: hidden;
       display: block;
       padding: 10px;
       @include horizontal-center;
       */
  }
  .columnText .first,
  .columnText .second {
    @include breakpoint(medium) {
      font-size: 28px;
      line-height: 42px;
    }
    @include breakpoint(small) {
      font-size: 1.2rem;
      line-height: 1rem;
    }
    color: #00acc1; //#0095e5;
    font-weight: bold;
  }
  .text-button {}
  .columnText .second span {
    display: block;
  }
  .element-row {
    // min-height:70vh;
  }
  .columnImage {
    @include breakpoint(medium) {
      min-height: 600px;
    }
    @include breakpoint(small) {
      min-height: 50vh;
    }
    /*
        background-size: cover;
        background-position: center center;
        */
  }
  .columnText {
    color: white;
    height: 100%;
    width: 100%;
    position: relative;
    @include breakpoint(medium) {
      //left: 10%;
    }
    @include breakpoint(small) {
      padding-top: 0px;
      padding-bottom: 20px;
      //left: 5%;
    }
  }
  .js-component9 {
    //background-color: #8ec7c7;
  }
  .js-component9 .slick-prev:before {
    content: url('/sites/all/themes/custom/foundation/src/assets/img/prev_dark.png') !important;

    @include breakpoint(medium) {
      margin-left: 55px !important;
    }
    @include breakpoint(small) {
      margin-left: 5px !important;
    }

  }
  .js-component9>[dir='rtl'] .slick-prev:before {
    //content: '→';
    content: url('/sites/all/themes/custom/foundation/src/assets/img/prev_dark.png') !important;
    @include breakpoint(medium) {
      margin-left: 55px !important;
    }
    @include breakpoint(small) {
      margin-left: 5px !important;
    }

  }
  .js-component9 .slick-next:before {
    //content: '→';
    content: url('/sites/all/themes/custom/foundation/src/assets/img/next_dark.png') !important;
    @include breakpoint(medium) {
      margin-left: -55px !important;
    }
    @include breakpoint(small) {
      margin-left: -5px !important;
    }
}
  .js-component9 [dir='rtl'] .slick-next:before {
    content: url('/sites/all/themes/custom/foundation/src/assets/img/next_dark.png') !important; // content: '←';
    @include breakpoint(medium) {
      margin-left: -55px !important;
    }
    @include breakpoint(small) {
      margin-left: -5px !important;
    }
  }
}
