.component3_images_with_text_on_top_grid {
    //background-color: red;
    //width: 95%;
    margin: 0 auto;
    margin-left: 0%;
    margin-right: 0%;
    @include flex-grid-row($size: 'expand', $gutters: 0px);


    .styled_paragraph{
      margin-top: 40px;
      margin-bottom: 30px;
      font-family: 'Open Sans', sans-serif;
      font-size: 2rem;
      text-align: center;
      color: #284349;
      padding-top:20px;
      padding-bottom:20px;
      }
    .comp-content {

        .views-field{
            height: 100%;
            width: 100%;
        }
 
            a:hover {
                .service-image {
                  -webkit-filter: brightness(70%);
                  filter: brightness(70%);
                }
              }
          
              a {
                font-family: "Open Sans", sans-serif;
                font-style: normal;
                font-weight: normal;
                color: white;
                display: block;
                text-align: center;
                height: 100%;
                width: 100%; 
                }


              .service-image {
                z-index: 2;
                -webkit-filter: brightness(70%);
                filter: brightness(70%);
                  height: 100%;
                  width: 100%;
          
                  //background-image:url(http://dhi.docker.localhost/sites/default/files/projects/m1.png);
                  background-repeat: no-repeat;
                  //background-size: contain;
                  background-clip: border-box;
                  background-size: 100% 100%;
                  background-size: cover;
                }
          
      
        .service-mainphoto{
            width: 100%;
            height: 100%;
            max-height: 300px;
            overflow: hidden;
          }

        .service-title {
            height: 100%;
            width: 100%;
            position: relative;
            vertical-align: middle;
            display: block;
            float: left;
            top: 40%!important;
            z-index: 4;
        }

        .service-mainphoto:hover{
          .service-title {
            transform: scale(1.1);
            transition: all .2s ease-in-out;
            opacity: 1;
            filter: alpha(opacity=1);
          }
        }


        .service-background {
            z-index: 3;
          }

        .service-image {
            z-index: 2;
            -webkit-filter: brightness(70%);
            filter: brightness(70%);
        }
    }



    .paragraphs-items {
      h1{
          font-weight: 700;
          font-size: 2rem;
          color: $white;
       }
      }
      


      
  .comp-content {
    display: grid;
    width: 100%;
    max-width: 1400px;
    margin: auto;
    justify-items: stretch;
    align-items: stretch;
    grid-gap: 10px 10px;

    @include breakpoint(medium up) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include breakpoint(small only) {
      grid-template-columns: repeat(1, 1fr);
    }

   
    @include breakpoint(medium up) {
      .featuredItemRow:nth-child(odd) {
        grid-auto-rows: auto;
        grid-column: 1;
      }
      .featuredItemRow:nth-child(even) {
        grid-auto-rows: auto;
        grid-column: 2;
      }
    }
 
    .featuredItemRow {
      //display: inline-block;
      //padding-bottom: 1rem;
      //padding-top: 1rem;

      @include breakpoint(medium up) {
        a {
          //font-size: 1.6rem;
          //font-weight: 700;
          display: grid;
          padding-left: 2px;
          padding-right: 2px;

          font-size: 21px;
        letter-spacing: 0px;
        line-height: 34px;
        color: rgb(255,255,255);
        font-family: "Open Sans";
        font-weight: bold;
        text-align: center;

        }
      }
      @include breakpoint(small only) {
        a {
          font-size: 14px;//1.4rem;
        }
      }
    }

  }


}