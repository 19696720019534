.button
{
    padding: 14px 30px 13px !important;
    color: white;
    background: $blue;
    display: inline-block;
    width: auto;
    min-width: 200px;
    border-color: white;
    font-size: 14px;
    letter-spacing: 0px;
    margin-bottom: 0;
    border-radius: 3px;
    font-weight: bold;

    &--secondary
    {
        background: white;
        color: $blue;
        border-color: $blue;

        &::hover
        {

        }
    }

}