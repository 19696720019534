.news-item-container {
    font-family: "Open Sans", sans-serif;



    .news-item-title{


    h1{
        font-style: normal;
        font-weight: 700;
        font-size: 23px;
        line-height: 1.3;
        font-family: "Open Sans", sans-serif;
        letter-spacing: 0px;
        text-align: inherit;
        color: #00acc1;//#0095e5;
    }

}

.news-item-date{
    padding-top:5px;
    color: #616262;
    font-size: 0.875rem;
    font-weight: 400;
    i {
        color: #00acc1;//#0095e5;
    }
}

.news-item-body{
    p{
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 1.2;
        font-family: "Open Sans", sans-serif;
        letter-spacing: 0px;
        text-align: left;
        color: #616262;
    }
}

}