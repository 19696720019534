

  .paragraphs-items {



 
    .styled_paragraph p{ 
        font-style: normal;
        //font-weight: 400;
        //font-size: 1.5rem;
        //line-height: 1.2;
        font-family: "Open Sans";
        //font-family: "Open Sans", sans-serif;
        letter-spacing: 0px;
        text-align: left;
        color: #616262;
    }
 
    h1{
        //font-weight: 700;
        //font-size: 2rem;
        font-size: 28px;
        color: #00acc1;//#0095e5;
     }
            /*
     h1:after
        {
            content:' ';
            display:block;
            content:' ';
            display:block;
            border:2px solid #00acc1;//#0095e5;
            border-radius:4px;
            width:100px;//10%;
            -webkit-border-radius:4px;
            -moz-border-radius:4px;
            box-shadow:inset 0 1px 1px rgba(0, 0, 0, .05);
            -webkit-box-shadow:inset 0 1px 1px rgba(0, 0, 0, .05);
            -moz-box-shadow:inset 0 1px 1px rgba(0, 0, 0, .05);
    }
        */

     h2{
        //font-weight: 700;
        font-size: 14px;//1.4rem;
        color: #00acc1;//#0095e5;
        
    }   
    
    h3{
        //font-weight: 700;
        font-size: 1.3rem;
        color: #00acc1;//#0095e5;
        
    }   
    h4{
        //font-weight: 700;
        font-size: 1.2rem;
        color: #00acc1;//#0095e5;
        
    }   
    h5{
        //font-weight: 700;
        font-size: 1rem;
        color: #00acc1;//s#0095e5;
        
    }   
    
    p{
        font-size: 15px;
        line-height: 1.5;
        font-style: normal;
        font-family: "Open Sans", sans-serif;
        letter-spacing: 0px;
        text-align: inherit;
        color: #616262;
    }
}