$menu-color-1 :#00acc1;//#0095e5 ;
$menu-color-2 :#00acc1;//#18a1eb ;
.menu-terms{
        @include breakpoint(small) {
        padding-bottom:20px;  
 }

    a{
        width:100%;
        background-color: $menu-color-1;
        color:white;
    }
    a.active-trail{
        background-color: white!important;
        color:$menu-color-1!important;
    }
    a.active{
        background-color: white!important;
        color:#767f89!important;
        width:100.1%;
    }

    .submenu{
        li{
            width:100%;    
        }
        a{
        width:100%;
        }
    }
    /*
    .drilldown a {
        background: $menu-color-1 none repeat scroll 0 0;
        padding: 0.7rem 1rem;
    }
      
    .drilldown .is-drilldown-submenu {
        background: $menu-color-1 none repeat scroll 0 0;
    }  
    */
}

/*
.menu-terms{
    background-color:$menu-color-1;
    padding-top:5px;
    padding-bottom:5px;
    margin-right:10px;
    .menu{
        width:100%;
    }
    
    ul.menu {
        list-style-type: none;
        margin: 0 0 0 0;
        padding: 0px 0px 0px 0px;
        > li{
            margin-right:10px;
            display: block;
            width:100%;
            margin: 0 0 0.25em 0.5em;
            //padding: 0px 0px 0px 0px;
            background-color: transparent;// $menu-color-1;
            border: 1px solid $menu-color-2;
            > a{
                color:$white!important;
            }
            > a.active-trail{
                background-color: white!important;
                color:$menu-color-1!important;
            }
            > a.active{
                background-color: white!important;
                color:#767f89!important;
                width:100.1%;
            }
        }
        li{
            ul.menu{
                border:0px solid transparent!important;
            }
        }
        li.active-trail.expanded{
            ul.menu{
                border:1px solid white!important;
            }
        }
        li:hover{
            background-color: $menu-color-2;
            border: 1px solid $white;
        }
        > li.active-trail{
            //background-color: $white;
            background-color: transparent!important;
            border: 1px solid $menu-color-2;
        }

    }
}
*/


.small-path{
        font-style: normal;
        line-height: 1.3;
        font-family: "Open Sans", sans-serif;
        letter-spacing: 0px;
        text-align: inherit;
        color: #616262;
        font-size:0.8rem;
    a{
        font-style: normal;
        line-height: 1.3;
        font-family: "Open Sans", sans-serif;
        letter-spacing: 0px;
        text-align: inherit;
        color: $menu-color-1;
        font-size:0.8rem;
    }
}