.component5_simpleParagraphs{

/*
    .simple-paragraph{    
        font-style: normal;
        line-height: 1.3;
        font-family: "Open Sans", sans-serif;
        letter-spacing: 0px;
        text-align: inherit;
        color: #616262;
        font-weight: 400;
        font-size:20px;
        p{
            line-height: 1.3;
            font-family: "Open Sans", sans-serif;
            letter-spacing: 0px;
            font-weight: 400;
            text-align: inherit;
            color: #616262;
            font-size:20px;
        }

    }
*/

}