@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
// @include foundation-grid;
@include foundation-flex-grid;
@include foundation-flex-classes;

@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
// @include foundation-multilevel-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
// @include foundation-progress-element;
// @include foundation-meter-element;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
// @include foundation-flex-classes;
@include motion-ui-transitions;
@include motion-ui-animations;

@import "zurb_foundation";
// Components
@import "components/blocks/header/top-panel";
@import "components/blocks/header/Menu";
@import "components/blocks/header/off-canvas-full-screen";
@import "components/blocks/header/main_menu";
@import "components/blocks/header/top-bar";
@import "components/blocks/footer/footer";
@import "components/blocks/forms/block_webform_contact";
@import "components/blocks/block_OfficePhotos";
@import "components/blocks/terms";

@import "components/paragraphs";
@import "components/paragraphs/generic_for_paragraphs";
@import "components/paragraphs/blue-background-cta";
@import "components/paragraphs/paragraphs-item-info-container";
@import "components/paragraphs/component1_two_columns_leftimagetitlesubtitlepa";
@import "components/paragraphs/component2_images-and-text-in-2-columns";
@import "components/paragraphs/component3_images-with-text-on-top-grid";
@import "components/paragraphs/component4_full_width_image_carousel";
@import "components/paragraphs/component5_simple_paragraph";
@import "components/paragraphs/component8_youtube-video";
@import "components/paragraphs/component9_full_width_image_carousel_left_right";
@import "components/paragraphs/carousel";
@import "components/paragraphs/component10_images_and_text_sidebyside";

@import "components/views/view-blog-posts";
@import "components/views/contact";


@import "components/nodes/news-item";



@import "components/views/taxonomy_term_page.scss";

@import "components/buttons";

@import "animations";
// import the font here!!!

@import "panos.scss";

//colors

.return-link{
  text-transform: uppercase;
  font-family: 'Open Sans', sans-serif;
  line-height: normal;
  font-size: 14px;//1.4rem;
  text-align:left;
  // padding-left: 30px;
  color: #BDBDBD;
}

.paragraphs-items{
  @include breakpoint(medium up) {
    width:100%;
  }
  /*
  @include breakpoint(small) {
    width:90%;
    padding-left:10%;
  }
  */
}

a{
  padding: 0rem 0rem;
}
.button-group{
  list-style-type:none;
}
h1{
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  //font-size: 2rem;
  font-size: 28px;
  //text-transform: uppercase;
  color: #57A099;
  padding-top:1rem;
  padding-bottom:1rem;
}
h2 {
  font-family: 'Open Sans', sans-serif;

  font-style: normal;
  font-weight: normal;
  font-size: 1.8rem;
  //letter-spacing: 3.4px;
  color: #57A099;
  // text-transform: uppercase;
  padding-bottom:1rem;
}

h3 {
  font-family: 'Open Sans', sans-serif;

  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  //letter-spacing: 3.4px;
  color: #57A099;
  //text-transform: uppercase;
  padding-top:0.5rem;
  padding-bottom:0.7rem;
}

p{
  font-family: 'Open Sans', sans-serif;

  font-style: normal;
  font-weight: normal;
  //font-size: 1.3rem;
  font-size: 14px;
  line-height: 24px;
  color: #828282;
}

.all-cases-link{
  font-family: 'Open Sans', sans-serif;

  line-height: normal;
  font-size: 14px;//1.4rem;
  text-transform: uppercase;
  color: #BDBDBD;
}

.user-options-menu{
ul{
  margin-eight: 1.25rem;
  list-style-type: none;
}
  li{
    display: inline-block;
    padding-right: 10px;
a{
  // button hollow custom-button-cta
  padding: 0rem 0rem;
  @include button(true,  $black, $black, $white,'solid');
}
}
}

#btnHideMenu{
padding: 0rem 0em;
  background-color:transparent!important;

}
.main-column-primary {
  position: relative;
  
  .block{
    @media screen and (min-width: 0px) and (max-width: 1230px) {
      margin-left: 0rem;
    }
    @media screen and (min-width: 1230px)  { // and (max-width: 1024px)
      margin-left:5rem;
    }
  }
}
 

.moto{
  font-family: 'Open Sans', sans-serif;

  font-style: italic;
  font-weight: bold;
  font-size: 2rem;
  color: #57A099;
  padding-left:10%;
  padding-top:2rem;
  padding-bottom:2rem;
}

.moto-1{
  width: 50%;
  font-family: 'Open Sans', sans-serif;

  font-style: italic;
  font-weight: bold;
  font-size: 2rem;
  color: #57A099;
  padding-left:10%;
  padding-top:2rem;
  padding-bottom:2rem;
}

.moto-2{
  font-family: 'Open Sans', sans-serif;

  font-style: italic;
  font-weight: bold;
  font-size: 2rem;
  color: #57A099;
  width:80%;
  padding-left:10%;
  padding-top:2rem;
  padding-bottom:2rem;
}
.moto-3{
  font-family: 'Open Sans', sans-serif;

  font-style: italic;
  font-weight: bold;
  font-size: 2rem;
  color: #57A099;
  width:70%;
  padding-top:2rem;
  padding-bottom:2rem;
}

.company-page{
  @include flex-grid-row($size: $grid-row-width);
  @include flex-grid-column($columns:10);
}


h1.hrLine{
  padding-top:3rem;
  position: relative;
}
h1.hrLine:before{
  content: "";
  position: absolute;
  margin-top: -2rem;
  height: 4px;
  background-color: #CBF1FD;
  width: 70%;
  left: 40%;
  overflow: hidden;
}



.block-block {
  // width:100%;
  //margin-top:1rem;
}

article{
  // @include flex-grid-row($size: $grid-row-width);
  // @include flex-grid-column($columns:12);

  .customUL{
    font-style: normal;
    font-weight: bold;
    line-height: 133%;
    list-style-type: none;
    font-size: 1.3rem;
    text-indent: 0rem;
    padding-top:0.5rem;
    padding-bottom:0.7rem;
    li{
    }
    li:before {
      content: "- ";
      text-indent: -1.8rem;
    }

  }


}

img {
  display: block;
  margin:0 auto;
}
.block-title{
 
  margin-top:40px;
  margin-bottom:30px;
  font-family: 'Open Sans', sans-serif;

  font-size: 2rem;
  text-align: center;
  color:#00acc1;
  //color: #284349;
  // color:#0095e5;
}
h1.block-title{
  color:#00acc1;
  font-size: 28px;
  letter-spacing: 0px;
  line-height: 34px;
  font-family: "Open Sans";
}

.view-header p {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  text-align: center;
  color: #284349;
  margin-top:10px;
  margin-bottom:30px;
}

.l-main-front{
  background-color: $white;
}

body{
  max-width: 2000px;
  margin:0 auto;
  font-style: normal;
  //font-weight: 400;
  font-size: 14px;
  line-height: 24px;
//font-size: 20px;
  //line-height: 1.2;
  font-family: $body-font-family;
  letter-spacing: 0px;
  text-align: left;
  color: $body-font-color;
}
 

.color-lightgreenblue{
  color:#418794;
}
.text-big{
  font-size:2rem;
}
.text-normal{
  font-size: 1.3rem;
}
 

@media screen and (min-width: 0px) and (max-width: 1230px) {
  .show-for-small-only-custom { display: block!important; }  /* show it on small screens */
  .hide-for-small-only-custom { display: none!important; }  /* hide it  */
}
@media screen and (min-width: 1230px)  { // and (max-width: 1024px)
  .show-for-small-only-custom { display: none!important; }  /* hide it elsewhere */
  .hide-for-small-only-custom { display: block!important; }  /* show it elsewhere */
}

.gutter-top
{
  margin-top: 20px;
}

.view-list-of-treatment-articles{
  .view-content {
    width:95%;
}
}



.contact_details{

  @include breakpoint(medium up) {
    width: 100%;
  }
  @include breakpoint(small) {
    width: 90%;
    margin: 0 auto;
  }

h1{
  color:#20afb3;
  font-size:26px!important;
  line-height: 34px;
}
  a{
    color:#858585!important;
  }
}


.block-block-2{
  @include breakpoint(medium up) {
    padding-top:42px;
  }
}
.block-block-13{
  background-color:black;
  padding-top:80px;
  padding-bottom:70px;

  p{
  text-align: center;
  font-size: 29px;
line-height: 37px;
color: rgb(255,255,255);
//font-family: "Roboto";
font-weight: bold;
text-align: center;
}
}
.node-appointment-webform {
    box-shadow: 0px 0px 0px transparent;
    @include breakpoint(medium up) {
      width : 80%;
    }
    @include breakpoint(small) {
      width : 100%;
    }

    background-color:#fafafa;

    .webform-component--intro2{
      font-size: 14px;
      line-height: 37px;
      color: rgb(136,139,141);
      font-family: "Roboto"; 
    }
  .fieldset-description{
    color:#00acc1;
    letter-spacing: 0px;
    line-height: 37px;
    font-family: "Roboto";
    @include breakpoint(medium up) {
//font-size: 2rem;
    font-size: 26px;
    }
    @include breakpoint(small) {
      font-size: 14px;//1.4rem;
    }

  h2{
   color:#20afb3;
  } 
  .cal_link{
    a{
      color:#858585!important;
    }
  }
  }
  ::-moz-placeholder{
    opacity: 1;
    color:#858585;
    font-size: 19px;
    font-weight: normal;
  }
  ::-webkit-input-placeholder{
    opacity: 1;
    color:#858585;
    font-size: 19px;
    font-weight: normal;
  }
  .webform-submit{
    
    @include breakpoint(medium up) {
      width: 50%;  
    }
    @include breakpoint(small) {
      width: 100%;  
    }
  }


  @include breakpoint(medium up) {

  .webform-component--e-mail{
    display: inline-block;
    width: 49%;
  }

  .webform-component--telephone{
    display: inline-block;
    width: 49%;
  }

}

  
  form .form-item input,
  form .form-actions input{
    color: #888b8d;
    outline: 0;
    //font-size: 1.2rem;
    font-size: 15px;
    font-weight: normal;
    line-height: 37px;
    //color: rgb(136,139,141);
    //font-family: "Roboto";

    box-shadow: inset 0 0px 0px rgba(133, 133, 133, 0.1);
    background: transparent;
    border-bottom: 1px solid #858585;
    border: none;
    border-bottom: 1px solid #858585;
  }


  form .form-item textarea, 
  form .form-actions textarea {
    color: #888b8d;
    outline: 0;
    font-weight: normal;
    font-size: 15px;
    line-height: 37px;
    //font-family: "Roboto";
    background: transparent;
    border-bottom: 1px solid black;
    border: none;
    border-bottom: 1px solid #000000;
  }

  label{
    color: #888b8d;
    font-size: 15px;
    font-weight: normal;
    line-height: 37px;
  }

  .webform-datepicker{
    padding-left:20px;
    .form-item{
      width: 25%;
      display: inline-block;
    }

    .webform-calendar{
      vertical-align: middle;
      border-bottom:0px;
    }
  }
  
  form .form-item select, 
  form .form-actions select{
    font-weight: normal;
    color: #888b8d;
    outline: 0;
    font-size:18px;// 15px;
    line-height:22px;
    //line-height: 37px;
    //font-family: "Roboto";

    background-color: #fefefe;
    border: 0px solid #888b8d;
    border-bottom: 1px solid #888b8d;
    background: url(/sites/all/themes/custom/foundation/src/assets/img/br_down.png) no-repeat right transparent;
    -webkit-appearance: none;
    background-position-x: 100%;
  }


}

 
#data-sticky-container{
  z-index:999999;
  }
