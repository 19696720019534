//.page-taxonomy .section-categories-treatments{
    .treatment-page-styling{ 
   // background-color: green;
  
  
   //@include breakpoint(small) {
   // .paragraphs-items:first-child{ 
    //      padding-top:20px;  
   // }
  //}


        .elementor-divider-separator {
            border-top-style: solid;
            border-top-width: 2px;
            width: 65px;
        }
        .elementor {
            hyphens: manual;
        }
        .elementor-widget-divider .elementor-divider-separator {
            display: inline-block;
        }
        .elementor-divider-separator {
            color: #00acc1;//#0095e5;
        }
        .elementor-widget-divider .elementor-divider {
            line-height: 0;
            font-size: 0;
        }
    
        font-style: normal;
        line-height: 1.3;
        font-family: "Open Sans", sans-serif;
        letter-spacing: 0px;
        text-align: inherit;
        color: #616262;
        
     h1{
        //font-weight: 700;
        font-size: 2rem;
        color: #00acc1;//#0095e5;
        
     }
     h2{
        //font-weight: 700;
        font-size: 1.5rem;
        color: #00acc1;//#0095e5;
        
    }   
    
    h3{
        //font-weight: 700;
        font-size: 14px;//1.4rem;
        color: #00acc1;//#0095e5;
        
    }   
    h4{
       // font-weight: 700;
        font-size: 1.3rem;
        color: #00acc1;//#0095e5;
        
    }   
    h5{
      //  font-weight: 700;
        font-size: 1.2px;
                color: #00acc1;//#0095e5;
        
    }   
    .paragraphs-items p,p{
        font-size: 14px;//1.4rem;
        font-style: normal;
        line-height: 1.3;
        font-family: "Open Sans", sans-serif;
        letter-spacing: 0px;
        text-align: inherit;
        color: #616262;
    }
    

    .vocabulary-treatment{
        //   background-color: yellow;
   
       }
     
.treatments_main_view_content{
    ol,ul{
        margin: 0;
        padding: 0;
        list-style-type: none;
        padding-bottom:1rem;
    }
    ol > li,
    ul > li {
    font-weight: normal;
}
    ol > li:before,
    ul > li:before {
        color: #00acc1;//#0095e5;
        content: '\f00c';
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 2px;
        margin-right: 10px;
    }

}

    .field-name-name-field{
        font-weight: 700;
        font-size: 2rem;
        color: #00acc1;//#0095e5;
    }

    .field-name-field-info-title-container{
        margin-bottom:40px;
        margin-top:40px;
    }

    .field-name-name-field:after {
/*
        content: ' ';
        display: block;
        border:1px solid #0095e5;
        //border-radius: 4px;
        width: 100px;
*/
margin-bottom: 20px;
margin-top: 20px;
bottom: 4px;
display: block;
height: 4px;
content: "";
position: relative;
/*
    background: none repeat scroll 0 0 #00acc1;//#0095e5;
    content: "";
    width: 100px;
    */
    }
    
        .paragraphs-item-info-container::after {
            position: absolute;
            display: block;
            top: 0;
            bottom: 0;
            left: -100%;
            right: -100%;
            content: "";
            background-color: transparent;
            background: none;
            z-index: -1;
        }

} 