$offcanvas-full-screen-bg: #284349;
$offcanvas-full-screen-side: left;
$offcanvas-full-screen-closebutton-size: 2rem;

.main-menu-small-1 .offcanvas-full-screen-menu{
  padding-top:-1px;
}

.offcanvas-full-screen-menu{
  padding-top:-1px;
  display:inline-block!important;
  ul:first-child{
    display: inline-block!important;
  }
}

.main-menu-small-1 .offcanvas-full-screen-menu:first-child{
  padding-top:10px;
}


.offcanvas-full-screen {
  @include off-canvas-base($background: $offcanvas-full-screen-bg, $transition: 0.3s ease-in-out);
  @include off-canvas-position($position: $offcanvas-full-screen-side, $sizes: 80vw);

}
.offcanvas-full-screen.is-transition-overlap {
  z-index: 100;
}
.offcanvas-full-screen-inner {
  text-align: left;
  @include breakpoint(small only){
    //padding-top: 5rem;
    padding-top: 0rem;
    padding-bottom: 5rem;
    padding-left: 0rem;
    padding-right: 0rem;
  }
}
.menu.vertical > li > a{
  padding-top: 13px !important;
  padding-bottom: 13px !important;
  display: inline-block;
  position: relative;
}
.menu > li > a{
  padding-top: 13px !important;
  padding-bottom: 13px !important;
}

.offcanvas-full-screen-menu {
  @include menu-base;
  @include menu-direction(vertical);

  .logo {
    fill: #fff;
  }
  .menu.nested {
    margin-left: 0rem;
  }

  .menu > li > a{
    padding-top:20px!important;
    padding-bottom:20px!important;
  }
  a:first-child {
    //border-top: 1px solid #8ec7c7;
  }


  a {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    //font-size: 17px;
    font-size: 1.7rem;
    color: $white;


    //text-transform: uppercase;
    display: inline-block!important;
    width: 100%;

    &:hover {
      background-color: darken($white, 30%);
    }
  }
  a.active{
    background-color: #418794;
   font-size:1.9rem;
  }
  a.active-trail{
    background-color: #418794;
    font-size:1.9rem;
  }

  // class="innerNav menu vertical nested submenu is-accordion-submenu is-active"

  ul {
    list-style-type: none;
    margin: 0;
    background-color:#8BC2C3;

    padding-bottom: 0px;
    margin-bottom: 0px;
    li {
      width:100%;
      //padding-bottom: 1rem;
      border-bottom: 1px solid #3F8591;
      padding-bottom: 0px;
      margin-bottom: 0px;
      display: inline-table;

    }
  }
 /*
  li.is-accordion-submenu-parent:after{
    position:relative;
    color: white;
    content: '▾';
  }
  */
  li{
    border-bottom: 1px solid #418794;
    display: inline-block;
    position: relative;
  }
  //
  li.is-accordion-submenu-parent[aria-expanded="true"]{
    background-color:#3F8591;
  }
  /*
  li.is-accordion-submenu-parent[aria-expanded="false"]:first-child:after {
    content: "▾";
    right: 6%;
    font-size: 2rem;
    color: white;
    position: relative;
    float: right;
    margin-top: -60px;
  }
  li.is-accordion-submenu-parent[aria-expanded="true"]:first-child:after {
    content: " ";
    font-size:2rem;
    position: relative;
    padding-left: 98%;
    float:left;
    color: white;
    left: 0;
    top: 0;
  }
*/


}
.offcanvas-full-screen-menu > li > a {
  display: inline-block!important;
}

.home-button-wrapper{
  padding-left:15px;
  padding-top:20px;


  .close-button{
    //float:right;
  }
  //home-link
  a{
    padding-left: 0rem;
    float:left;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: bold;
    font-size: 1.7rem;
    border-color: transparent;
    color: #8EC7C7;
    //border-bottom:1px solid #8EC7C7;
    height:100%;
    width:100%;
    outline: none;
    padding-bottom: 20px!important;
  }
}

